.Feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  margin-bottom: 21px;

  .rating,
  .recommend {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  h6 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: black;
    opacity: 0.6;
  }
}
