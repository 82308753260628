.Pdf {
  position: relative;
  padding-top: 18px;
  display: flex;

  &.addCandidateModal {
    .resumes {
      .view {
        //   position: absolute;
        //   top: 0;
        display: none;
      }
    }
  }

  .resumes {
    position: relative;
    padding-top: 23px;
    height: 536px;
    width: 250px;
    overflow-y: scroll;
    margin: 0 20px 28px 0;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    user-select: none;

    .view {
      position: fixed;
      z-index: 1;
      top: 350px;
      width: 222px;
      height: 275px;
      background: url('./../../../../../assets/images/dashboard/candidate/wordResume/miniScroll.svg');
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      .magnifier {
        position: absolute;
        top: 85px;
        left: 21px;
        width: 183px;
        height: 121px;
        border-radius: 11px;
        background-color: transparent;
        cursor: zoom-in;
      }
    }

    .resume {
      margin-top: 62px;
      max-width: 222px;
      display: flex;
      flex-direction: column;

      .page {
        min-width: 0 !important;
        min-height: 0 !important;
        height: 233px;
        margin-bottom: 28px;
        overflow: hidden;
        border: 2px solid #f2f4f8;
        border-radius: 8px;
      }

      canvas {
        width: 205px !important;
        height: 233px !important;
      }
    }
  }

  .mainResumeContainer {
    width: 720px;
    height: 545px;
    overflow: scroll;
    overflow-x: scroll;

    .mainResume {
      scrollbar-width: thin;

      .page {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Pdf {
    padding-top: 40px;

    &.addCandidateModal {
      &.hasResume {
        padding-top: 89px;
      }
    }

    .resumes {
      display: none;
    }

    .mainResumeContainer {
      width: 100%;
      height: 476px;
      overflow: auto;
      overflow-x: auto;
    }

    /*   .icons {
      right: 0;
      flex-direction: row;

      .icon {
        width: 30px;
        height: 30px;
        background-size: contain;
      }
    } */
  }
}
