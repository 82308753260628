.Note {
  position: absolute;
  top: 52px;
  right: 14px;
  width: 24px;
  height: 24px;
  background-image: url(../../../../../../../assets/images/dashboard/candidates/note.svg);
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    .noteContent {
      display: flex;
    }
  }

  &.onTop {
    .noteContent {
      top: -16px;
      transform: translateY(-100%);
      border-radius: 16px 16px 0 16px;

      &::after {
        top: unset;
        bottom: -16px;
        transform: scaleX(1) scaleY(-1) rotate(45deg);
      }
    }
  }

  .noteContent {
    display: none;
    position: absolute;
    z-index: 1000;
    top: calc(100% + 20px);
    right: 50%;
    flex-direction: column;
    width: 234px;
    min-height: 104px;
    padding-top: 7px;
    padding-left: 14px;
    padding-right: 9px;
    padding-bottom: 13px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
    border-radius: 16px 0 16px 16px;

    &::after {
      content: '';
      position: absolute;
      right: -6px;
      top: -16px;
      z-index: -1;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid white;
      transform: rotate(45deg);
      border-radius: 42% 0 0 0;
    }
  }
}
