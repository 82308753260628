.ResumeInput {
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    margin-bottom: 5px;
    font-family: Gilroy-SemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #242833;
  }

  .input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 80px;
    background-color: #f2f4f8;
    border: 1px dashed #c1c5d6;
    border-radius: 16px;
    cursor: pointer;

    span {
      font-family: Gilroy-SemiBold;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      text-align: center;
      color: #c1c5d6;
    }

    .addButton {
      position: absolute;
      top: -14px;
      right: -12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-image: linear-gradient(
        228deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 50%;
      border: none;
    }
  }
}

@media (max-width: 1024px) {
  .ResumeInput {
    .input {
      width: 100%;
    }
  }
}
