.Video {
  position: relative;

  video {
    width: 100%;
    min-width: 548px;
    max-height: 649px;
    object-fit: cover;
    border-radius: 16px;
  }

  .controls {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 511px;
    height: 54px;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;

    .progressContainer {
      height: 12px;
      margin-bottom: 4px;
      padding-top: 4px;

      .progress {
        position: relative;
        width: 100%;
        height: 4px;
        background-color: #65758d;
        border-radius: 5px;

        .fill {
          position: absolute;
          left: 0;
          height: 4px;
          background-color: #00e0a2;
          border-radius: 5px;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .playPauseButton {
        position: absolute;
        left: calc(50% - 8.5px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 18px;
        padding: 0;
        background-color: transparent;
        border: none;

        &.pause {
          img {
            display: none;
          }
          &::after {
            content: '';
            display: block;
            width: 3px;
            height: 14px;
            background-color: white;
            margin-left: 2px;
          }

          &::before {
            content: '';
            display: block;
            width: 3px;
            height: 14px;
            background-color: white;
          }
        }
      }

      .timeLeft {
        font-family: 'Gilroy-SemiBold';
        font-size: 15px;
        line-height: 18px;
        color: white;
      }

      .fullScreenButton {
        display: flex;
        width: 16px;
        height: 14px;
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }
  }
}
