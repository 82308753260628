.LatestNotifications {
  padding-bottom: 34px;
  .header {
    // position: sticky;
    // top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;

    h3 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 22px;
      color: #242731;
    }

    button {
      border: none;
      padding: 0;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 17px;
      color: #c1c5d6;
      background-color: transparent;
    }
  }

  .noData {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 98px;

    p {
      width: 226px;
      font-family: 'Gilroy-SemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }
  }

  .notifications {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 1024px) {
  .LatestNotifications {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 21px;
    .header {
      margin-bottom: 15px;
      h3 {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
