.DayView {
  width: 100%;
  display: flex;

  .hours {
    flex: 1;
    position: relative;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 14px - 65px - 100px - 80px);
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    .line {
      margin-top: 12px;
      position: absolute;
      z-index: 3;
      margin-left: 45px;
      width: calc(100% - 48px - 65px - 7px);
      min-height: 2px;
      background-color: #6722d2;

      &::after,
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -7px;
        width: 16px;
        height: 16px;
        background: #6722d2;
        border-radius: 50%;
      }

      &::after {
        left: auto;
        right: 0;
      }
    }

    .hour {
      display: flex;
      width: calc(100% - 55px);

      .title {
        width: 50px;
        margin-top: -13px;
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
        line-height: 150%;
        color: #c1c5d6;
      }

      .columns {
        display: flex;
        width: calc(100% - 69px);

        .column {
          position: relative;
          padding-right: 2px;
          flex: 1;
          height: 95px;
          border: 1px solid rgba(36, 40, 51, 0.15);

          &:first-child {
            padding-left: 29px;
            width: calc(100% + 39px);
            border-left: none;
          }

          &:nth-child(5) {
            &::after {
              content: '';
              position: absolute;
              width: 65px;
              height: 95px;
              border: 1px solid #f2f4f8;
              border-right: none;
              left: 100%;
              top: -1px;
            }
          }
        }
      }
    }
  }
}

.week {
  .hours {
    height: calc(100vh - 160px - 105px - 104px);

    .hour {
      width: calc(100% - 55px);

      .columns {
        .column {
          z-index: auto;
          &:first-child {
            & > div {
              max-width: calc(100% - 27px);
            }
          }
        }
      }
    }
  }
}

.hours {
  .hour:nth-child(-n + 2) {
    .columns {
      .column {
        border-top: none;

        &:nth-child(5) {
          &::after {
            border-top: none;
          }
        }
      }
    }
  }

  .hour:nth-child(n + 25) {
    .columns {
      .column {
        border-bottom: none;

        &:nth-child(5) {
          &::after {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .DayView {
    width: 1040px;
    overflow: auto;

    .hours {
      height: unset;
      overflow: hidden;
    }
  }
}
