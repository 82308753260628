.AuthLayout {
  max-width: 100%;
  min-height: 100vh;
  padding-right: 80px;
  padding-left: 80px;
  background-image: url(../../assets/images/carousel/top-left-shape.svg),
    url(../../assets/images/carousel/top-right-shape.svg),
    url(../../assets/images/carousel/bottom-left-shape.svg),
    url(../../assets/images/carousel/bottom-right-shape.svg);
  background-position: top left, top right -20px, left bottom -50px,
    bottom right;
  background-repeat: no-repeat;
  background-color: rgb(242 244 248);

  .mainContainer {
    display: flex;
    justify-content: center;
    gap: 114px;
    .leftColumn {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 554px;
      // padding-right: 114px;
      padding-bottom: 40px;
      padding-top: 40px;

      .container {
        width: 100%;
        max-width: 554px;
        height: 100%;
        padding-top: 43px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 38px;
        background-color: rgba(255, 255, 255, 0.7);
        border: 2px solid;
        border-image-source: linear-gradient(
          36.97deg,
          #ffffff 1.9%,
          rgba(254, 254, 254, 0) 102%
        );
        border-radius: 20px;
      }
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100vh;
      flex: 1;
      // margin-right: auto;
      max-width: 668px;
      padding-bottom: 40px;
      padding-top: 40px;
    }
  }
}

@media (max-width: 1024px) {
  .AuthLayout {
    padding-right: 15px;
    padding-left: 15px;
    background-position: top left, top right -20px, left -70px bottom -50px,
      bottom right -120px;
    .mainContainer {
      .leftColumn {
        padding-top: 66px;
        .container {
          padding-top: 23px;
          padding-left: 24px;
          padding-right: 24px;
          padding-bottom: 31px;
        }
      }

      .rightColumn {
        display: none;
      }
    }
  }
}
