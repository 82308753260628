.JobCard {
  position: relative;
  display: block;
  // width: calc(25% - 11px);
  height: 203px;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 17px;
  text-decoration: none;
  border-radius: 16px;
  box-shadow: 4px 4px 50px 0px rgba(78, 79, 114, 0.1);

  &.noShadow {
    box-shadow: none;
  }

  &.green {
    &::after {
      background-color: #00e4ac;
    }
  }

  &.orange {
    &::after {
      background-color: #ffbf00;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 10px;
    background-color: #c1c5d6;
    box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0px 0px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    margin-bottom: 15px;

    .leftBlock {
      display: flex;
      gap: 10px;
      align-items: center;

      .code {
        font-family: 'Gilroy-SemiBold';
        font-size: 14px;
        line-height: 24px;
        color: #242833;
      }
    }

    .rightBlock {
      display: flex;
      align-items: center;
      gap: 10px;

      .status {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 24px;
        color: #242833;
      }

      .favoritesButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: transparent;
        border: none;
      }

      .userImage {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        color: white;
        object-fit: cover;
        box-shadow: 2px 5px 30px 0px rgba(1, 173, 131, 0.15);
        border-radius: 8px;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }
    }
  }

  h2 {
    margin: 0;
    margin-bottom: 15px;
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 23px;
    color: #242833;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .details {
    display: flex;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      gap: 6px;
      font-family: 'Gilroy-Regular';
      font-size: 10px;
      line-height: 150%;
      color: #c1c5d6;

      .value {
        font-family: 'Gilroy-SemiBold';
        font-size: 12px;
        line-height: 150%;
        color: #c1c5d6;
      }
    }
  }

  .hiringPipeline {
    position: absolute;
    bottom: 10px;
    right: 16px;
    left: 16px;
    padding-top: 8px;
    padding-left: 15px;
    padding-bottom: 15px;
    background-color: #f2f4f8;
    border-radius: 16px;

    table {
      width: 100%;

      thead {
        tr {
          th {
            font-family: 'Gilroy-SemiBold';
            font-size: 10px;
            line-height: 160%;
            text-align: center;
            color: #242833;

            &.new {
              color: #6e60e7;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Gilroy-SemiBold';
            font-size: 14px;
            line-height: 160%;
            text-align: center;
            color: #242833;
            &.new {
              color: #4c74e2;
            }
          }
        }
      }
    }
  }
}

.long {
  z-index: 0;

  &:hover {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );

    &::before {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: -1;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: white;
      border-radius: 16px;
    }
  }

  &::after {
    content: none;
  }
}

.JobCard {
  &.favorites {
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    min-height: 163px;
    height: 163px;
    padding: 15px;
    background-color: white;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );

      &::before {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        z-index: -1;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: white;
        border-radius: 16px;
      }
    }

    &::after {
      content: none;
    }

    .rightBlock {
      // .favoritesButton {
      //   background-image: url(../../../../../../assets//images/dashboard/favorites/fav.svg);
      // }

      .userImage {
        box-shadow: 2px 5px 10px 0px rgba(1, 173, 131, 0.3);
      }
    }

    h2 {
      margin-top: auto;
      margin-bottom: 10px;
    }

    .details {
      justify-content: unset;
      gap: 26px;
    }
  }
}

@media (max-width: 1024px) {
  .JobCard {
    width: 100%;
  }
}
