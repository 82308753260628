@use '../../variables' as var;

.FormButton {
  width: 206px;
  height: 56px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  border-radius: 16px;
  position: relative;
  z-index: 2;
  .animatedBackground {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(242, 244, 248, 1);
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    border-radius: 16px;
  }
  .chilredWrapper {
    position: relative;
    z-index: 2;
  }
  &:hover {
    .animatedBackground {
      transform: scale(1);
    }
  }

  &:disabled {
    color: rgba(193, 197, 214, 1) !important;
    background-color: rgba(242, 244, 248, 1) !important;
    border: none !important;

    &:hover {
      color: rgba(36, 40, 51, 1) !important;
      background-color: rgba(242, 244, 248, 1) !important;
      border: 1px solid rgba(193, 197, 214, 1) !important;
    }
  }

  &:active,
  &:focus {
    color: white !important;
    background-color: var.$light-green;
    outline: none;
    border: none !important;
  }

  &.deactivate {
    color: white;
    background-color: rgba(255, 45, 85, 1);
  }

  &.submit {
    color: rgba(36, 40, 51, 1);
    background-color: rgba(0, 228, 172, 1);

    &:hover {
      color: rgba(0, 228, 172, 1);
      background-color: transparent;
      border: 1px solid rgba(0, 228, 172, 1);
    }
  }

  &.reset {
    border: 1px solid rgba(0, 0, 0, 1);
  }

  &.delete {
    background-color: rgba(193, 197, 214, 1);

    // &:hover {
    //   color: white;
    // }
  }
}
