@keyframes rotate90deg {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(95deg);
  }
  50% {
    transform: rotate(85deg);
  }
  75% {
    transform: rotate(95deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes zoomScale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.SideMenu {
  max-height: 100vh;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 80px;
    width: 80px;
    height: 100%;
    max-height: 100vh;
    padding-top: 15px;
    padding-bottom: 29px;
    background-color: white;
    border-right: 1px solid #f2f4f8;
    overflow-y: auto;
    overflow-x: hidden;

    .topMenuContainer {
      display: none;
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 54px;
      img {
        width: 60px;
        height: 19px;
        margin-bottom: 5px;
      }

      .powered {
        font-family: 'Gilroy-Regular';
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #c1c5d6;
      }

      .zaphire {
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 15px;
        font-weight: bold;
        color: #242833;
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      flex: 1;
      // margin-bottom: 90px;
      .buttonsList {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        //  gap: 20px;
        max-height: 603px;
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }

    .bottomBlock {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: auto;
      padding-top: 20px;

      i {
        display: block;
        width: 24px;
        height: 24px;
        font-size: 0;
        background-size: contain;
        cursor: pointer;

        &.options {
          background-image: url(../../../assets/images/dashboard/nav/options.svg);
          &:hover {
            animation: rotate90deg 0.3s ease-in-out;
          }
        }

        &.activeOptions {
          background-image: url(../../../assets/images/dashboard/nav/options-active.svg);
          &:hover {
            animation: rotate90deg 0.3s ease-in-out;
          }
        }

        &.logout {
          background-image: url(../../../assets/images/dashboard/nav/logout.svg);
          &:hover {
            animation: rotate90deg 0.3s ease-in-out;
          }
        }
      }
    }
  }
}

.backdrop.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #242833;
  opacity: 0.2;
}

.SideMenu.mobile {
  position: fixed;
  right: -80px;
  top: 0;
  bottom: 0;
  z-index: 10001;
  width: 160px;
  max-height: 100dvh;
  padding-left: 80px;
  transition: right 0.3s;
  overflow: hidden;
  overflow-y: auto;

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 80px;
    right: 0;
    bottom: 0;
    background-color: white;
  }

  //It is used to prevent tooltip from being cut off
  .container {
    position: absolute;
    right: 0;
    z-index: 1;
    width: 160px;
    padding-left: 80px;
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: auto;

    .switch {
      position: absolute;
      top: 0;
      left: 0;
      right: 80px;
      height: 1276px;
      background-color: transparent;
    }
  }

  &.open {
    right: 0;
  }

  &:not(.open) {
    right: -160px;
  }

  nav {
    margin-bottom: 90px;
    .buttonsList {
      gap: 20px;
    }
  }

  .topMenuContainer {
    display: unset;
    padding-top: 20px;
  }

  .bottomBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    padding-bottom: 20px;
    padding-top: 0;
    border-bottom: 1.3px solid #242833;
  }
}
