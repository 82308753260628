.Controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 23px;

    &.noActiveButton {
      padding-top: 5px;
    }
  }
}

@media (max-width: 1024px) {
  .Controls {
    align-items: flex-start;
    .buttonsContainer {
      flex-wrap: wrap;
      gap: 13px;
      margin-right: 7px;

      &.noActiveButton {
        padding-top: 0;
      }
    }
  }
}
