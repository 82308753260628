.NoAvatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: white;
  background: linear-gradient(
    228.41deg,
    #00e4ac 0%,
    #10c5e9 53.88%,
    #9240dc 106.71%
  );
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(91, 200, 220);
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.05s linear;
  }

  &.hovered {
    &::after {
      transform: scale(1);
    }

    span {
      animation: bounce 0.4s ease-in-out forwards;
    }
  }

  &.notHovered {
    span {
      animation: bounceOut 0.4s ease-in-out forwards;
    }
  }

  span {
    position: relative;
    z-index: 1;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  40% {
    // transform: scale(0.75);
    transform: scale(1.25);
  }

  60% {
    //  transform: scale(0.83);
    transform: scale(1.17);
  }
  80% {
    //transform: scale(0.79);
    transform: scale(1.21);
  }

  90% {
    //  transform: scale(0.8);
    transform: scale(1.2);
  }
  100% {
    // transform: scale(0.8);
    transform: scale(1.2);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1.03);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
