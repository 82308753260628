.InfoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: rgba(193, 197, 214, 0.5);
  border-radius: 50%;

  &:hover {
    background-color: #00e4ac;

    svg {
      path {
        animation: blink-animation 0.1s forwards;
      }
    }
  }
}

@keyframes blink-animation {
  0%,
  100% {
    stroke: white; /* Start and end with black */
  }
  30% {
    stroke: black; /* Blink to white at the midpoint */
  }
}
