.ReportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .container {
    display: flex;
    flex-shrink: 0;
    gap: 12px;
    align-items: center;

    button {
      width: 50px;
      height: 50px;
      border-radius: 16px;
      border: transparent;
      background: transparent;
    }
  }

  h1 {
    margin: 0;
    flex-shrink: 0;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 20px;
    color: #242833;
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 18px;
  }
}
