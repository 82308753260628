.InterviewDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 15px;
  row-gap: 30px;

  .title {
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 24px;
    color: #242833;
  }

  & > div {
    flex-basis: calc(50% - 22px);
  }

  .notification {
    display: flex;
    flex-direction: column;

    .titleContainer {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .switchContainer {
        transform: scale(0.8);
      }
    }

    .form {
      position: relative;
      top: -3px;
      display: flex;
      flex-direction: row;

      .input:first-child {
        flex-basis: 50%;
      }

      .input:nth-child(2n) {
        flex-basis: 25%;
      }

      .input:last-child {
        flex-basis: 25%;
      }
    }
  }

  .mode {
    display: flex;
    gap: 22px;

    & > div {
      flex-basis: 50%;
    }
  }

  .connection {
    flex-basis: 100%;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .input {
      width: 100%;
      height: 45px;
      padding: 20px 15px;
      display: flex;
      align-items: center;
      font-family: Gilroy-SemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      background: white;
      border: none;
      outline: none;
      border-radius: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .InterviewDetails {
    display: flex;
    flex-direction: column;
    align-items: unset;
    gap: 20px;

    .notification {
      .form {
        top: 0;
        gap: 15px;
        &.hidden {
          display: none;
        }

        .input:first-child {
          flex: 1;

          flex-basis: unset;
        }

        .input:nth-child(2n) {
          width: 80px;
          min-width: 80px;
          flex-basis: unset;
        }

        .input:last-child {
          flex: 1;
          flex-basis: unset;
        }
      }
    }

    .mode {
      gap: 15px;
    }
  }
}
