.dialog {
  max-width: 1097px;
}

.modalContent {
  border-radius: 16px;
  overflow: hidden;
}

.JobPostPreviewModal {
  .content {
    max-height: 978px;
    border-radius: 16px;

    .closeIcon {
      position: absolute;
      top: 15px;
      right: 14px;
      display: block;
      width: 32px;
      height: 32px;
      font-size: 0;
      background-image: url(../../assets/images/modals/close.svg);
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .JobPostPreviewModal {
    .content {
      max-height: unset;

      .closeIcon {
        top: 7px;
        right: 7px;
        width: 20px;
        height: 20px;
        background-size: 20px;
      }
    }
  }
}
