.Stat {
  min-width: 43.14px;
  width: 43.14px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .graph {
    position: relative;
    flex: 1;
    background-color: #f0f2f5;
    border-radius: 38px;
    overflow: hidden;

    .fill {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: #6722d2;
      border-radius: 38px;
    }
  }

  h5 {
    height: 30px;
    margin: 0;
    font-family: 'Gilroy-Regular';
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #a2a3a5;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 1024px) {
  .Stat {
    min-width: auto;
    width: 35px;
    max-width: 35px;
    width: auto;
    flex: 1;

    h5 {
      display: none;
    }
  }
}
