.BasicInfo {
  display: flex;
  gap: 15px;

  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 70px;

    &:last-child {
      position: relative;
      top: -5px;
    }

    .numberRangeReadOnly {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .split {
        padding-top: 29px;
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
        line-height: 100%;
        color: #242833;
      }
    }

    .mediaContainer {
      h1 {
        margin: 0;
        margin-bottom: 50px;
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 20px;
        color: #242833;
      }

      .uploadersContainer {
        position: relative;
        display: flex;
        margin-bottom: -7px;

        .savedVideo {
          margin-left: 15px;
        }

        .savedVideo,
        .savedImage {
          position: relative;
          width: 193px;
          height: 316px;
          overflow: hidden;
          object-fit: cover;
          border-radius: 11px;

          .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            display: flex;
            gap: 20px;
            flex-direction: column;
            text-align: center;
            color: white;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .error {
          margin-top: 6px;
          font-family: 'Gilroy-Regular';
          font-size: 14px;
          line-height: 18px;
          color: #ff2d55;
        }

        .videoUploaderContainer {
          position: relative;
          left: 19px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .BasicInfo {
    flex-direction: column;
    gap: 20px;

    .col {
      gap: 20px;

      .mediaContainer {
        h1 {
          margin-bottom: 25px;
        }

        .uploadersContainer {
          .videoUploaderContainer {
            left: 13px;
          }
        }
      }
    }
  }
}
