.badgesContainer {
  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 13px;
    margin-bottom: 70px;
    padding-right: 10px;
  }
}
