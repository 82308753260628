.CounterOfferStatus {
  display: inline-flex;
  align-items: center;
  height: 28px;
  margin-top: 4px;
  padding-left: 11px;
  padding-right: 12px;
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: white;
  background-color: #ffbf00;
  border-radius: 16px;

  &.declined {
    background-color: #ff2d55;
  }

  &.accepted {
    background-color: #00e4ac;
  }
}
