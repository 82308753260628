.FontSelector {
  .fontOptions {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    .styleSelectorContainer {
      padding-top: 29px;
    }
  }

  .fontPreview {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    color: #242833;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    background-color: #f2f4f8;
    border-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .FontSelector {
    .fontOptions {
      .styleSelectorContainer {
        padding-top: 23px;
      }
    }

    .fontPreview {
      height: 45px;
      white-space: nowrap;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
