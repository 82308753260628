@keyframes fadeInFadeOut {
  0% {
    opacity: 0.9;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}
.blur {
  position: fixed;
  right: -5px;
  bottom: -20px;
  z-index: -1;
  min-width: 1350px;
  opacity: 0;
  background-color: rgba(37, 40, 51, 0.1);
  backdrop-filter: blur(1.5px);
  overflow-y: scroll;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;
}

.blurActivated {
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 1003;
}

.fadeInOut {
  transition: opacity 0.3s ease-in-out;
  animation: fadeInFadeOut 0.3s ease-in-out;
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
  z-index: 1002;
  padding: 29px 23px;
  width: 1144px;
  min-height: 100vh;
  background-color: white;
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0.3s linear, visibility 0.3s linear;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .closeIconContainer {
    position: absolute;
    top: 62px;
    left: -67px;
  }
}

.visible {
  visibility: visible;
  transform: translateX(0);
}

.candidateInfo {
  margin-top: 24px;
  padding: 28px 39px 0 26px;
  width: 100%;
  min-height: 766px;
  background-color: rgb(255, 255, 255);
  border: 2px solid white;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 16px;
}

.links {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.withoutGap {
  justify-content: start;
  gap: 40px;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: #c1c5d6;
  cursor: pointer;

  .counter {
    width: 48px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #c1c5d6;
    border-radius: 16px;
    color: white;
  }

  .counterActive {
    background-color: #00e4ac;
  }

  .noData {
    background: #c1c5d6;
    color: white;
  }
}

.linkActive {
  position: relative;
  color: #242833;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
  }
}

.hidden {
  display: none;
}

.open {
  display: block;
}

@media (max-width: 1024px) {
  .blurActivated {
    min-width: unset;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    left: 15px;
    right: 15px;
    bottom: 0;
    width: unset;
    max-width: 100%;
    height: unset;
    min-height: unset;
    background-color: transparent;

    padding: 0;
    .closeIconContainer {
      display: none;
    }
  }

  .visible {
    visibility: visible;
    transform: translateX(0);
  }

  .candidateInfo {
    height: unset;
    min-height: unset;
    flex: 1;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .links {
    gap: 40px;
    margin-bottom: 22px;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .link {
    padding-bottom: 15px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
  }

  .linkActive {
    &::after {
      bottom: 0;
    }
  }
}
