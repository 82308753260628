.Column {
  display: flex;
  align-items: center;
  gap: 16px;

  .checkbox {
    width: 25px;
    height: 25px;
    background-image: url('../../../../../../../assets/images/dashboard/reports/noChecked.svg');
    cursor: pointer;

    &.active {
      background-image: url('../../../../../../../assets/images/dashboard/reports/checked.svg');
      border-radius: 0;
    }
  }

  span {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
  }
}