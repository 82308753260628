.RecruitmentFunnel {
  display: flex;
  flex-direction: column;
  width: 319px;
  height: 100%;
  min-height: 291px;
  padding-top: 18px;
  padding-left: 11px;
  padding-right: 19px;
  padding-bottom: 16px;
  background-color: white;
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 16px;

  .header {
    margin-bottom: 29px;
    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #242833;
    }
  }

  .noData {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    p {
      width: 226px;
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin: 0;
    padding: 0;
    padding-left: 8px;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      .title {
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
        line-height: 19px;
        color: #191c32;
      }

      .data {
        display: flex;
        gap: 15px;
        .value {
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: #191c32;
        }

        .dynamic {
          display: block;
          width: 50px;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: #c1c5d6;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .RecruitmentFunnel {
    width: 100%;
    min-height: 237px;
    padding: 15px;

    .header {
      margin-bottom: 20px;
      h2 {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .noData {
      p {
      }
    }

    ul {
      li {
        height: 17px;
        .title {
          font-size: 14px;
        }

        .data {
          .value {
            font-size: 14px;
          }

          .dynamic {
            font-size: 14px;
          }
        }
      }
    }
  }
}
