@keyframes leftAnimation {
  0% {
    margin-left: 0px;
  }
  25% {
    margin-left: 5px;
  }
  50% {
    margin-left: 10px;
  }
  75% {
    margin-left: 5px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes rightAnimation {
  0% {
    margin-left: 20px;
  }
  25% {
    margin-left: 15px;
  }
  50% {
    margin-left: 13px;
  }
  75% {
    margin-left: 15px;
  }
  100% {
    margin-left: 20px;
  }
}

.Switch {
  display: flex;
  align-items: center;
  gap: 10px;

  &.enabled {
    .switch {
      background: var(--backgroundColor);

      .toggle {
        margin-left: 20px;
      }
    }
  }

  .label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;
    color: #242833;
  }
  .jumpLeft {
    animation: leftAnimation 0.2s ease-in-out ;
  }
  .jumpRight {
    animation: rightAnimation 0.2s ease-in-out ;
  }

  .switch {
    display: flex;
    align-items: center;
    width: 54px;
    min-width: 54px;
    height: 30px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: #c1c5d6;
    border-radius: 50px;
    cursor: pointer;

    .toggle {
      width: 20px;
      height: 20px;
      background-color: #eff1f5;
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
    }
  }
}
