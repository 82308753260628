.dialog {
  max-width: 785px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.ShareModal {
  border-radius: 20px;

  .content {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    padding-bottom: 28px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 40px;
        line-height: 40px;
        color: #242833;
      }

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close.svg);
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      gap: 50px;
      margin-bottom: 21px;

      .col {
        display: flex;
        flex-direction: column;
        flex: 1;

        .linkButton {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 186px;
          height: 50px;
          margin-bottom: 41px;
          margin-left: auto;
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 24px;
          color: #242833;
          background-color: #f2f4f8;
          border: 1px solid #f2f4f8;
          border-radius: 16px;
        }

        .share {
          .selectContainer {
            margin-bottom: 26px;
          }
        }

        .shareDetails {
          margin-bottom: 25px;
          h2 {
            margin: 0;
            font-family: 'Gilroy-SemiBold';
            font-size: 35px;
            line-height: 36px;
            color: #242833;
          }

          .location {
            display: inline-block;
            height: 24px;
            font-family: 'Gilroy-Regular';
            font-size: 16px;
            line-height: 24px;
            color: #242833;
          }
        }
      }
    }

    footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
