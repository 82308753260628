.Recommendation {
  .container {
    display: flex;
    align-items: center;
    gap: 10px;

    &.yes {
      .thumb {
        background-image: url(../../../../../../../../../assets/images/chat/thumb-up.svg);
      }

      span {
        color: #00e4ac;
      }
    }

    &.no {
      .thumb {
        background-image: url(../../../../../../../../../assets/images/chat/thumb-down.svg);
      }
      span {
        color: #ff2d55;
      }
    }

    span {
      font-family: 'National 2 Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 42px;
      text-align: center;
    }

    .thumb {
      width: 25px;
      height: 25px;
    }
  }
}
