.UserValidation {
  .title {
    margin-bottom: 20px;
    font-family: Gilroy-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }

  p {
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    &.error {
      color: rgba(255, 45, 85, 1);
    }

    &.success {
      color: rgba(0, 228, 172, 1);
    }
  }
}
