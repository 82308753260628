.QuoteInput {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 11px;
  padding-right: 16px;
  background-color: #f2f4f8;
  border-radius: 16px;

  .input {
    width: 100%;
    // min-height: 30px;
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 20px;
    color: #242833;
    background-color: #f2f4f8;
    border: 1px solid #242833;
    border-radius: 8px;
    &:focus {
      outline: none;
    }
  }
}
