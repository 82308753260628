.LeftPanel {
  width: 237px;
  height: calc(100vh - 80px);
  padding-bottom: 8px;
  padding-top: 18px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;

  &.hide {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 20px;
    color: #242833;

    .hideButton {
      width: 40px;
      height: 40px;
      border: transparent;
      background: transparent;
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 16px;
      border: 1px solid #c1c5d6;

      &:hover {
        background-color: #00e4ac;

        svg {
          path {
            fill: white;
            stroke: white;
          }
        }
      }
    }
  }

  .container {
    height: calc(100% - 74px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }
}

@media (max-width: 1024px) {
  .LeftPanel {
    min-width: 237px;
    height: unset;
    margin-top: 20px;
    margin-left: 15px;

    &.hide {
    }

    .header {
      button {
      }
    }

    .container {
      &::-webkit-scrollbar {
      }
    }
  }
}
