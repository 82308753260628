.ViewsGraph {
  position: relative;
  height: 408px;
  padding: 15px;
  padding-bottom: 36px;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }

    .info {
      display: flex;
      gap: 15px;

      .view,
      .applicant,
      .type {
        position: relative;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #707070;
      }

      .view,
      .applicant {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          background: #00e4ac;
          border-radius: 4px;
        }
      }
      .view {
        &::after {
          background: #6722d2;
        }
      }

      .type {
        padding-right: 20px;
        display: flex;
        justify-content: end;
        cursor: pointer;

        &::after {
          content: '';
          top: 10px;
          right: 2px;
          position: absolute;
          width: 0px;
          height: 0px;
          border-top: 6px solid black;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }

        .dropdown {
          position: absolute;
          top: calc(100% + 5px);
          right: 0;
          z-index: 1;
          width: 130px;
          padding: 10px 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          background: #ffffff;
          box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          cursor: pointer;

          .option {
            &:hover {
              font-size: 18px;
            }
          }

          .activeOption {
            color: #00e4ac;
          }
        }
      }

      .selected {
        &::after {
          content: '';
          top: 3px;
          right: 2px;
          position: absolute;
          width: 0px;
          height: 0px;
          border-bottom: 6px solid black;
          border-top: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .ViewsGraph {
    height: 296px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    .header {
      .title {
        margin-right: 7px;
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
      }

      .info {
        gap: 5px;
        .view,
        .applicant,
        .type {
          font-size: 14px;
        }

        .type {
          .dropdown {
          }
        }
      }
    }
  }
}
