.WeekMode {
  display: flex;
  align-items: center;
  gap: 15px;

  .weekDays {
    flex: 1;
    display: flex;
    align-items: center;
    height: 160px;
    background-color: white;
    border-radius: 16px;

    .day {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      color: #c1c5d6;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        display: block;
        width: 2px;
        height: 130px;
        background-color: #f2f4f8;
      }

      .dayNum {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 20px;
        color: #242833;
        border-radius: 12px;
      }

      .selected {
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        box-shadow: 0px 1.5px 1px rgba(0, 31, 112, 0.25),
          0px 3px 2px rgba(0, 71, 255, 0.2);
        color: white;
      }

      .today {
        box-shadow: none;
        background: #6722d2;
        color: white;
      }

      &:nth-child(6) {
        &::after {
          display: none;
        }
      }

      &:first-child,
      &:last-child {
        max-width: 55px;
        background-color: #c1c5d6;
        border-radius: 16px 0 0 16px;

        &::after {
          display: none;
        }

        .dayNum {
          color: white;
        }

        color: #ffffff;
      }

      &:last-child {
        border-radius: 0 16px 16px 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .WeekMode {
    .weekDays {
      min-width: 937px;
      .day {
        &::after {
        }

        .dayNum {
        }

        .selected {
        }

        .today {
        }

        &:nth-child(6) {
          &::after {
          }
        }

        &:first-child,
        &:last-child {
          &::after {
          }

          .dayNum {
          }
        }

        &:last-child {
        }
      }
    }
  }
}
