@use '../../../../../../../variables' as *;

.User {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: 'Gilroy-Regular';
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &.allMembers {
    img {
      object-fit: contain;
    }
  }

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 14px;
  }

  .noPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: white;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    border-radius: 14px;
  }
}

@media (max-width: $breakpoint) {
  .User {
    gap: 10px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 8px;
    }

    .noPhoto {
      width: 30px;
      height: 30px;
      border-radius: 8px;
    }
  }
}
