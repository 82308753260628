.Word {
  padding-top: 68px;

  &.addCandidateModal {
    .wordContainer {
      width: calc(100% - 84px);
    }
  }

  .wordContainer {
    padding: 0px 144px 0 26px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    height: 61px;
    border: 1px solid #c1c5d6;
    font-family: 'Gilroy-SemiBold';
    color: #000;

    .wordName {
      font-size: 18px;
      cursor: pointer;
    }

    .date {
      font-size: 16px;
    }
  }

  .icons {
    position: absolute;
    top: 0;
    right: -25px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .icon {
      width: 50px;
      height: 50px;
      background: rgba(243, 244, 248, 1);
      cursor: pointer;
    }

    .search {
      position: relative;
      background: no-repeat center
        url('../../../../../assets/images/dashboard/candidate/wordResume/searchDisabled.svg');
      cursor: auto;
    }

    .download {
      background: no-repeat center
        url('../../../../../assets/images/dashboard/candidate/wordResume/download.svg');
    }

    .print {
      background: no-repeat center
        url('../../../../../assets/images/dashboard/candidate/wordResume/print.svg');
    }
  }
}

@media (max-width: 1024px) {
  .Word {
    padding-top: 89px;

    &.addCandidateModal {
      margin-top: 0 !important;
      .wordContainer {
        width: 100%;
      }
    }

    .wordContainer {
      padding: 15px;
      width: 100%;

      .wordName {
        font-size: 14px;
      }

      .date {
        font-size: 14px;
      }
    }

    .icons {
      right: 0;
      flex-direction: row;
      .icon {
        width: 30px;
        height: 30px;
        background-size: contain;
      }
    }
  }
}
