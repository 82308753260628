.overlay {
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 80px;
  right: 0;
  bottom: 0;
  background-color: rgba(37, 40, 51, 0.1);
  backdrop-filter: blur(1.5px);
}

.Favorites {
  position: fixed;
  top: 127px;
  left: 50%;
  transform: translateX(calc(-50% + 40px));
  z-index: 1002;
  opacity: 1;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .container {
    position: relative;
    width: 1097px;
    padding-bottom: 25px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .header {
      padding-top: 18px;
      padding-left: 15px;
      padding-right: 15px;

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url('../../../assets/images/dashboard/candidate/grayClose.svg');
        cursor: pointer;
      }

      .title {
        margin-bottom: 15px;
        font-family: 'Gilroy-Bold';
        font-size: 25px;
        line-height: 30px;
        color: #242833;
      }

      .menu {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 2px solid #d9d9d9;

        .tabs {
          display: flex;
          align-items: flex-end;
          gap: 40px;

          .tab {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            .text {
              font-family: 'Gilroy-SemiBold';
              font-size: 14px;
              line-height: 20px;
              color: #c1c5d6;
            }

            .textActive {
              color: #242833;
            }

            .amount {
              width: 40px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: Gilroy-SemiBold;
              font-size: 12px;
              line-height: 20px;
              color: white;
              background: #c1c5d6;
              border-radius: 16px;
            }

            .amountActive {
              background: #00e4ac;
            }
          }

          .tabActive {
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 4px;
              bottom: -23px;
              background: linear-gradient(
                228.41deg,
                #00e4ac 0%,
                #10c5e9 53.88%,
                #9240dc 106.71%
              );
            }

            .text {
              color: #242833;
            }
            .amount {
              background: #00e4ac;
            }
          }
        }
      }
    }

    .form {
      display: flex;
      gap: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .overlay {
    left: 0;
  }

  .Favorites {
    top: 121px;
    left: 15px;
    right: 15px;
    bottom: 48px;
    transform: unset;

    .container {
      width: unset;
      .header {
        flex-direction: column;

        .menu {
          flex-direction: column-reverse;
          gap: 15px;
          .tabs {
            justify-content: space-between;
            gap: unset;
          }
        }
      }
    }
  }
}
