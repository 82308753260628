.timeline {
  font-family: 'Gilroy-SemiBold';

  .noData {
    text-align: center;
    padding-top: 165px;
    font-size: 20px;
    font-weight: 600;
    color: #c1c5d6;
  }

  .prev,
  .next {
    width: 9px;
    height: 16px;
    cursor: pointer;
  }

  .prev {
    background: no-repeat
      url('../../../../assets/images/dashboard/candidate/arrow.svg');
  }

  .next {
    background: no-repeat
      url('../../../../assets/images/dashboard/candidate/arrow.svg');
    transform: rotate(180deg);
  }

  .header {
    margin-top: 32px;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .date {
      margin-right: 14px;
      display: flex;
      gap: 15px;
      align-items: center;
      font-family: 'Gilroy-Medium';
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
    }

    .legend {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 19px;
      padding-right: 19px;
      font-size: 16px;
      line-height: 19px;
      background-color: #f2f4f8;
      border-radius: 16px;

      .legendTitle {
        margin-bottom: 4px;
        font-family: 'Gilroy-Medium';
        font-size: 16px;
        font-style: normal;
        line-height: 20px;
        color: #242833;
      }

      .legendContent {
        display: flex;
        gap: 20px;
      }

      .today,
      .eventsLegend,
      .selected {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 20px;

        .circle {
          width: 17px;
          height: 17px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .today {
        .circle {
          background-image: url('../../../../assets/images/dashboard/candidate/timeline/gradientPoint.svg');
        }
      }

      .eventsLegend {
        .circle {
          background-image: url('../../../../assets/images/dashboard/candidate/timeline/bluePoint.svg');
        }
      }

      .selected {
        .circle {
          width: 49px;
          height: 20px;
          background-image: url('../../../../assets/images/dashboard/candidate/timeline/greenPoint.svg');
        }
      }
    }
  }

  .calendar {
    margin-top: 32px;
    display: flex;
    gap: 15px;
    align-items: center;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 20px;

    .days {
      width: 100%;
      display: flex;
      gap: 27px;
      overflow: hidden;

      .day {
        min-width: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        cursor: pointer;
      }

      .selectedLine {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 70px;
          height: 30px;
          bottom: 0;
          display: block;
          border: 1px solid #00e4ac;
          border-right: 0px;
          border-left: 0;
        }
      }

      .selectedLineFirst {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          border-radius: 10px 0 0 10px;
          border-left: 1px solid #00e4ac;
        }
      }

      .selectedLineSecond {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          border-radius: 0 10px 10px 0;
          border-right: 1px solid #00e4ac;
        }
      }

      .eventToday,
      .eventDay,
      .eventSelected {
        margin-top: -5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .eventDay {
        background: url('../../../../assets/images/dashboard/candidate/timeline/eventDay.svg');
      }

      .eventToday {
        color: white;
        background: url('../../../../assets/images/dashboard/candidate/timeline/eventToday.svg');
      }

      .eventSelected {
        color: white;
        background: url('../../../../assets/images/dashboard/candidate/timeline/eventSelected.svg');
      }
    }
  }

  .timeline {
    margin-top: 15px;
    padding: 0px 15px 0px;
    overflow: scroll;
    max-height: 360px;
  }
}

.inputs {
  display: flex;
  font-family: 'Gilroy-Regular';
  gap: 20px;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .timeline {
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .noData {
      padding-top: 118px;
    }

    .header {
      .legend {
        margin-right: 60px;
      }
    }

    .calendar {
      width: 1030px;
      overflow: visible;
    }

    .timeline {
      max-height: unset;
    }
  }
}
