@use '../../../../../../variables' as *;

.quoteContainer {
  height: 100px;
}

.Toolbar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 0.5px solid #c1c5d6;

  .leftTools {
    display: flex;
    gap: 26px;
    .leftButton {
      position: relative;
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 0;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 16px;
      color: #c1c5d6;
      background-color: transparent;
      border: none;

      &:hover {
        .fileCount {
          background-color: #00e4ac;
        }
      }

      .fileCount {
        position: absolute;
        top: -6px;
        right: -20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 12px;
        font-family: 'Gilroy-SemiBold';
        font-size: 10px;
        line-height: 24px;
        color: white;
        background-color: #c1c5d6;
        border-radius: 10px;
      }

      img {
        &.active {
          display: none;
        }
      }

      &:hover {
        color: #0fc7e6;

        img {
          &.default {
            display: none;
          }

          &.active {
            display: block;
          }
        }
      }
    }
  }

  .rightTools {
    display: flex;
    align-items: center;
    gap: 12px;
    .charsLeft {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #c1c5d6;

      &.red {
        color: #ff2d55;
      }
    }

    .rightButton {
      display: flex;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width: $breakpoint) {
  .Toolbar {
    flex-direction: column-reverse;
    gap: 20px;
    padding-bottom: 20px;
    .leftTools {
      gap: 10px;
      .leftButton {
        gap: 5px;
      }
    }
  }
}
