.Console {
  position: relative;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 36px;
  background-color: #f2f4f8;

  .title {
    margin-bottom: 28px;
    font-family: Gilroy-Bold;
    font-size: 20px;
    line-height: 25px;
    color: #242833;
  }

  .managementMenuContainer {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .Console {
    min-height: calc(100dvh - 54px);
    overflow-x: auto;

    .title {
      margin-bottom: 15px;
    }

    .managementMenuContainer {
      margin-bottom: 15px;
    }
  }
}
