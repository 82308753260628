.JobNotification {
  margin-top: 37px;
  font-family: 'Gilroy-SemiBold';
  font-size: 24px;
  line-height: 36px;
  color: #3C3C43;

  .notifications {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}