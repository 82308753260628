.Tabs {
  max-width: 557px;
  margin-bottom: 24px;
  .tabsContainer {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }

  .line {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #f2f4f8;

    .lineFill {
      position: absolute;
      top: -1px;
      width: 20px;
      height: 3px;
      background-image: linear-gradient(
        228deg,
        #00e4ac 0%,
        #10c5e9 50.5%,
        #9240dc 100%
      );
      transition: all 0.3s linear;
    }
  }
}

@media (max-width: 1024px) {
  .Tabs {
    max-width: 100%;

    .tabsContainer {
      justify-content: space-between;
    }
  }
}
