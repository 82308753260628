.dialog {
  max-width: 497px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 16px;
  border: none;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}

.AddCandidateNoteModal {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 23px;
    padding-bottom: 23px;
    border-radius: 16px;

    .textareaContainer {
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      gap: 23px;
    }
  }
}
