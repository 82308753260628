.Textarea {
  position: relative;
  display: flex;
  flex-direction: column;

  &.error {
    textarea {
      border: 1.5px solid #ff2d55;
    }

    .status {
      color: #ff2d55;
    }
  }

  &.success {
    textarea {
      border: 1.5px solid #00e4ac;
    }

    .status {
      color: #ff2d55;
    }
  }

  &.warning {
    textarea {
      border: 1.5px solid #ffbf00;
    }

    .status {
      color: #ffbf00;
    }
  }

  .charCounter {
    position: absolute;
    bottom: -24px;
    right: 0;
    font-family: 'Gilroy-Regular';
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #c1c5d6;
  }

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 20px;
    color: #242833;

    .infoIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }

    .note {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;
    }

    .maxLength {
      font-family: 'Gilroy-Regular';
      color: #c1c5d5;
    }
  }

  textarea {
    height: 60px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 16px;
    border: none;
    resize: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00e4ac;
      border-radius: 6px;
    }

    &::placeholder {
      color: #c1c5d6;
    }

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      &:not(:read-only) {
        background-color: white;
        box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.12);
        border-radius: 16px;
      }
    }
  }

  .status {
    margin-top: 6px;
    font-size: 14px;
    line-height: 18px;
  }
}
