.ViewRestaurant {
  display: flex;
  flex-direction: column;
  gap: 58px;

  .row {
    display: flex;
    gap: 63px;

    .col {
      display: flex;
      flex-direction: column;
      gap: 58px;
      flex: 1;
    }
  }
}
