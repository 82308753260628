.CollapsedPostContent {
  width: 100%;
  padding-top: 20px;
  padding-right: 24px;

  .container {
    display: flex;
    justify-content: space-between;

    .textContainer {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 10px;
      font-size: 16px;
      line-height: 150%;

      p {
        margin: 0;
        font-family: 'Gilroy-Regular';
        color: #242833;

        .bold {
          font-family: Gilroy-Bold;
        }

        h3 {
          margin: 0;
          font-family: Gilroy-Bold;
          color: #242833;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .expandButton {
        display: flex;
        gap: 10px;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 150%;
        color: #c1c5d6;
        background-color: transparent;
        border: none;
      }
    }
  }
}
