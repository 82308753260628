.PasswordValidation {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text {
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      line-height: 20px;
      color: #242833;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 0;
      padding: 0;
      padding-left: 20px;
      list-style: none;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #242833;

      .rule {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: calc(100% + 10px);
          width: 3px;
          height: 3px;
          background: #242833;
          border-radius: 50%;
          transform: translateY(-50%);
        }
      }

      .activeRule {
        color: #00e4ac;

        &::before {
          content: '\2714';
          right: calc(100% + 6px);
          width: auto;
          height: auto;
          background: transparent;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .PasswordValidation {
    margin-bottom: 102px;
  }
}
