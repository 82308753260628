.TitleWithTooltip {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 13px;

  h2 {
    margin: 0;
    font-family: Gilroy-Bold;
    color: #242833;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #e0e2ea;
    border-radius: 50%;

    &:hover {
      background-color: #00e4ac;

      svg {
        path {
          animation: blink-animation 0.1s forwards;
        }
      }
    }
  }
}

@keyframes blink-animation {
  0%,
  100% {
    stroke: white; /* Start and end with black */
  }
  30% {
    stroke: black; /* Blink to white at the midpoint */
  }
}
