.MediaUploader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 193px;
  height: 319px;
  background-color: rgba(242, 244, 248, 0.5);
  border: 3px dashed #c1c5d6;
  border-radius: 16px;
  cursor: pointer;

  &.withFile {
    border: none;
    .text {
      span {
        color: white;
      }
    }
  }

  input {
    display: none;
  }

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

  .text {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'Gilroy-Bold';
    font-size: 13px;
    line-height: 100%;
    text-align: center;

    .allowedFormats {
      font-family: Gilroy-Regular;
      color: darkgrey;
    }

    .videoLength {
      position: absolute;
      bottom: -119px;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'Gilroy-Regular';
      font-size: 13px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }

    span {
      display: inline-block;
    }
  }

  i {
    position: absolute;
    z-index: 1;
    top: -15px;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    &.deleteIcon {
      top: -12px;
      right: -12px;
      background-color: #ff2d55;
    }

    &.addIcon {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
    }
  }

  .error,
  .warning {
    position: absolute;
    bottom: -24px;
    left: 0;
    width: 100%;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 18px;
    color: #ff2d55;
    white-space: nowrap;
  }

  .warning {
    color: #ffbf00;
  }
}

@media (max-width: 1024px) {
  .MediaUploader {
    width: calc((100vw - 60px) / 2 - 9px);
    height: 282px;
    padding-left: 10px;
    padding-right: 10px;

    .text {
      .videoLength {
        bottom: -100px;
      }
    }

    &:has(.warning) {
      margin-bottom: 36px;
    }

    &:has(.error) {
      margin-bottom: 18px;
    }

    .warning {
      bottom: -42px;
      white-space: normal;
    }
  }
}
