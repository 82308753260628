.mouseLeaveEnter {
  position: relative;
  width: 150px;
  height: 170px;
  flex-shrink: 0;
  .cardWrapper {
    width: 150px;
    height: 170px;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 16px;
    &:hover {
      .Card {
        transform: scale(1.1);
      }
    }
  }

  .Card {
    position: relative;
    padding: 15px;
    width: 150px;
    height: 170px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    color: white;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(180deg, rgba(36, 40, 51, 0) 0%, #242833 100%),
      url('../../../../../assets/images/dashboard/discover/example.svg');
    // background: linear-gradient(180deg, rgba(36, 40, 51, 0) 0%, #242833 100%);
    background-size: cover;
    background-position: center;
    cursor: pointer;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      color: white;
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: 1px;
      white-space: nowrap;

      .title {
        font-family: 'Gilroy-Bold';
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtitle {
        font-family: 'Gilroy-Bold';
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: #fdfefe;
        opacity: 0.8;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  img,
  .noPhoto {
    position: relative;
    z-index: 1;
    width: 28px;
    height: 28px;
    border-radius: 7px;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    border: 1px solid
      linear-gradient(228.41deg, #00e4ac 0%, #10c5e9 53.88%, #9240dc 106.71%);
  }

  .noPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    font-size: 18px;
    color: white;
  }

  .personContainer {
    position: absolute;
    bottom: -30px;
    left: 26px;
    cursor: pointer;

    &:hover::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 15px;
      background: linear-gradient(
        222.81deg,
        #00e4ac -4.27%,
        #10c5e9 61.98%,
        #9240dc 113.72%
      );
    }

    .personInfo {
      position: fixed;
      z-index: 100;
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      white-space: nowrap;
      color: #242833;
      background-color: white;
      box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
      border-radius: 8px;

      .avatarContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-image: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 7px;
      }

      .text {
        display: flex;
        flex-direction: column;
        .name {
          margin-bottom: 4px;
          font-family: 'Gilroy-Bold';
          font-size: 12px;
          line-height: 8px;
        }

        .email {
          font-family: 'Gilroy-Regular';
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}
