.JobHeader {
  // max-width: calc(100vw - 194px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 13px;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  .row {
    display: flex;
    gap: 75px;
    justify-content: space-between;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 100%;
    height: 33px;
    padding-top: 7px;
    word-break: break-word;
    overflow: hidden;

    h1 {
      margin: 0;
      max-width: 100%;
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 25px;
      color: #242833;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .jobControls {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 15px;

    .share,
    .qrq {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .share {
      margin-right: -5px;
      background: url('../../../../../../assets/images/dashboard/jobs/share-icon.svg');
    }

    .qrq {
      margin-right: -7px;
      background: url('../../../../../../assets/images/dashboard/jobs/QRQ-icon.svg');
    }
  }

  .jobInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .details {
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;

      .value {
        margin-left: 15px;
        color: #242833;
      }
    }
  }
}

@media (max-width: 1024px) {
  .JobHeader {
    position: relative;

    height: unset;
    justify-content: unset;

    .title {
      height: 20px;
      margin-bottom: 20px;
      padding-top: 0;
      h1 {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .jobControls {
      // position: absolute;
      flex-wrap: wrap;
      width: 100%;
    }

    .jobInfo {
      gap: 10px;
      margin-bottom: 20px;
      .details {
        line-height: 20px;
        .value {
          margin-left: 5px;
        }
      }
    }
  }
}
