.Notification {
  display: flex;
  gap: 174px;

  .col {
    flex: 1;
  }

  .title {
    margin-bottom: 27px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 15px;
    color: #242833;
  }

  .notifications {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
