.LeftPanel {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 65px);
  margin-right: 7.5px;
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 7.5px;
  padding-bottom: 41px;
  overflow-y: auto;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .row {
    display: flex;
    gap: 15px;

    &.first {
      padding: 15px;
      margin-bottom: 25px;
      background-color: white;
      border-radius: 16px;
    }

    &.second {
      margin-bottom: 23px;
    }

    &.third {
      flex: 1;
      gap: 21px;
    }

    .col {
      &.flexOne {
        flex: 1;
      }
    }
  }
}

@media (max-width: 1024px) {
  .LeftPanel {
    flex: unset;
    height: auto;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    overflow-y: unset;

    .row {
      flex-direction: column;
      gap: 25px;

      &.first {
        margin-bottom: 43px;
        padding: 0;
        padding-right: 15px;
        background-color: transparent;
      }

      &.second {
        margin-bottom: 23px;
        overflow-x: auto;
      }

      &.third {
        display: none;
      }
    }
  }
}
