.SortIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(calc(-50% + 2px));
  display: none;
  align-items: center;
  // visibility: hidden;
  &.desc {
    transform: translateY(-50%) scaleY(-1);
  }

  &.visible {
    display: flex;
    // visibility: visible;
  }
}
