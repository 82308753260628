.SelectedAssessments {
  padding: 0 22px 0 25px;
  flex: 1 1;

  .assessmentHeader {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: 'Gilroy-SemiBold';

    h2 {
      font-size: 22px;
    }

    .amount {
      width: 48px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #c1c5d6;
      border-radius: 16px;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;

      &.green {
        background-color: #00e4ac;
      }
    }
  }

  .search {
    margin-top: 18px;
  }

  .content {
    position: absolute;
    padding-top: 18px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 25px;
    margin-left: -25px;
    width: 439px;
    height: 373px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }
}
