.week {
  position: absolute;
  width: 100%;

  .cardContainer {
    position: relative;
    width: 100%;

    &:hover {
      z-index: 2;
      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 9px;
      }
    }
  }

  .title {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 18px;
    color: #242833;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .person {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    margin-top: 20px;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 150%;
    color: #c1c5d6;
  }

  .card {
    position: relative;
    z-index: 1;
    // padding: 8px 12px 5px 20px;
    padding-top: 8px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 12px;
    width: 100%;
    min-height: 23px;
    height: 100%;
    min-height: 45px;
    background: white;
    box-shadow: 0px 4px 60px rgba(24, 39, 75, 0.1);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background-color: transparent;
      border-radius: 8px 0 0 8px;
    }

    &:hover ~ .popup {
      opacity: 1;
      visibility: visible;
      transition: opacity 2s step-end;
    }

    .cardInfo {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .popup {
    opacity: 0;
    visibility: hidden;
  }

  .joining {
    background: #d2faff;

    &::after {
      background: #d2faff;
    }

    .date {
      color: #242833;
    }
  }

  .selected {
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 9px;
    }
  }

  .onboarding {
    background: #cc9cff;

    &::after {
      background: #cc9cff;
    }

    .date {
      color: #242833;
    }
  }

  .canceled {
    &::before {
      background-color: #ff2d55;
    }
  }

  .accepted {
    &::before {
      background-color: #00e4ac;
    }
  }

  .completed {
    &::before {
      background-color: #242833;
    }
  }

  .pending {
    &::before {
      background-color: #c1c5d6;
    }
  }
  .declined {
    &::before {
      background-color: #ffcc33;
    }
  }
}

.twoCard {
  .cardContainer {
    position: relative;
    top: 0 !important;
    height: 46px !important;
  }

  .person {
    display: none;
  }

  .date {
    margin-top: 0;
  }
}

.threeCard {
  .cardContainer {
    position: relative;
    top: 0 !important;
    height: 31px !important;
  }

  .card {
    .person,
    .date {
      display: none;
    }
  }
}

.fourCard {
  .cardContainer {
    position: relative;
    top: 0 !important;
    padding-top: 4px;
    height: 23px !important;
  }

  .card {
    .person,
    .date {
      display: none;
    }
  }
}

.noCard {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: #242833;
  background: white;
  box-shadow: 0px 4px 25px rgba(24, 39, 75, 0.1);
  border-radius: 8px;

  .seeAll {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    color: #00e4ac;
    cursor: pointer;
  }
}
