.RightPanelHeader {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding-top: 8px;
  padding-left: 35px;
  padding-right: 40px;
  border: 3px solid #f8f9fb;
  border-left-width: 1px;

  .col {
    display: flex;

    &.searchInputContainer {
      justify-content: flex-end;
      width: 350px;
    }

    .userPhoto {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
    }

    .user {
      margin-left: 15px;
      display: flex;
      gap: 13px;
      align-items: flex-start;

      .userInfo {
        h4 {
          margin-bottom: 0;
          font-family: 'Gilroy-SemiBold';
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.03em;
        }

        .location {
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.03em;
          color: #7a7a7a;
        }
      }

      .userFav {
        margin-top: 4px;
      }

      .profileButton {
        position: relative;
        top: 3px;
        width: 100px;
        height: 28px;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: white;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 16px;
        border: none;
      }
    }

    .callButtonsContainer {
      display: flex;
      gap: 10px;
      margin-left: 10px;
    }

    .dotsButtonContainer {
      margin-left: 9px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f9fafc;
      border-radius: 50%;

      .dotsButton {
        width: 48px;
        height: 48px;
        font-size: 0;
        background-image: url(../../../../../assets/images/chat/dots.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        border: none;
      }
    }
  }
}
