.SetNewPassword {
  position: relative;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 40px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 15px;
  }

  .container {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;

    .column {
      flex: 1;
      flex-direction: column;
      display: flex;
      gap: 15px;
    }
  }

  .buttons {
    position: absolute;
    bottom: 0;
    align-self: center;
    display: flex;
    gap: 30px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 32px;
  }
}

@media (max-width: 1024px) {
  .SetNewPassword {
    .title {
    }

    .container {
      flex-direction: column;
      gap: 30px;
      .column {
        gap: 30px;
      }
    }

    .validations {
      margin-bottom: 102px;
    }
    .buttons {
      position: static;
      flex-direction: column;
      gap: 15px;
      width: 100%;
    }
  }
}
