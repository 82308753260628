.Button {
  width: 22px;
  height: 30px;
  font-family: Gilroy-SemiBold;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(177, 182, 202, 1);
  background-color: rgba(236, 237, 242, 0);
  border: none;
  border-radius: 24px;
  transition: background-color 0.3s cubic-bezier(0.42, 0, 0.58, 1);

  &.active {
    width: 41px;
    color: white;
    background-color: rgba(0, 228, 172, 1);
    border-radius: 24px;

    &:hover {
      background-color: rgba(194, 197, 213, 1);
    }
  }

  &:hover {
    background-color: rgba(236, 237, 242, 1);
  }
}
