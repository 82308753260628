.RightPanel {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;
  min-width: max-content;
}

@media (max-width: 1024px) {
  .RightPanel {
    padding-bottom: 53px;
  }
}
