.Event {
  display: flex;
  gap: 14px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  &:hover {
    .leftCol {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: -1px;
        left: -1px;
        display: block;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 17px;
      }
    }
  }

  &.today {
    .leftCol {
      .dateCard {
        background-color: #00e4ac;
      }
    }
  }

  .leftCol {
    .dateCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 80px;
      height: 80px;
      padding-top: 6px;
      padding-bottom: 6px;
      background-color: #242833;
      border-radius: 16px;

      h5 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 22px;
        color: white;
        text-transform: uppercase;
      }

      .date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 67px;
        height: 42px;
        font-family: 'Gilroy-Bold';
        font-size: 32px;
        line-height: 38px;
        color: #242833;
        background-color: white;
        border-radius: 16px;
      }
    }
  }

  .rightCol {
    h4 {
      margin-bottom: 3px;
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 20px;
      color: #242833;
    }

    .time {
      margin-bottom: 3px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 15px;
      color: #c1c5d6;
    }

    .interviewDetails,
    .userName {
      width: 173px;
      height: 18px;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 18px;
      color: #242833;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .interviewDetails {
      margin-bottom: 3px;
    }
  }
}
