.Tab {
  flex: 1;
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #c1c5d6;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &.active {
    color: #242833;
  }

  &:hover {
    color: #242833;
  }
}

@media (max-width: 1024px) {
  .Tab {
    flex: unset;
    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}
