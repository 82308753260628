.AddUserForm {
  overflow-x: hidden;
  form {
    display: flex;
    gap: 70px;
    margin-bottom: 27px;
    padding-bottom: 34px;

    .leftCol,
    .rightCol {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 55px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  gap: 10px;
}
