.Contacts {
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: calc(100vh - 80px - 144px);
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00e4ac;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }
}
