.ButtonWithHelperText {
  width: 100%;
  .buttonsContainer {
    width: 100%;
  }

  .helper {
    margin-top: 13px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #c1c5d6;

    a {
      text-decoration: none;
    }

    span {
      color: #00e4ac;
      cursor: pointer;
    }
  }
}
