.ResumeUploadProgress {
  display: flex;
  flex-direction: column;
  align-items: center;

  // Compensate for absent label
  padding-top: 25px;

  .progress {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 80px;
    background-color: rgba(193, 197, 214, 1);
    border-radius: 16px;
    overflow: hidden;

    span {
      position: relative;
      z-index: 1;
      font-family: 'Gilroy-Medium';
      font-size: 24px;
      color: white;
    }

    .progressBar {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #00e4ac;
      // transition: all 0.3s ease-in;
    }
  }
}
