@import '../../../../../mixins';

.tab {
  width: 1067px;
  max-width: 1067px;
  display: flex;

  @include onMobile {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .events {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    gap: 15px;

    @include onMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}
