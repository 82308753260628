.prev,
.next {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 0;
  background-color: #f2f4f8;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: white;
    border: 1px solid #c1c5d6;
  }
}
.prev {
  transform: rotate(180deg);
}
