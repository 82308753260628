.DropdownOptions {
  position: absolute;
  z-index: 10;
  top: 38px;
  left: 40px;
  display: flex;
  flex-direction: column;
  width: 122px;
  height: 86px;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 16px;
  box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;

  li {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 20px;
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    background-color: white;
    line-height: 19px;
    color: #242833;

    &.active,
    &:hover {
      background-color: #f2f4f8;
    }
  }
}
