.dialog {
  max-width: 815px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.SetOnboardingModal {
  border-radius: 20px;

  &.remote {
    .content {
      height: unset;
      min-height: 741px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 741px;
    padding-top: 32px;
    padding-left: 44px;
    padding-right: 44px;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;

      .title {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 15px;
        font-family: 'Gilroy-Bold';
        font-size: 28px;
        line-height: 30px;
        color: #242833;

        .status {
          width: 100px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Gilroy-Regular';
          font-size: 14px;
          line-height: 20px;
          color: white;
          background: #00e4ac;
          border-radius: 16px;
        }

        .statusDeclined {
          background-color: #ff2d55;
        }
      }

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close-dark.svg);
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .remote,
      .inperson {
        flex: 1;
      }

      .row {
        display: flex;
        gap: 50px;
        margin-bottom: 21px;

        .onboardingModeSelector {
          display: flex;
          align-items: center;
          gap: 60px;

          h2 {
            margin: 0;
            font-family: 'Gilroy-Bold';
            font-size: 18px;
            line-height: 24px;
            color: #242833;
          }

          .radios {
            display: flex;
            gap: 40px;
          }
        }

        .col {
          display: flex;
          flex-direction: column;
          flex: 1;

          .linkButton {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 186px;
            height: 50px;
            margin-bottom: 41px;
            margin-left: auto;
            font-family: 'Gilroy-SemiBold';
            font-size: 16px;
            line-height: 24px;
            color: #242833;
            background-color: #f2f4f8;
            border: 1px solid #f2f4f8;
            border-radius: 16px;
          }
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 121px;
      gap: 10px;
    }
  }
}
