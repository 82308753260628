@use '../../../../../../variables' as *;

.Announcement {
  padding-left: 15px;
  padding-right: 15px;
  .container {
    display: flex;
    gap: 20px;
    padding-right: 86px;
    background-color: #e1fff8;
    border-radius: 16px;

    &.newHire {
      background-color: #efddff;

      .content {
        .text {
          font-size: 16px;
          font-family: Gilroy-Medium;
          line-height: 150%;
        }
      }

      .background {
        background-image: url(../../../../../../assets/images/dashboard/discover/newHire.png);
      }
    }

    &.newRegistration {
      // background-color: rgba(194, 185, 245, 0.74);
      background-color: #dadce7;
      .content {
        .text {
          font-size: 16px;
          font-family: Gilroy-Medium;
          line-height: 150%;
        }
      }

      .background {
        background-image: url(../../../../../../assets/images/dashboard/discover/newRegistration.png);
      }
    }

    .content {
      flex: 1;
      padding-top: 34px;
      padding-left: 24px;
      padding-bottom: 34px;

      .bold {
        font-family: Gilroy-Bold;
      }

      .title {
        margin-bottom: 5px;
        font-family: 'Gilroy-Bold';
        font-size: 20px;
        line-height: 150%;
      }

      .text {
        margin-bottom: 33px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
      }

      .isRead {
        display: flex;
        align-items: center;
        gap: 15px;

        p {
          margin: 0;
          font-family: 'Gilroy-Medium';
          font-size: 14px;
          line-height: 150%;
          color: #00e4ac;

          .black {
            color: black;
          }

          .viewCount {
            position: relative;
            display: inline-block;
            cursor: pointer;
          }
        }
      }
    }

    .background {
      width: 180px;
      min-height: 180px;
      background-image: url(../../../../../../assets/images/dashboard/discover/announcement.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: $breakpoint) {
  .Announcement {
    padding-left: 15px;
    padding-right: 15px;

    .container {
      position: relative;
      padding-right: 0;

      &.custom {
        gap: 11px;
        padding-right: 12px;
        .content {
          padding-right: 0;
        }

        .background {
          max-width: 23%;
          min-height: unset;
          aspect-ratio: unset;
        }
      }

      .content {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 18px;

        .title {
          font-size: 16px;
          line-height: 20px;
        }

        .text {
          font-size: 14px;
          line-height: 20px;
        }

        .isRead {
          .circle {
            width: 24px;
            min-width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }

      .recipientContainer {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding-right: 22px;

        .background {
          display: flex;
          align-items: center;
          width: unset;
          min-height: 60px;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
}
