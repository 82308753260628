.ViewData {
  display: flex;
  gap: 70px;

  .col {
    display: flex;
    flex-direction: column;
    gap: 58px;
    flex: 1;

    .switchContainer {
      height: 79px;
    }

    .fullWidth {
      position: relative;
      height: 79px;

      input {
        position: absolute;
        top: 32px;
        width: 862px;
      }
    }
  }
}
