@use '../../../../variables' as *;

.dialog {
  max-width: 1144px;
}

.backdrop {
  background: rgba(37, 40, 51, 0.1);
  backdrop-filter: blur(1.5px);
}

.modalContent {
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 16px;
  border: none;
  height: 873px;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.content {
  position: relative;
  padding: 62px 44px 29px;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 23px;
    display: block;
    width: 23px;
    height: 23px;
    font-size: 0;
    background-image: url('../../../../assets/images/modals/close-v2.svg');
    cursor: pointer;
  }

  .header {
    height: 162px;
    background-color: white;
    border: 2px solid white;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
      0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 16px;

    & > div {
      padding: 0;
      background-color: none;
      box-shadow: none;
      height: 100%;
      width: 100%;
    }
  }

  .candidateInfo {
    margin-top: 24px;
    padding: 18px 32px 28px 32px;
    width: 100%;
    height: 600px;
    background-color: rgb(255, 255, 255);
    border: 2px solid white;
    box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
      0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 16px;
  }

  .links {
    margin-bottom: 0;
    padding: 0;
    display: flex;
    gap: 40px;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #c1c5d6;
    cursor: pointer;

    .counter {
      width: 48px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #c1c5d6;
      border-radius: 16px;
      color: white;
    }

    .counterActive {
      background-color: #00e4ac;
    }

    .noData {
      background: #c1c5d6;
      color: white;
    }
  }

  .linkActive {
    position: relative;
    color: #242833;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -3px;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
    }
  }

  .hidden {
    display: none;
  }

  .open {
    display: block;
  }
}

@media (max-width: $breakpoint) {
  .modalContent {
    height: unset;
  }

  .content {
    padding: 0;
    .closeIcon {
      display: none;
    }

    .header {
      & > div {
        padding: 15px;
      }
    }

    .candidateInfo {
      height: unset;
      flex: 1;
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .links {
      gap: 40px;
      margin-bottom: 22px;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .link {
      padding-bottom: 15px;
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
    }

    .linkActive {
      &::after {
        bottom: 0;
      }
    }
  }
}
