p,
h2 {
  margin: 0;
}

.noManipulations {
  pointer-events: none;
}

.Feedback {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  height: 716px;
  width: 826px;
  padding: 40px;
  font-family: 'Gilroy-SemiBold';
  font-size: 35px;
  line-height: 36px;
  color: #242833;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 60px rgba(24, 39, 75, 0.1);
  transform: translate(-50%, -50%);

  &:not(.interviewOrganizer) {
    .buttons {
      display: none;
    }
  }

  .info {
    margin-top: 30px;
    display: flex;
    gap: 40px;
    justify-content: space-between;

    .container {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .title {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #3c3c43;
  }

  .location,
  .job {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #3c3c43;
  }

  .rating {
    margin-top: 32px;

    input {
      display: none;
    }
  }

  .recommend {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 40px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    display: flex;
    color: #c1c5d6;

    .yes,
    .no {
      position: relative;
      width: 63px;
      height: 25px;
      cursor: pointer;
    }

    .yes {
      background: no-repeat
        url('../../../../../../assets/images/dashboard/candidate/interview/yes.svg');

      &:hover {
        background: no-repeat
          url('../../../../../../assets/images/dashboard/candidate/interview/activeYes.svg');
      }
    }

    .no {
      background: no-repeat
        url('../../../../../../assets/images/dashboard/candidate/interview/no.svg');

      &:hover {
        background: no-repeat
          url('../../../../../../assets/images/dashboard/candidate/interview/activeNo.svg');
      }
    }

    .isRecommend {
      background: no-repeat
        url('../../../../../../assets/images/dashboard/candidate/interview/activeYes.svg');
    }

    .isNoRecommend {
      background: no-repeat
        url('../../../../../../assets/images/dashboard/candidate/interview/activeNo.svg');
    }
  }

  .feedback {
    position: relative;
    margin-top: 30px;
    font-family: 'Gilroy-Regular';
    line-height: 20px;

    .description {
      margin-top: 2px;
      font-size: 14px;
      color: #242833;
    }

    .textarea {
      margin-top: 10px;
      padding: 20px;
      width: 746px;
      height: 105px;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
      outline: none;
      border: 0;
      background: #f2f4f8;
      border-radius: 16px;
      resize: none;
    }

    .validation {
      position: absolute;
      left: 0;
      bottom: 0;
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      color: #ff2d55;
    }

    .counter {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      color: #c1c5d6;
    }
  }

  .buttons {
    margin-top: 25px;
    display: flex;
    gap: 23px;
    justify-content: center;
    align-items: center;

    .button {
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      border: 1px solid #242833;
      border-radius: 16px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &.disabled {
        pointer-events: none;
      }

      &:hover {
        background-color: #c1c5d6;
        border-color: #c1c5d6;
        color: white;
      }

      &:active {
        background-color: black;
      }
    }

    .delete {
      background-color: #c1c5d6;
      border: 0;

      &.disabled {
        color: unset;
        pointer-events: none;
      }
    }

    .disabled {
      color: #c1c5d6;
      border-color: #c1c5d6;
      cursor: default;

      &:hover {
        background-color: transparent;
        border-color: #c1c5d6;
        color: #c1c5d6;
      }
    }

    .submit {
      border-color: #00e4ac;
      background-color: #00e4ac;
      color: white;

      &:hover {
        background-color: white;
        color: #00e4ac;
        border-color: #00e4ac;
      }

      &:active {
        border-color: black;
        color: white;
        background-color: black;
      }
    }
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 32px;
    height: 32px;
    font-size: 0;
    background-image: url('../../../../../../assets/images/dashboard/candidate/grayClose.svg');
    cursor: pointer;
  }
}
