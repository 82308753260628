.AssessmentQuestions {
  display: flex;
  flex-direction: column;
  gap: 59px;
  padding-right: 133px;
  .question {
    .header {
      display: flex;
      gap: 365px;
      margin-bottom: 37px;
      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #242833;
      }
    }

    .answers {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
      padding-left: 34px;

      .answer {
        display: flex;
        align-items: center;
        gap: 25px;
        .label {
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: #242833;

          span {
            color: #c1c5d6;
          }
        }

        & > div {
          flex: 1;
        }
      }
    }
  }
}
