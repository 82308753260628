.ColorInfo {
  display: flex;
  gap: 8px;
  font-family: Gilroy-Regular;
  color: #242833;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .colorModel,
  .color {
    height: 28px;
    padding-left: 6px;
    padding-top: 4px;
    padding-right: 6px;
    padding-bottom: 4px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
  }

  .colorModel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 69px;

    .switch {
      padding: 0;
      background-color: transparent;
      border: none;

      svg {
        path {
          &:first-child {
            fill: #00e4ac;
          }
          &:last-child {
            fill: #9ca3af;
          }
        }
      }

      &.hex {
        svg {
          path {
            &:first-child {
              fill: #9ca3af;
            }
            &:last-child {
              fill: #00e4ac;
            }
          }
        }
      }
    }
  }

  .color {
    flex: 1;
    height: 28px;
    text-transform: uppercase;

    .gray {
      color: #9ca3af;
    }
  }
}
