.Scheme {
  display: flex;
  width: 100%;
  min-height: 54px;
  border-radius: 6px;
  border: 2px solid #f2f4f8;
  overflow: hidden;
  cursor: pointer;

  &.active {
    border: 2px solid transparent;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
  }

  .color {
    flex: 1;
    //  width: 54px;
    width: 40px;
    min-height: 54px;
  }
}
