.SearchInput {
  input {
    padding: 14px 20px;
    width: 100%;
    height: 56px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 36px;
    color: black;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.10);
    border: none;
    outline: none;

  
    &::placeholder {
      color: #C1C5D6;
    }
  }
}
