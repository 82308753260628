@use '../../../variables' as var;

@keyframes arrowRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-80deg);
  }
  50% {
    transform: rotate(-140deg);
  }
  75% {
    transform: rotate(-190deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes arrowRotateReverse {
  0% {
    transform: rotate(-180deg);
  }
  25% {
    transform: rotate(-140deg);
  }
  50% {
    transform: rotate(-80deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.Dropdown {
  // width: 100%;
  position: relative;
  display: inline-flex;

  &.readOnly {
    pointer-events: none;

    .selector {
      .arrow {
        display: none;
      }
    }
  }

  &.open {
    .selector {
      z-index: 1000;
      .arrow {
        background-image: url('../../../../src/assets/images/common/drop-down-arrows/drop-down-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        &:hover {
          background-image: url('../../../../src/assets/images/common/drop-down-arrows/drop-down-arrow-green.svg');
        }
      }
    }
  }

  &:focus {
    outline: none;

    .selector {
      box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.25);
    }
  }

  .selector {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 48px;
    padding-left: 20px;
    padding-right: 40px;
    font-size: 16px;
    line-height: 100%;
    font-family: 'Gilroy-Regular';
    color: #c1c5d6;
    background-color: white;
    border: 1px solid #c1c5d6;
    border-radius: 16px;
    cursor: pointer;
    .arrow {
      transform: rotate(0deg);
      animation: arrowRotateReverse 0.2s ease-in-out;
    }
    &.dropdownActive {
      border-color: var.$main-purple;
      .arrow {
        transform: rotate(180deg);
        animation: arrowRotate 0.2s ease-in-out;
        background-image: url('../../../../src/assets/images/common/drop-down-arrows/drop-down-arrow-green.svg');
      }
    }
    &:hover {
      border-color: var.$main-purple;
      .arrow {
        background-image: url('../../../../src/assets/images/common/drop-down-arrows/drop-down-arrow-green.svg');
      }
    }

    .hasOption {
      color: #242833;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .arrow {
      position: absolute;
      right: 21px;
      width: 16px;
      height: 10px;
      background-image: url('../../../../src/assets/images/common/drop-down-arrows/drop-down-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition: transform 0.2s ease-in-out;
    }
  }

  .options {
    position: absolute;
    z-index: 1002;
    top: calc(100% + 7px);
    left: 0;
    min-width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: white;
    box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
    border-radius: 16px;
    overflow: hidden;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        height: 38px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
        white-space: nowrap;
        cursor: pointer;

        &.withDivider {
          position: relative;
          padding-top: 6px;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            width: calc(100% - 40px);
            height: 1px;
            background-color: #e9eef2;
          }
        }

        &.danger {
          color: #ff2d55;
        }

        &.active {
          font-size: 16px;
          line-height: 24px;
          color: #00e4ac;
        }

        &:hover {
          font-size: 18px;
          line-height: 24px;
          font-family: 'Gilroy-Bold';
          color: #141224;
          background-color: #f2f4f8;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Dropdown {
    .selector {
      font-size: 14px;
    }
  }
}

@keyframes bounceDropdown {
  0% {
    top: calc(0% + 7px);
    opacity: 0;
  }
  25% {
    top: calc(100% + 7px);
    opacity: 0.3;
  }
  50% {
    top: calc(85% + 7px);
    opacity: 0.6;
  }
  75% {
    top: calc(110% + 7px);
    opacity: 0.9;
  }
  100% {
    top: calc(100% + 7px);
    opacity: 1;
  }
}

@keyframes bounceDropdownReverse {
  0% {
    top: calc(100% + 7px);
    opacity: 1;
  }
  25% {
    top: calc(110% + 7px);
    opacity: 0.9;
  }
  50% {
    top: calc(85% + 7px);
    opacity: 0.6;
  }
  75% {
    top: calc(100% + 7px);
    opacity: 0.3;
  }
  100% {
    top: calc(0% + 7px);
    opacity: 0;
  }
}

.animation {
  opacity: 1;
  animation: bounceDropdown 0.7s ease-in-out;
}

.animationReverse {
  opacity: 0;
  animation: bounceDropdownReverse 0.7s ease-in-out;
}
.hiddenDisplay {
  display: none;
}
