.SettingsContainer {
  height: calc(100% - 56px);
  border-radius: 16px;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .SettingsContainer {
    height: unset;
    box-shadow: none;
  }
}
