.PrevOffer {
  display: flex;
  align-items: flex-end;
  gap: 20px;

  &.decline {
    position: relative;
    top: 15px;
    align-items: center;
    justify-content: center;
  }

  .decline {
    width: 287px;
    font-family: Gilroy-Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #ff2d55;
  }

  .dropdownTitle {
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #242833;
    white-space: nowrap;

    .note {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      color: #c1c5d6;
    }

    &.short {
      width: 20px;
    }
  }
}
