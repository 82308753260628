.EditContent {
  .row {
    display: flex;
    gap: 70px;

    .col {
      flex: 1;
    }
  }

  .mediaContainer {
    h1 {
      margin-bottom: 5px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }

    .media {
      width: 225px;
      height: 220px;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}
