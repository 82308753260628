@keyframes topMovement {
  0% {
    top: 15px;
  }
  25% {
    top: 7px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: -5px;
  }
  100% {
    top: 0px;
  }
}

.GoTopButton {
  position: fixed;
  z-index: 1000;
  bottom: 30px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: none;
    box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
    overflow: hidden;
    &:hover {
      .arrow {
        animation: topMovement 0.5s ease-in-out;
      }
    }

    .arrowWrapper {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-image: url('../../../../../assets/images/dashboard/discover/goTopButton.svg');
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      position: relative;
      .arrowBackground {
        height: 40px;
        width: 40px;
        background-image: url('../../../../../assets/images/dashboard/discover/scroll-up/scroll-up-background.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        left: -6px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        overflow: hidden;
      }
      .arrowOpacity {
        opacity: 1;
      }
      .arrow {
        height: 40px;
        width: 40px;
        background-image: url('../../../../../assets/images/dashboard/discover/scroll-up/scroll-up-light-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: -6px;
        top: 0px;
        transition: top ease-in-out 0.5s;
      }
      .arrow15 {
        top: 15px;
      }
      .arrow0 {
        top: 0px;
      }
    }
  }
}
