.dotMenu {
  display: flex;
  justify-content: center;
  line-height: 2;
  position: relative;
  width: 40px;
  height: 40px;
  background: #f2f4f8;
  border-radius: 25px;
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background: #c1c5d6;
  }

  .selector {
    display: flex;
    width: 40px;
    justify-content: center;
    font-weight: bold;
  }

  .options {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 5px);
    right: 0;
    width: 180px;
    height: max-content;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        height: 38px;
        padding-left: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
        cursor: pointer;

        &:hover {
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #141224;
          background-color: #f2f4f8;
        }

        &:first-child:hover {
          border-radius: 16px 16px 0 0;
        }

        &:last-child:hover {
          border-radius: 0 0 16px 16px;
        }
      }

      .disabled {
        cursor: default;
        color: #c1c5d6;

        &:hover {
          font-size: 16px;
          font-family: 'Gilroy-Regular';
          font-weight: 400;
          color: #c1c5d6;
          background-color: white;
        }
      }
    }
  }
}

.active {
  background-color: #c1c5d6;
}

.transparent {
  background-color: white;

  &:hover {
    background-color: white;
  }
}

.hide {
  display: none;
}
