.ColorTile {
  width: 45px;
  height: 45px;
  cursor: pointer;

  .inner {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  &.active {
    width: 55px;
    height: 55px;
    margin-right: -5px;
    margin-left: -5px;

    .outer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      border: 1.5px solid transparent;
      background: linear-gradient(
          223deg,
          #00e4ac 20.85%,
          #10c5e9 65.29%,
          #9240dc 100%
        )
        border-box;
      -webkit-mask: linear-gradient(white 0 0) padding-box,
        linear-gradient(white 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      border-radius: 12px;
    }

    .inner {
      top: 5px;
    }
  }

  .inner {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 122;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border: 0.5px solid #e9e9e9;
    border-radius: 12px;
  }
}
