.InterviewRating {
  display: flex;
  align-items: center;
  gap: 5px;

  &.red {
    span {
      color: #ff2d55;
    }
  }

  &.orange {
    span {
      color: #ffbf00;
    }
  }

  &.green {
    span {
      color: #00e4ac;
    }
  }

  &.gradient {
    span {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .ratingIcon {
    width: 24px;
    height: 24px;
  }

  span {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;
  }
}
