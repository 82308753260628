.ManagementMenu {
  padding-bottom: 15px;
  border-bottom: 2px solid #c1c5d6;
  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #c1c5d6;
      white-space: nowrap;
      cursor: pointer;

      &.active {
        position: relative;
        color: #242833;

        &::after {
          content: '';
          position: absolute;
          bottom: -18px;
          display: block;
          width: 100%;
          height: 4px;
          background-color: #00e4ac;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .ManagementMenu {
    width: 1500px;
    ul {
      gap: 139px;

      li {
        &.active {
          &::after {
          }
        }
      }
    }
  }
}
