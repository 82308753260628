.ContentManagement {
  .container {
    background-color: white;
    border-radius: 0px 16px 16px 16px;
    box-shadow: 0px 4px 60px rgba(24, 39, 75, 0.1);
  }
}

@media (max-width: 1024px) {
  .ContentManagement {
    width: 1500px;
  }
}
