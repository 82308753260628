.TextInput {
  position: relative;
  display: flex;
  flex-direction: column;

  .iconContainer {
    position: relative;
    .icon {
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      width: 100%;
      padding-left: 49px;
    }
  }

  .icon {
    position: absolute;
    bottom: 0;
  }

  &.staticErrorPositon {
    .status {
      position: static;
      margin-top: 6px;
    }
  }

  &.centerText {
    input {
      text-align: center;
    }
  }

  &.error {
    /* input {
      border: 1.5px solid #ff2d55;
    } */

    .status {
      color: #ff2d55;
    }
  }

  &.warning {
    input {
      border: 1.5px solid #ffbf00;
    }

    .status {
      color: #ffbf00;
    }
  }

  label {
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 20px;
    color: #242833;

    .maxLength {
      font-family: 'Gilroy-Regular';
      color: #c1c5d5;
    }

    .infoIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }

    .note {
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 20px;
      color: #c1c5d6;
      white-space: nowrap;
    }
  }

  input {
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 16px;
    border: none;

    &::placeholder {
      color: #c1c5d6;
    }

    &:focus {
      outline: none;
    }

    &:focus,
    &:hover {
      &:not(:read-only) {
        background-color: white;
        box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.12);
        border-radius: 16px;
      }
    }
  }

  .status {
    position: absolute;
    bottom: -24px;
    // margin-top: 6px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 18px;
  }
}
