@use '../../../../../../variables' as *;

.Tags {
  display: flex;
  gap: 5px;
  padding-top: 25px;
  padding-left: 97px;
  padding-right: 24px;

  h3 {
    margin: 0;
    font-family: 'Gilroy-Regular';
    font-size: 18px;
    line-height: 24px;
    color: #242833;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-family: 'Gilroy-Regular';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }
  }
}

@media (max-width: $breakpoint) {
  .Tags {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;

    h3 {
      font-size: 16px;
    }

    ul {
      li {
        font-size: 16px;
      }
    }
  }
}
