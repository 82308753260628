.Reports {
  display: flex;
  background-color: #f2f4f8;
}

@media (max-width: 1024px) {
  .Reports {
    max-width: calc(100vw - 15px);
    min-height: calc(100dvh - 54px);
    position: relative;
    padding-bottom: 80px;
    overflow-x: auto;
    background-color: #f2f4f8;
  }
}
