.circle {
  width: 40px;
  height: 40px;
  border: 1px solid #c2c5d5;
  border-radius: 50%;

  &.read {
    background-color: #00e4ac;
    border: none;

    .background {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: 20px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='100' height='100' viewBox='0 0 26 26'%0Astyle='fill:%23FFFFFF;'%3E%3Cpath d='M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  &.animate {
    background-color: transparent;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;

    .background {
      animation: bounce 0.5s forwards;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='100' height='100' viewBox='0 0 26 26'%0Astyle='fill:%23FFFFFF;'%3E%3Cpath d='M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  .background {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0);
    border: 1px solid #c2c5d5;
  }
  20% {
    background-color: #00e4ac;
    border: 1px solid #c2c5d5;
  }
  40% {
    transform: scale(1.35);
    background-color: #00e4ac;
    border: none;
  }

  60% {
    transform: scale(1.1);
    background-color: #00e4ac;
    border: none;
  }
  80% {
    transform: scale(1.21);
    background-color: #00e4ac;
    border: none;
  }

  90% {
    transform: scale(1.1);
    background-color: #00e4ac;
    border: none;
  }
  100% {
    transform: scale(1);
    background-color: #00e4ac;
    border: none;
  }
}
