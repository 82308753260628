.AuthHeading {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0;
  font-family: Gilroy-SemiBold;
  font-size: 32px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  span {
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
