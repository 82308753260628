.LeftPanel {
  width: calc(50% - 16px);
  height: 100%;
}

@media (max-width: 1024px) {
  .LeftPanel {
    width: unset;
    height: unset;
  }
}
