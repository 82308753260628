.Tooltip {
  position: relative;

  .tip {
    position: absolute;
    z-index: 1001;
    top: calc(100% + 12px);
    left: 50%;
    display: inline-flex;
    align-items: center;
    min-height: 27px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 13px;
    text-align: center;
    font-family: 'Gilroy-Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    color: white;
    background-color: #242833;
    box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &::after {
      content: '';
      position: absolute;
      top: -8.5px;
      left: 0;
      width: 23px;
      height: 17px;
      background-image: url(../../assets/images/tooltip/triangle.png);
      background-repeat: no-repeat;
    }

    &.animationDisabled {
      visibility: hidden;
    }

    &.visible {
      opacity: 1;
      //Animation
      transform: scale(1);
      transform-origin: left;

      &:not(.animationDisabled) {
        animation: bounceIn 0.6s ease-in-out forwards;
      }
    }

    &.top {
      top: unset;
      bottom: calc(100% + 12px);

      &::after {
        top: unset;
        bottom: -8px;
        transform: scaleY(-1);
      }
    }

    &.top {
      &.left {
        &::after {
          transform: scaleY(-1) scaleX(-1);
        }
      }
    }

    &.left {
      transform: translateX(-100%);
      &.visible {
        &:not(.animationDisabled) {
          animation: bounceInLeft 0.6s ease-in-out forwards;
          transform-origin: top right;
        }
      }

      &::after {
        left: unset;
        right: 0;
        transform: scaleX(-1);
      }
    }
  }
}

@keyframes bounceInLeft {
  0% {
    transform: translateX(-100%) scale(0);
  }
  20% {
    transform: translateX(-100%) scale(1.2); /* Initial bounce to a larger size */
  }
  40% {
    transform: translateX(-100%) scale(0.9); /* Recoil to a smaller size */
  }
  60% {
    transform: translateX(-100%) scale(1.05); /* Second smaller bounce */
  }
  80% {
    transform: translateX(-100%) scale(0.95); /* Second smaller recoil */
  }
  100% {
    transform: translateX(-100%) scale(1); /* Final size */
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.2); /* Initial bounce to a larger size */
  }
  40% {
    transform: scale(0.9); /* Recoil to a smaller size */
  }
  60% {
    transform: scale(1.05); /* Second smaller bounce */
  }
  80% {
    transform: scale(0.95); /* Second smaller recoil */
  }
  100% {
    transform: scale(1); /* Final size */
  }
}

/* @keyframes bounceIn {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.3);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
 */
