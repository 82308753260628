.ChangeRequestHeader {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .row {
    display: flex;
    align-items: center;
    gap: 15px;

    .title {
      font-family: Gilroy-Bold;
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
    }

    .changeRequest {
      height: 20px;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #ffbf00;
      font-family: Gilroy-Bold;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      color: white;
      border-radius: 16px;
    }

    .date {
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #ffbf00;
    }
  }
}
