.NumberRange {
  position: relative;
  display: flex;
  align-items: center;
  gap: 23px;

  .error {
    position: absolute;
    bottom: -24px;
    margin-top: 6px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 18px;
    color: #ff2d55;

    &.warning {
      color: #ffbf00;
    }
  }

  .info {
    position: absolute;
    top: 0;
    left: 117px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }

    span {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #c1c5d6;
    }
  }

  .toContainer {
    width: 100%;
    padding-top: 29px;
  }

  .fromContainer {
    width: 100%;
  }

  .split {
    padding-top: 29px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 100%;
    color: #242833;
  }
}
