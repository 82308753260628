@use '../../../../../variables' as *;

.Post {
  padding-bottom: 15px;
  background-color: white;
  box-shadow: -10px -4px 60px rgba(78, 79, 114, 0.1);
  border-radius: 16px;

  &.collapsed {
    display: flex;
    padding-bottom: 0;
  }

  .title {
    margin-bottom: 15px;
    padding-left: 97px;
    padding-right: 141px;
    font-size: 18px;
    font-weight: bold;
  }

  .commentsContainer {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: $breakpoint) {
  .Post {
    border-radius: 8px;
  }
}
