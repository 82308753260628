.NoContacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;

  .message {
    width: 276px;
    margin: 0;
    font-family: 'Gilroy-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #c1c5d6;
  }

  img {
    margin-top: 41px;
  }
}
