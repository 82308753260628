.SubmitToJob {
  display: flex;
  gap: 15px;
  padding-top: 15px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 15px;
  background-color: #f2f4f8;
  border-radius: 16px;
}

@media (max-width: 1024px) {
  .SubmitToJob {
    flex-direction: column;
    padding: 15px;
  }
}
