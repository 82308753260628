.TwoWeeksCalendar {
  h2 {
    margin-bottom: 10px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 21px;
    color: #242833;
    opacity: 0.7;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    .todayButton {
      margin: 0;
      padding: 0;
      background-color: transparent;
      font-family: 'Gilroy-Bold';
      font-size: 28px;
      line-height: 35px;
      color: #242833;
      border: none;
    }

    .monthAndButtonsContainer {
      display: flex;
      align-items: center;
      gap: 15px;

      .month {
        font-family: Gilroy-Bold;
        color: #242833;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .buttons {
        display: flex;
        gap: 10px;

        .button {
          display: block;
          width: 20px;
          height: 20px;
          font-size: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3590_62441)'%3E%3Cpath d='M4.4442 0.5L15.5553 0.5C18.0109 0.5 19.9998 2.48889 19.9998 4.94444L19.9998 16.0556C19.9998 18.5111 18.0109 20.5 15.5553 20.5L4.4442 20.5C1.98864 20.5 -0.000247955 18.5111 -0.000247955 16.0556L-0.000247955 4.94444C-0.000247955 2.48889 1.98864 0.5 4.4442 0.5Z' fill='%23C1C5D6'/%3E%3Crect x='13.3333' y='10.4453' width='1.11111' height='6.66667' rx='0.555556' transform='rotate(126.602 13.3333 10.4453)' fill='white'/%3E%3Crect x='8.10163' y='14.9453' width='1.11111' height='6.66667' rx='0.555556' transform='rotate(-128.302 8.10163 14.9453)' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3590_62441'%3E%3Crect width='20' height='20' fill='white' transform='matrix(-1 0 0 -1 20 20.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
          background-color: #c1c5d6;
          border-radius: 6px;
          cursor: pointer;

          &.back {
            transform: scale(-1, 1);
          }
        }
      }
    }
  }

  .calendar {
    .days {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      justify-content: space-between;
      margin-bottom: 16px;

      .day {
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #242833;
      }
    }

    .dates {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 16px;

      .date {
        position: relative;
        display: flex;
        justify-content: center;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #242833;
        user-select: none;

        &.differentMonth {
          opacity: 0.3;
        }

        &.event {
          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            right: 6px;
            display: block;
            width: 5px;
            height: 5px;
            background: linear-gradient(
              228.41deg,
              #00e4ac 0%,
              #10c5e9 53.88%,
              #9240dc 106.71%
            );
            border-radius: 50%;
          }
        }

        .today {
          position: absolute;
          z-index: 1;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 44px;
          background-color: #6722d2;
          border: 2px solid #f2f4f8;
          border-radius: 6px;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .TwoWeeksCalendar {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    h2 {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 17px;
    }

    .header {
      margin-bottom: 15px;
      .todayButton {
        font-size: 16px;
        line-height: 20px;
      }

      .monthAndButtonsContainer {
        .month {
          font-size: 16px;
          line-height: 20px;
        }

        .buttons {
          .button {
            &.back {
            }
          }
        }
      }
    }

    .calendar {
      .days {
        .day {
        }
      }

      .dates {
        .date {
          &.differentMonth {
          }

          &.event {
            &::after {
            }
          }

          .today {
          }
        }
      }
    }
  }
}
