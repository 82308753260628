.NoFavorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 480px;
  padding-top: 36px;

  .text {
    margin: 0;
    margin-bottom: 24px;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 22px;
    color: #c1c5d6;

    .star {
      background: -webkit-linear-gradient(
        268.41deg,
        #00e4ac 0%,
        #10c5e9 27.88%,
        #9240dc 106.71%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .icons {
    width: 233px;
    height: 284px;
    background: url('../../../../assets/images/dashboard/favorites/favorites.svg');
  }
}

@media (max-width: 1024px) {
  .NoFavorites {
    height: calc(100dvh - 121px - 48px - 165px - 25px);
    padding-top: 47px;
  }
}
