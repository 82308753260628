.candidateCard {
  position: relative;
  max-width: calc(100% - 70px);
  display: flex;
  align-items: center;
  cursor: pointer;;

  .noPhoto {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    color: white;
    border-radius: 8px;
    background: linear-gradient(228.41deg, #00E4AC 0%, #10C5E9 53.88%, #9240DC 106.71%);
  }

  .photo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
  }

  .info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    .location {
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      color: #C1C5D6;
    }
  }

  .settings {
    position: absolute;
    top: 0;
    right: -70px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
}