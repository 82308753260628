/* @keyframes shake {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(-10deg);
  }
} */

/* @keyframes shakeReverse {
  0% {
    transform: rotate(-10deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
 */
.Button {
  height: 56px;
  padding-left: 5em;
  padding-right: 5em;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: #242833;
  background-color: #00e4ac;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  position: relative;

  &.animate {
    position: relative;

    &.clicked {
      .mouveOverButton {
        animation: none;
      }
      .mouveLeaveButton {
        animation: none;
      }

      &:not(:disabled) {
        &::after {
          transform: scale(1);
        }

        .wrapper {
          position: relative;
          z-index: 1;

          img {
            filter: brightness(10000%);
          }
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #00e4ac;
      border-radius: 16px;
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  .mouveOverButton {
    animation: shake 0.7s ease-in-out forwards;
  }
  .mouveLeaveButton {
    animation: reverseShake 0.7s ease-in-out forwards;
  }
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:disabled {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #c1c5d6;
    background-color: transparent;
    border: 1px solid #c1c5d6;
    cursor: auto;
  }

  &.setWidth {
    padding-left: unset;
    padding-right: unset;
  }

  &.modal {
    border: 1.5px solid white;
    color: white;
    background-color: transparent;

    &:hover {
      color: black;
      border: none;
    }
  }

  &.print {
    background-color: #f2f4f8;
    color: #c1c5d6;
  }

  &.gradient {
    &:not(:disabled) {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      background-blend-mode: multiply;
      color: white;
    }

    &:hover {
      border: none !important;
      color: white !important;
    }
  }

  &.black {
    background-color: #242833;
    color: white;

    &:hover {
      &:not(:disabled) {
        color: white;
        background-color: #c1c5d6;
        border: 1px solid #c1c5d6;
      }
    }
  }

  &.secondary {
    color: #c1c5d6;
    background-color: #f2f4f8;
    border: none;

    &:disabled {
      color: #c1c5d6;
      border: 1px solid #c1c5d6;
      cursor: auto;
    }

    &:hover {
      &:not(:disabled) {
        color: white;
        background-color: #c1c5d6;
        border: 1px solid #c1c5d6;
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: black;
        color: white;
      }
    }
  }

  &.delete {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #242833;
    background-color: #c1c5d6;
    border: none;

    &:hover {
      background-color: #c1c5d6 !important;
      border: none !important;
      &:not(:disabled) {
        color: white;
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: black !important;
        color: white !important;
      }
    }
  }

  &.report {
    background-color: white;

    &:disabled {
      background-color: rgba(242, 244, 248, 1);
      border: 1px solid #c1c5d6;
      cursor: auto;

      &:hover {
        background-color: #c1c5d6;
      }

      img {
        filter: brightness(0%);
      }
    }

    &:hover {
      &:not(:disabled) {
        background-color: rgba(242, 244, 248, 1);
        border: 1px solid #c1c5d6;

        img {
          filter: brightness(0%);
        }
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: #c1c5d6;
      }
    }
  }

  &:hover {
    &:not(:disabled) {
      color: #00e4ac;
      background-color: white;
      border: 1.5px solid #00e4ac;
    }
  }

  &:active {
    &:not(:disabled) {
      background-color: black;
      color: #ffffff;
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(100deg);
  }
  30% {
    transform: rotate(-20deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes reverseShake {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(-100deg);
  }
  30% {
    transform: rotate(20deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(2deg);
  }
  90% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0);
  }
}
