.AssessmentStatus {
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 24px;

  img {
    width: 24px;
    height: 24px;
  }

  .container {
    &.expired {
      color: #c1c5d6;
    }

    &.pending {
      .leftChar {
        color: #c1c5d6;
      }
    }

    &.complete {
      .leftChar {
        color: #00e4ac;
      }
    }

    &.fail {
      .leftChar {
        color: #ff2d55;
      }
    }

    &.black {
      .leftChar {
        color: #242833;
      }
    }

    &.allCompleted {
      .leftChar,
      .rightChar {
        color: #00e4ac;
      }
    }
  }
}
