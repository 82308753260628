.candidates {
  position: relative;
  z-index: 222;
  padding-top: 20px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  overflow-y: auto;
  height: 480px;
  padding-bottom: 25px;
  padding-right: 15px;

  &.scroll {
    margin-right: 4px;
    padding-right: 6px;
  }

  &.noFavorites {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .candidates {
    height: calc(100dvh - 121px - 48px - 165px - 25px);
  }
}
