.dialog {
  max-width: 335px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
}

.Modal {
  border-radius: 20px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 346px;
    padding-bottom: 20px;
    background-color: #242833;
    border-radius: 20px;

    header {
      width: 100%;
      margin-bottom: 11px;
      padding-top: 16px;
      padding-right: 16px;
      padding-left: 16px;

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        margin-left: auto;
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' width='32' height='32' rx='16' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.9144 15.0252L19.9196 11.02C20.2125 10.7271 20.6874 10.7271 20.9803 11.02C21.2732 11.3129 21.2732 11.7877 20.9803 12.0806L16.9751 16.0858L20.9803 20.091C21.2732 20.3839 21.2732 20.8588 20.9803 21.1517C20.6874 21.4446 20.2125 21.4446 19.9196 21.1517L15.9144 17.1465L11.9092 21.1517C11.6163 21.4446 11.1415 21.4446 10.8486 21.1517C10.5557 20.8588 10.5557 20.3839 10.8486 20.091L14.8538 16.0858L10.8486 12.0806C10.5557 11.7877 10.5557 11.3129 10.8486 11.02C11.1415 10.7271 11.6163 10.7271 11.9092 11.02L15.9144 15.0252Z' fill='white'/%3E%3C/svg%3E%0A");
        cursor: pointer;
      }
    }

    .icon {
      margin-bottom: 18px;
    }

    h1 {
      margin: 0;
      margin-bottom: 25px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: white;
    }

    p {
      width: 271px;
      margin: 0;
      margin-bottom: 30px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: white;
      opacity: 0.7;
    }

    .buttons {
      display: flex;
      gap: 16px;
      margin-top: 30px;
    }
  }
}
