.SearchInput {
  position: relative;
  
  input {
    padding: 22px 22px 22px 46px;
    width: 100%;
    height: 56px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 36px;
    background: #f2f4f8;
    border-radius: 16px;
    border: none;
    outline: none;
    
    &::placeholder {
      color: black;
    }
  }


  &::before {
    content: '';
    position: absolute;
    top: 20px;
    width: 16px;
    height: 16px;
    left: 22px;
    background: url('../../../../../../assets/images/dashboard/reports/search.svg');
  }
}
