.GraphHoverInfo {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 115px;
  height: 60px;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);

  &::after {
    content: '';
    position: absolute;
    top: -17px;
    display: block;
    width: 50;
    height: 24px;
    border-style: solid;
    border-width: 0 25px 24px 25px;
    border-color: transparent transparent #ffffff transparent;
  }

  .title {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #242833;
  }

  .value {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #242833;
  }
}
