.note {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1034px;
  min-height: 180px;
  margin-left: 30px;
  padding: 15px;
  box-shadow: 0px -4px 60px 0px rgba(78, 79, 114, 0.1);
  border-radius: 16px;

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  &.editing {
    .info {
      .date {
        margin-left: auto;
      }
    }

    .buttons {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }

  .dotMenu {
    position: absolute;
    top: 0;
    right: 0;
  }

  .text {
    width: 100%;
    margin-bottom: 9px;
    padding: 13px 0;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    border-radius: 16px;
    outline: none;
    opacity: 0.8;
    overflow: hidden;
    resize: none;

    &::placeholder {
      font-size: 14px;
      color: #c1c5d6;
    }

    &:disabled {
      background-color: transparent;
      border: 0;
      cursor: default;
    }
  }

  .isEditingText,
  .isAddingText {
    height: 85px;
    padding: 13px;
    background: #f2f4f8;
    border: 0;
    opacity: 1;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 358px;
    padding-bottom: 12px;

    .author {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 20px;
    }

    .date {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      opacity: 0.6;
    }
  }

  .buttons {
    margin-left: -280px;
    display: flex;
    gap: 23px;

    .cancel,
    .save {
      width: 150px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      border-radius: 16px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #c1c5d6;
        border-color: #c1c5d6;
        color: white;
      }

      &:active {
        background-color: black;
      }
    }

    .cancel {
      background-color: #f2f4f8;
      color: #c1c5d6;
    }

    .save {
      background-color: #242833;
      color: white !important;
    }

    .disabled {
      color: #c1c5d6;
      border-color: #c1c5d6;
      cursor: default;
      pointer-events: none;
    }
  }

  .isAdding {
    margin: 0;
    justify-content: flex-end;
  }

  .hide {
    display: none;
  }
}

@media (max-width: 1024px) {
  .note {
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;

    &:first-child {
      margin-bottom: 0;
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.editing {
      .text {
        overflow-y: auto;
      }

      .info {
        padding-bottom: 0;
        .date {
          display: none;
        }

        .author {
          display: none;
        }
      }

      .buttons {
        position: static;
        // justify-content: space-between;
        gap: 15px;
        width: 100%;
        margin-left: 0;

        button {
          width: 50%;
        }
      }
    }

    .dotMenu {
    }

    .text {
      min-height: 89px;

      &::placeholder {
      }

      &:disabled {
      }
    }

    .isEditingText,
    .isAddingText {
    }

    .info {
      padding-bottom: 7px;
      padding-right: 0;
      .author {
      }

      .date {
      }
    }

    .buttons {
      .cancel,
      .save {
        &:hover {
        }

        &:active {
        }
      }

      .cancel {
      }

      .save {
      }

      .disabled {
      }
    }

    .isAdding {
    }

    .hide {
    }
  }
}
