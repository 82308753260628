.dialog {
  max-width: 750px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.ShareJobModal {
  border-radius: 20px;

  .content {
    padding: 15px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .buttonsContainer {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 20px;
        line-height: 20px;
        color: #242833;
      }

      .closeIcon {
        display: block;
        width: 20px;
        height: 20px;
        margin-left: 26px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close-black.svg);
        background-size: contain;
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      gap: 15px;
      margin-bottom: 24px;

      .col {
        display: flex;
        flex-direction: column;
        flex: 1;

        .share {
          width: 352px;
          .selectContainer {
            margin-bottom: 26px;
          }
        }
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

@media (max-width: 1024px) {
  .dialog {
    max-width: 750px;
  }

  .backdrop {
    background-color: transparent;
  }

  .ShareJobModal {
    .content {
      .buttonsContainer {
        display: flex;
        gap: 14px;
        margin-bottom: 20px;
      }

      header {
        h1 {
          margin: 0;
        }
      }

      .container {
        flex-direction: column;
        .col {
          .share {
            width: 100%;
          }
        }
      }

      footer {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }
  }
}
