.Addressee {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 36px;
  padding-left: 11px;
  padding-right: 11px;
  background-color: #e7fafc;
  border-radius: 7px;

  span {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 16px;
    color: #00e4ac;
  }

  .removeIcon {
    display: block;
    width: 18px;
    height: 18px;
    font-size: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z' stroke='%2300E4AC' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.87744 11.12L11.1224 6.875' stroke='%2300E4AC' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.1224 11.12L6.87744 6.875' stroke='%2300E4AC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
    cursor: pointer;
  }
}
