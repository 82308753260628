.ChipsReadonly {
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    .label {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }
  }

  .field {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    width: 100%;
    min-height: 192px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: #f2f4f8;
    border-radius: 16px;

    .chip {
      position: relative;
      display: flex;
      align-items: center;
      height: 44px;
      padding-left: 29px;
      padding-right: 29px;
      background-color: #c1c5d6;
      border-radius: 10px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: white;
      white-space: nowrap;
      user-select: none;
    }
  }
}
