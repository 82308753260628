@keyframes animationScale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.1);
  }
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 302px;
  &:hover {
    .username {
      animation: animationScale 0.3s ease-in-out;
      transform: scale(1.1);
    }
  }

  .gradientBorder {
    position: absolute;
    top: 0;
    z-index: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      ),
      linear-gradient(0deg, white, white);
    border-radius: 25px;
  }
}

.CandidateCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 296px;
  padding-right: 17px;
  padding-left: 17px;
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
  margin: 2px;

  &.dragged {
    width: 298px;
    min-height: 221px;
    padding-right: 15px;
    padding-left: 15px;

    .candidateInfo {
      position: relative;
      top: -2px;
    }

    .favoritesContainer {
      top: 11px;
      left: 15px;
    }

    .footer {
      padding-bottom: 8px;
    }
  }

  .favoritesContainer {
    position: absolute;
    top: 13px;
    left: 17px;
  }

  .candidateMenuContainer {
    position: absolute;
    top: 13px;
    right: 17px;
  }

  .candidateInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex: 1;
    padding-top: 12px;

    .coverImage {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
    }

    .username {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: white;
      transition: all 0,3s ease-in-out;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 50%;
    }

    h3 {
      width: 210px;
      margin: 0;
      height: 24px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #3c3c43;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .hiringDetails {
      width: 100%;
      margin-top: auto;
      margin-bottom: 13px;
    }

    .status {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #c1c5d6;

      .scheduledDate {
        white-space: nowrap;

        .date {
          color: #242833;
          text-decoration: underline;
        }

        .viewIcon {
          position: relative;
          top: -2px;
          margin-left: 6px;
        }
      }

      &.red {
        color: #ff2d55;
      }

      &.green {
        color: #00e4ac;
      }

      &.orange {
        color: #ffbf00;
      }

      &.black {
        color: #242833;
      }

      &.grey {
        color: #c1c5d6;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 10px;
    border-top: 1px solid #c1c5d6;

    .timeAgo {
      display: flex;
      align-items: center;
      gap: 8px;

      .createdAt {
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 24px;
        color: #c1c5d6;

        &.new {
          color: #242833;
        }
      }
    }
  }

  &.joined {
    background-color: #242833;

    .candidateInfo {
      h3 {
        color: white;
      }
    }

    .status {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

@media (max-width: 1024px) {
  .container {
    width: 264px;
  }

  .CandidateCard {
    width: 264px;
  }
}
