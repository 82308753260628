.DashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;

  .dropdowns {
    display: flex;
    justify-content: flex-end;
    gap: 7px;
    flex: 1;
    padding-left: 80px;

    .dropdownContainer {
      max-width: 175px;
      flex: 1;
    }
  }
}

@media (max-width: 1024px) {
  .DashboardHeader {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    .dropdowns {
      gap: 15px;
      padding-left: 0;
      flex-wrap: wrap;
      justify-content: unset;

      .dropdownContainer {
        width: 143px;
      }
    }
  }
}
