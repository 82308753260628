.PagePreview {
  position: relative;
  flex: 1;
  width: 100%;
  min-width: 222px;
  // max-width: 222px;
  height: 434px;
  max-height: 434px;
  flex: 1;
  border-radius: 6px;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
  overflow: hidden;
  // border: 1px solid red;

  &.active {
    border: 2px solid transparent;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
  }

  iframe {
    width: 1920px;
    // height: 5200px;
    height: var(--height);
    border-radius: 6px;
    overflow: hidden;
    // scale: 0.115625;
    scale: var(--scale);
    transform-origin: 0 0;
  }
}
