.SelectedUsers {
  position: relative;
  display: flex;
  column-gap: 17px;
  row-gap: 10px;
  flex-wrap: wrap;
  width: 375px;

  &.readOnly {
    pointer-events: none;
  }

  .currentUser {
    position: absolute;
    left: 8px;
    bottom: -30px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #242833;
  }
}
