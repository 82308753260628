.Stat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 112px;
  padding-top: 7px;
  padding-bottom: 9px;
  background-color: white;
  border: 0.5px solid white;
  border-radius: 16px;
  filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 7px 18px rgba(41, 41, 41, 0.14902);
    .value {
      transform: scale(1.1);
    }
  }

  .value {
    font-family: 'Gilroy-Bold';
    font-size: 40px;
    text-align: center;
    color: #c1c5d6;
    transition: all 0.3s ease-in-out;

    &.hasData {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
    }
  }

  .label {
    width: 102px;
    height: 36px;
    font-family: 'Gilroy-Bold';
    font-size: 12px;
    text-align: center;
    color: #242833;

    .note {
      color: #c1c5d6;
    }
  }
}

@media (max-width: 1024px) {
  .Stat {
    min-width: 120px;
    filter: none;
  }
}
