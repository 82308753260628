.TimeRange {
  position: relative;

  &.readOnly {
    pointer-events: none;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;

    h2 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: #242833;
    }

    .duration {
      position: absolute;
      display: flex;
      justify-content: center;
      gap: 3px;
      left: 130px;
      align-items: center;
      width: 93px;
      height: 20px;
      font-family: Gilroy-Regular;
      font-size: 12px;
      font-style: normal;
      line-height: 20px;
      color: white;
      background-color: #c1c5d6;
      border-radius: 16px;

      .bold {
        font-family: Gilroy-Bold;
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
        color: white;
      }

      &.gradient {
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }

      &.green {
        background-color: #00e4ac;
      }

      &.orange {
        background-color: #ffbf00;
      }

      &.red {
        background-color: #ff2d55;
      }
    }
  }

  .dropdowns {
    display: flex;
    gap: 12px;
  }

  .error {
    margin-top: 6px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 18px;
    color: #ff2d55;
  }
}
