.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);

  .container {
    .illustration {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 470px;
      height: 361px;
      margin-bottom: 49px;
      padding: 22px;
      padding-top: 18px;
      background-color: white;
      border-radius: 12.358px 12.358px 12.36px 12.358px;
      box-shadow: 0px 9.886567115783691px 37.07462692260742px 0px
        rgba(78, 79, 114, 0.08);

      .header {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 33px;
        padding-left: 12px;
        background-color: #f2f4f8;
        border-radius: 12.358px 12.358px 12.36px 12.358px;

        .circle {
          width: 10px;
          height: 10px;
          background-image: linear-gradient(
            228deg,
            #00e4ac 0%,
            #10c5e9 50.5%,
            #9240dc 100%
          );
          border-radius: 50%;
        }
      }

      .body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        border-radius: 12.358px 12.358px 12.36px 12.358px;
        background-color: #f2f4f8;

        img {
          width: 107px;
          height: 107px;
        }
      }
    }

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        margin-bottom: 49px;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        color: #c1c5d6;
        text-align: center;
      }

      button {
        width: 180px;
        height: 40px;
        padding: 8px 16px;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #242833;
        text-align: center;
        border-radius: 16px;
        border: 1px solid #242833;
        background-color: white;
        box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
      }
    }
  }
}

@media (max-width: 1024px) {
  .NotFound {
    align-items: flex-start;
    height: calc(100dvh - 80px);

    .container {
      width: 100%;
      padding-top: 64px;
      padding-left: 30px;
      padding-right: 30px;
      .illustration {
        width: 100%;
      }
    }
  }
}
