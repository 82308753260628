.ProductAnnouncements {
  height: 258px;
  min-height: 258px;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(78, 79, 114, 0.1);
  border-radius: 16px;

  .header {
    padding: 13px 16px;
    height: 46px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 21px;
    color: white;
    background: #c1c5d6;
    border-radius: 16px 16px 0px 0px;
  }

  .content {
    position: relative;
    padding: 13px 16px 34px 12px;
    width: 100%;
    height: 212px;

    .noData {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-SemiBold';
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }
  }

  .products {
    display: flex;
    flex-direction: column;
    gap: 22px;

    .seeAll {
      position: absolute;
      right: 12px;
      bottom: 14px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;
      color: #c1c5d6;
      cursor: pointer;
    }
  }
}
