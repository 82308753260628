.Notifications {
  position: fixed;
  z-index: 1002;
  top: 87px;
  right: 47px;
  width: 710px;
  height: 678px;
  max-height: calc(100vh - 87px - 20px);
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .notificationsList {
    height: calc(100% - 140px);
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: auto;
    scrollbar-color: #00e4ac transparent;
    scrollbar-width: thin;
  }
}

.overlay {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}
