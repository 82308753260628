.Role {
  display: flex;
  align-items: center;
  height: 26px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px;
  line-height: 20px;
  color: #6e6e77;
  background-color: #f0f0f7;
  border-radius: 8.5px;
}
