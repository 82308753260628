.Tabs {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 0;

  .tab {
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 24px;
    color: #c1c5d6;
    cursor: pointer;

    &.active {
      position: relative;
      color: #242833;

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        display: block;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }
    }
  }
}
