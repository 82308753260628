.AssessmentDropdown {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 2;
  cursor: default;

  .content {
    position: relative;
    margin-top: 10px;
    padding: 16px 9px 65px;
    width: 645px;
    height: 428px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
    overflow: hidden;

    .assessments {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 18px;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
    }

    .selectAll,
    .clear {
      position: absolute;
      bottom: 24px;
      color: #00e4ac;
      font-family: Gilroy-Regular;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }

    .clear {
      position: absolute;
      right: 22px;
      color: #c1c5d6;
    }
  }
}

@media (max-width: 1024px) {
  .AssessmentDropdown {
    .content {
      width: calc(100vw - 68px);

      .assessments {
        overflow-x: auto;
      }
    }
  }
}
