.ArrowMenu {
  position: relative;

  &.mirrored {
    transform: scaleX(-1);

    .options {
      li {
        transform: scaleX(-1);
      }
    }
  }

  &.bottomArrow {
    .options {
      border-radius: 16px 16px 0 16px;
      &::after {
        top: auto;
        bottom: -16px;
        border-top: 40px solid transparent;
        border-bottom: 40px solid transparent;
        border-left: 40px solid white;
        transform: rotate(-45deg);
        border-radius: 0 0 0 42%;
      }
    }
  }

  .options {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 20px);
    right: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 180px;
    margin: 0;
    padding: 0;
    padding-top: 12px;
    padding-bottom: 15px;
    list-style: none;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px 0 16px 16px;

    &::after {
      content: '';
      position: absolute;
      right: -5px;
      top: -16px;
      z-index: -1;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid white;
      transform: rotate(45deg);
      border-radius: 42% 0 0 0;
    }

    li {
      height: 24px;
      padding-left: 14px;
      padding-right: 13px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #3c3c43;
      cursor: pointer;
      white-space: nowrap;

      &.withBorder {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 36px;
          bottom: -9px;
          display: block;
          width: 100px;
          height: 1px;
          background-color: #c1c5d6;
        }
      }
    }
  }
}
