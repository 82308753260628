.Escalation {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 594px;
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-left: 20px;
    }

    .text {
      .title {
        margin-bottom: 8px;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #242833;
      }

      .info {
        margin: 0;
        font-family: Gilroy-Regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #c1c5d6;
      }
    }
  }
}
