.Candidates {
  height: calc(100vh - 80px);
  // width: calc(100vw - 100px);
  width: 100%;
  min-width: 1114px;
  padding-top: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1024px) {
  .Candidates {
    height: unset;
    width: unset;
    min-width: unset;
    padding-top: 20px;
    padding-bottom: 53px;
  }
}
