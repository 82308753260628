.SideCalendar {
  display: flex;
  flex-direction: column;
  width: 339px;
  min-width: 339px;
  font-family: 'Gilroy-SemiBold';
  line-height: 100%;

  // Probably not used
  .calendar {
    padding-top: 7px;
    width: 100%;
    height: 356px;
    background: #ffffff;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .header {
      padding: 0 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;

      .container {
        height: 100%;
        padding: 12px;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
        border-radius: 6px;
        font-size: 24px;
        line-height: 100%;
        color: #141414;

        cursor: pointer;
      }

      .month,
      .year {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 7px;
          height: 7px;
          right: -7px;
          bottom: 0;
          background: url('../../../../assets/images/dashboard/candidate/calendar/dropDown.svg');
        }
      }

      .prev,
      .next {
        width: 30px;
        height: 30px;
        background: no-repeat center
          url('../../../../assets/images/dashboard/candidate/calendar/chevron.svg');
        box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
        border-radius: 80px;
        cursor: pointer;
      }

      .prev {
        transform: rotate(180deg);
      }
    }

    .days {
      margin-top: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;

      color: #1f1f1f;

      .day {
        width: 50px;
        display: flex;
        justify-content: center;
      }
    }

    .calendarMonth {
      margin: 17px 0 0 1px;
      display: flex;
      flex-wrap: wrap;
      font-size: 18px;
      line-height: 100%;

      color: #1f1f1f;

      .day {
        position: relative;
        width: 43.5px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .selected {
        color: white;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        box-shadow: 0px 1.5px 1px rgba(0, 31, 112, 0.25),
          0px 3px 2px rgba(0, 71, 255, 0.2);
        border-radius: 6px;
      }

      .today {
        color: white;
        background: #6722d2;
        border: 2px solid #f2f4f8;
        border-radius: 6px;
      }
      .anotherMonth {
        color: #c1c5d6;
      }

      .hasEvent {
        &:after {
          content: '';
          position: absolute;
          top: 2px;
          right: 2px;
          width: 10px;
          height: 10px;
          background: linear-gradient(
            228.41deg,
            #00e4ac 0%,
            #10c5e9 53.88%,
            #9240dc 106.71%
          );
          border-radius: 50%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .SideCalendar {
    width: 100%;
    min-width: unset;
  }
}
