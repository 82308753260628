.AddRestaurant {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;

  .verifyAddress {
    position: absolute;
    right: 0;
    top: calc(-56px - 22px - 13px);
    display: flex;
    gap: 17px;

    .verified {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      width: 163px;
      height: 50px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: white;
      background-color: #00e4ac;
      border: 1.5px solid #00e4ac;
      border-radius: 16px;
    }

    p {
      width: 212px;
      margin: 0;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #c1c5d6;
      text-align: center;
    }
  }

  .row {
    display: flex;
    gap: 63px;

    .col {
      display: flex;
      flex-direction: column;
      gap: 58px;
      flex: 1;
      max-width: 400px;
    }
  }
}
