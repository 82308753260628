.Calendar {
  // padding: 24px 34px 0 28px;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 15px;
  overflow-y: auto;
  height: calc(100vh - 80px);
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .container {
    min-width: 1360px;
    display: flex;
    gap: 10px;
  }
}

@media (max-width: 1024px) {
  .Calendar {
    height: unset;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f2f4f8;
    overflow-y: unset;

    .container {
      min-width: unset;
      flex-direction: column;
      gap: 18px;
    }
  }
}
