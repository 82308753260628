.container {
  position: absolute;
  top: 27px;
  left: -480px;
  z-index: 1000;
  width: 497px;
  height: 266px;
  padding: 23px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  cursor: default;

  .note {
    width: 455px;
    height: 145px;
    margin-bottom: 5px;
    padding: 15px 20px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    background-color: #f2f4f8;
    outline: none;
    border: none;
    border-radius: 16px;
    resize: none;

    &::placeholder {
      color: #c1c5d6;
    }
  }

  .left {
    align-self: flex-end;
    margin-bottom: 15px;
    font-family: 'Gilroy-Regular';
    font-size: 12px;
    line-height: 10px;
    color: #c1c5d6;
  }

  .buttons {
    display: flex;
    gap: 23px;
    justify-content: center;
    align-items: center;

    .button {
      width: 180px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
      background-color: #f2f4f8;
      border: 1px solid #c1c5d6;
      border-radius: 16px;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #c1c5d6;
        border-color: #c1c5d6;
        color: white;
      }

      &:active {
        background-color: black;
      }
    }

    .delete {
      background-color: #c1c5d6;
      border: 0;
    }

    .disabled {
      color: #c1c5d6;
      border-color: #c1c5d6;
      cursor: default;

      &:hover {
        background-color: transparent;
        border-color: #c1c5d6;
        color: #c1c5d6;
      }
    }

    .submit {
      border-color: #00e4ac;
      background-color: #00e4ac;
      color: 242833;

      &:hover {
        background-color: white;
        color: #00e4ac;
        border-color: #00e4ac;
      }

      &:active {
        border-color: black;
        color: white;
        background-color: black;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    right: -11px;
    left: unset;
    width: calc(100vw - 68px);
    height: unset;
    padding-top: 15px;
    padding-left: 19px;
    padding-right: 19px;
    padding-bottom: 13px;

    .note {
      width: 100%;
    }
  }
}
