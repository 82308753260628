.JobStatusDropdown {
  position: relative;

  .selector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 148px;
    height: 40px;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 24px;
    color: #242833;
    background-color: #00e4ac;
    box-shadow: 0px 16px 60px rgba(134, 109, 201, 0.16);
    border-radius: 16px;
    cursor: pointer;

    &.notActive {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 32px;
      color: #242833;
      background-color: transparent;
      border: 1.5px solid #242833;
      box-shadow: none;

      svg {
        display: none;
      }
    }
  }

  .options {
    position: absolute;
    bottom: -7px;
    transform: translateY(100%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin: 0;
    list-style: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    li {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 24px;
      color: #3c3c43;
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .JobStatusDropdown {
    .selector {
      width: 111px;
    }
  }
}
