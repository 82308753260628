@use '../../../../../../../variables' as *;

.Comment {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  .animationWrapper {
    height: 40px;
    width: 40px;
    overflow: hidden;
    .animationScale {
      height: 100%;
      width: 100%;
      transition: all 0.3s ease-in-out;
      transform: scale(1);
      &:hover {
        transform: scale(1.2);
      }
      & > img,
      .noPhoto {
        flex-shrink: 0;
        align-self: flex-start;
        width: 40px;
        height: 40px;
        border-radius: 14px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .noPhoto {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      font-size: 18px;
      color: white;
    }
  }

  .commentContainer {
    min-width: 350px;
    position: relative;
    margin-left: 10px;
    margin-right: 12px;
    margin-bottom: 26px;
    height: max-content;
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: #f2f4f8;
    border-radius: 16px;

    .emodjiContainer {
      position: absolute;
      top: 0;
      right: -32px;
      .emodji {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        align-self: flex-start;
        background: url('../../../../../../../assets/images/dashboard/discover/comments/smile-small.svg');
        cursor: pointer;

        &.disabled {
          cursor: auto;
        }
      }
    }

    &.selected {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    }

    .header {
      width: 100%;
      display: flex;
      gap: 40px;
      margin-bottom: 5px;
      align-items: center;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 18px;

      .authorName {
        align-self: flex-start;
        flex-shrink: 0;
      }
    }

    .textBox {
      height: max-content;
      white-space: pre-wrap;
      font-family: Gilroy-Medium;
      font-size: 14px;
      font-style: normal;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.14px;
      color: #242833;
      word-break: break-word;
      outline: none;
    }

    .usersTags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .footer {
      position: absolute;
      top: calc(100% + 5px);
      right: 20px;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 150%;
      color: #c1c5d6;

      & > div {
        display: flex;
        gap: 10px;
      }

      .rightPanel {
        opacity: 0.6;
      }

      .leftPanel {
        span {
          cursor: pointer;
        }
      }

      .charsLeft {
        font-family: 'Gilroy-Regular';
        font-size: 12px;
        line-height: 20px;
        color: #c1c5d6;

        &.red {
          color: #ff2d55;
        }
      }
    }
  }

  .reactions {
    .child {
      margin-top: 3px;
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 24px;

      color: #c1c5d6;
    }
  }
}

.emojiPicker {
  position: fixed;
  z-index: 3;
}

@media (max-width: $breakpoint) {
  .Comment {
    .animationWrapper {
      .animationScale {
        & > img,
        .noPhoto {
          width: 30px;
          height: 30px;
          font-size: 14px;
          border-radius: 8px;
        }
      }
    }

    .commentContainer {
      min-width: 100%;
      margin-right: 10px;
      padding-top: 9px;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 8px;
      border-radius: 8px;

      .emodjiContainer {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -25px;
        .emodji {
          width: 15px;
          height: 15px;
          background-size: contain;
        }
      }

      .footer {
        justify-content: space-between;
        gap: 20px;
        font-size: 12px;
        line-height: 8px;
        & > div {
          white-space: nowrap;
        }
      }
    }
  }
}
