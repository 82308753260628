.InPerson {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    gap: 27px;

    .col {
      display: flex;
      flex-direction: column;
      gap: 39px;
      flex: 1;
    }
  }

  .сontact {
    width: 350px;

    .selectedUsers {
      margin-top: 26px;
    }
  }

  .textareaContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
  }
}

@media (max-width: 1024px) {
  .InPerson {
    &.calendar {
      .row {
        flex-direction: column;
        gap: 24px;
        .col {
          gap: 24px;
        }
      }

      .сontact {
        position: static;
        width: unset;
      }
    }
  }
}
