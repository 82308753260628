.AddOrEditCity {
  display: flex;
  gap: 158px;

  .col {
    .coverImage {
      .error {
        margin-top: 6px;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 18px;
        color: #ff2d55;
      }

      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #242833;
      }

      .imageUploader {
        position: relative;
        width: 225px;
        height: 220px;
        border-radius: 10px;
        border: 1px dashed #000000;
        cursor: pointer;

        .uploaderContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Regular';
          background-color: #f2f4f8;
          opacity: 0.5;

          input {
            display: none;
          }
        }

        .plusIcon {
          position: absolute;
          z-index: 1;
          top: -15px;
          right: -15px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background-image: linear-gradient(
            228.41deg,
            #00e4ac 0%,
            #10c5e9 53.88%,
            #9240dc 106.71%
          );
          border-radius: 18.989px;
        }
      }

      .imageContainer {
        position: relative;
        width: 225px;
        height: 220px;

        .deleteIcon {
          position: absolute;
          top: -15px;
          right: -15px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background-color: #ff2d55;
          border-radius: 50%;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 15px;
        }
      }
    }

    &:first-child {
      display: flex;
      flex-direction: column;
      gap: 58px;
      width: 398px;
    }
  }
}
