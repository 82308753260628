.ViewCity {
  display: flex;
  gap: 158px;

  .col {
    .coverImage {
      h1 {
        margin: 0;
        margin-bottom: 5px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #242833;
      }
      .imageContainer {
        width: 225px;
        height: 220px;
        border-radius: 15px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &:first-child {
      display: flex;
      flex-direction: column;
      gap: 58px;
      width: 398px;
    }
  }
}
