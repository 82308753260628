.AddOrEditAssessment {
  display: flex;
  gap: 71px;

  .col {
    flex: 1;
  }

  .buttonContainer {
    position: absolute;
    left: calc(50% - 100px);
    bottom: 210px;
    display: flex;
    justify-content: center;

    button {
      width: 200px;
      height: 52px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: white;
      background-color: #242833;
      border: none;
      border-radius: 16px;
    }
  }
}
