.GradientButton {
  position: relative;
  flex: 1;
  // max-width: 140px;
  height: 38px;
  padding: 0;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: white;
  background-image: linear-gradient(
    228deg,
    #00e4ac 0%,
    #10c5e9 50.5%,
    #9240dc 100%
  );
  border-radius: 8.5px;
  border: none;
  overflow: hidden;

  &.active {
    color: black;
    background-color: white;
    background-image: none;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
    border: 1px solid transparent;

    &::after {
      content: var(--text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
    }
  }

  // do not remove
  /* &:not(:disabled) {
    &::after {
      content: var(--text);
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #c1c5d6;
      background-image: linear-gradient(
        228deg,
        #00e4ac 0%,
        #10c5e9 50.5%,
        #9240dc 100%
      );
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &:hover {
      &::after {
        opacity: 1;
        color: white;
      }
    }
  }
   */
  &:disabled {
    color: #c1c5d6;
    background-image: none;
  }
}

@media (max-width: 1024px) {
  .GradientButton {
    min-width: 140px;

    &.active {
      &::after {
      }
    }

    &:disabled {
    }
  }
}
