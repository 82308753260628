.AssessmentQuestions {
  display: flex;
  flex-direction: column;
  // gap: 80px;
  width: 100%;
  padding-right: 42px;

  .addButton {
    position: fixed;
    z-index: 1;
    bottom: 211.5px;
    right: 394px;
    width: 200px;
    height: 52px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: white;
    background-color: #242833;
    border-radius: 16px;
    border: none;
  }
}
