.RequestNewPasswordSuccessPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    height: 100%;

    p {
      margin: 0;
      font-family: Gilroy-SemiBold;
      font-size: 22px;
      font-weight: 600;
      line-height: 36px;
      text-align: left;
    }
  }
}

@media (max-width: 1024px) {
  .RequestNewPasswordSuccessPage {
    height: 567px;
  }
}
