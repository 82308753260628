.CandidateSourceTag {
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 20px;
  background-color: #f2f4f8;
  border-radius: 16px;
  user-select: none;
}
