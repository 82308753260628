.FullScreenButton {
  width: 48px;
  height: 48px;
  font-size: 0;
  border-radius: 16px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #f2f4f8;
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .fullScreenArrows {
    width: 48px;
    height: 48px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.2002 0.9375C10.786 0.9375 10.4502 1.27329 10.4502 1.6875C10.4502 2.10171 10.786 2.4375 11.2002 2.4375H17.402L11.1355 9.60543C10.8629 9.91728 10.8947 10.3911 11.2066 10.6637C11.5184 10.9363 11.9922 10.9045 12.2648 10.5927L18.3834 3.59393L18.4504 9.5648C18.455 9.97899 18.7946 10.311 19.2087 10.3063C19.6229 10.3017 19.9549 9.96216 19.9503 9.54797L19.8631 1.77796C19.8579 1.31228 19.4789 0.9375 19.0132 0.9375H11.2002Z' fill='url(%23paint0_linear_4014_62552)'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.99843 21.56H9.20021C9.61442 21.56 9.95021 21.8958 9.95021 22.31C9.95021 22.7242 9.61442 23.06 9.20021 23.06H1.38725C0.92152 23.06 0.542526 22.6852 0.537301 22.2196L0.450121 14.4495C0.445475 14.0354 0.777473 13.6958 1.19166 13.6912C1.60585 13.6865 1.94538 14.0185 1.95003 14.4327L2.01702 20.4036L8.13556 13.4048C8.40819 13.093 8.88199 13.0612 9.19384 13.3338C9.50569 13.6064 9.53748 14.0802 9.26486 14.3921L2.99843 21.56Z' fill='url(%23paint1_linear_4014_62552)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_4014_62552' x1='15.2003' y1='-4.6458' x2='4.09951' y2='4.79832' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_4014_62552' x1='5.20014' y1='7.56514' x2='-5.90061' y2='17.0093' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    transition: transform 0.2s ease-in-out;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    .fullScreenArrows {
      transform: scale(1.35);
    }
  }


  &.active {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.2002 0.9375C10.786 0.9375 10.4502 1.27329 10.4502 1.6875C10.4502 2.10171 10.786 2.4375 11.2002 2.4375H17.402L11.1355 9.60543C10.8629 9.91728 10.8947 10.3911 11.2066 10.6637C11.5184 10.9363 11.9922 10.9045 12.2648 10.5927L18.3834 3.59393L18.4504 9.5648C18.455 9.97899 18.7946 10.311 19.2087 10.3063C19.6229 10.3017 19.9549 9.96216 19.9503 9.54797L19.8631 1.77796C19.8579 1.31228 19.4789 0.9375 19.0132 0.9375H11.2002Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.99855 21.56H9.20033C9.61454 21.56 9.95033 21.8958 9.95033 22.31C9.95033 22.7242 9.61454 23.06 9.20033 23.06H1.38737C0.921642 23.06 0.542648 22.6852 0.537423 22.2196L0.450243 14.4495C0.445597 14.0354 0.777595 13.6958 1.19178 13.6912C1.60597 13.6865 1.9455 14.0185 1.95015 14.4327L2.01714 20.4036L8.13568 13.4048C8.40831 13.093 8.88212 13.0612 9.19396 13.3338C9.50581 13.6064 9.5376 14.0802 9.26498 14.3921L2.99855 21.56Z' fill='white'/%3E%3C/svg%3E%0A");
    background-color: #00e4ac;
    .fullScreenArrows {
      background: none;
    }
  }
}
