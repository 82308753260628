@import '../../../../../../../mixins';

.Contacts {
  padding-top: 38px;
  padding-left: 25px;
  padding-right: 19px;
  padding-bottom: 38px;

  @include onMobile {
    padding: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;

    .saveButtonsContainer {
      padding-bottom: 4px;

      @include onMobile {
        padding-bottom: 0px;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 37px;
  }
}
