.VrAndAssessment {
  padding-top: 30px;

  .counterContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 38px;
      margin-left: auto;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 21px;
      color: #242833;
      background-color: #f2f4f8;
      border-radius: 16px;
      position: relative;

      .error {
        position: absolute;
        bottom: -24px;
        margin-top: 6px;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        white-space: nowrap;
        line-height: 18px;
        color: #ff2d55;

        &.warning {
          color: #ffbf00;
        }
      }
    }

    span {
      margin-right: 179px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #c1c5d6;
    }

    .red {
      color: #ff2d55;
    }

    .amber {
      color: #ffbf00;
    }
  }

  .col {
    flex: 1;
  }

  .assessmentsFields {
    display: flex;
    gap: 45px;
  }

  .assessmentContainer {
    margin-bottom: 57px;
    .assessment {
      display: flex;
      gap: 70px;
      padding-top: 25px;
    }
  }

  .row {
    display: flex;
    flex-direction: column;

    &.jobPostForm {
      margin-top: 52px;
      margin-bottom: 27px;
      flex-direction: row;
      gap: 20px;
    }
  }

  .applicationMethodContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    margin-bottom: 27px;

    &.jobPostForm {
      margin: 0;
      flex-direction: row;
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .vrTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    &.wide {
      gap: 70px;

      .col {
        &:last-child {
          gap: 85px;
        }
      }

      .counterContainer {
        margin-bottom: 0;
      }
    }

    .col {
      display: flex;
      align-items: center;
      flex: 1;

      &:first-child {
        min-width: 585px;
      }
    }

    .optional {
      margin-left: 10px;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;
    }
  }

  .vrContainer {
    margin-top: 50px;

    .vrQuestionButton {
      width: 184px;
      height: 44px;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 24px;
      color: white;
      background-color: #242833;
      border-radius: 16px;
      border: none;
    }
  }
}

@media (max-width: 1024px) {
  .VrAndAssessment {
    padding-top: 0;

    .row {
      scrollbar-width: none;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &.jobPostForm {
        margin-bottom: 53px;
      }
    }

    .vrTitle {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      &.wide {
        align-items: flex-start;
        gap: 30px;

        .counterContainer {
          display: none;
        }
      }

      .col {
        &:first-child {
          align-items: flex-start;
          min-width: unset;
        }
      }

      .optional {
        margin-left: 40px;
      }
    }

    .vrContainer {
      .counterContainer {
        display: none;
      }
    }
  }
}
