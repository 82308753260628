.ViewAssessment {
  .questionCount {
    position: absolute;
    top: 47px;
    right: 56px;
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
    }

    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
      background-color: #f2f4f8;
      border-radius: 8px;
    }
  }

  .buttonContainer {
    position: absolute;
    left: calc(50% - 100px);
    bottom: 210px;
    display: flex;
    justify-content: center;

    button {
      width: 200px;
      height: 52px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: white;
      background-color: #242833;
      border: none;
      border-radius: 16px;
    }
  }

  .row {
    display: flex;
    gap: 70px;
    .col {
      display: flex;
      flex-direction: column;
      gap: 58px;
      flex: 1;
    }
  }
}
