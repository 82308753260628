.Preview {
  display: flex;
  flex-direction: column;
  height: 567px;
  padding-top: 18px;
  padding-left: 22px;
  padding-bottom: 17px;
  background-color: #f2f4f8;
  border-radius: 16px;
  overflow-x: auto;
  overflow-y: hidden;

  .firstRow {
    display: flex;
    gap: 14px;
    margin-bottom: 13px;
  }

  .secondRow {
    display: flex;
    gap: 15px;

    .col {
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex: 1;
      min-width: 314px;
      max-width: 314px;
      padding-right: 22px;

      .pagePreviewCutContainer {
        position: relative;
        width: 292px;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid red;

        &.active {
          border: 2px solid transparent;
          background: linear-gradient(
              223deg,
              #00e4ac 20.85%,
              #10c5e9 65.29%,
              #9240dc 100%
            )
            border-box;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Preview {
    height: unset;
    padding-left: 10px;
    padding-right: 10px;

    .firstRow {
      flex-direction: column;
      align-items: center;
    }

    .secondRow {
      display: grid;
      grid-template-rows: auto auto; /* Two rows */
      grid-template-columns: calc(100% - 80px - 5px) 80px; /* Two columns */
      column-gap: 7px;
      justify-content: center;
    }

    .col {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .pagePreviewCutContainer {
        position: relative;
        &.active {
          border-radius: 8px;
          border: 2px solid transparent;
          background: linear-gradient(
              223deg,
              #00e4ac 20.85%,
              #10c5e9 65.29%,
              #9240dc 100%
            )
            border-box;
        }
      }
    }
  }
}
