.Notification {
  position: fixed;
  top: -100%;
  left: 50%;
  z-index: 99999;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 774px;
  height: 74px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.5 13.8493V14.9993C27.4985 17.6949 26.6256 20.3177 25.0117 22.4766C23.3977 24.6356 21.1291 26.2149 18.5442 26.9792C15.9593 27.7435 13.1966 27.6517 10.6681 26.7176C8.1396 25.7834 5.98082 24.057 4.5137 21.7957C3.04658 19.5344 2.34974 16.8594 2.5271 14.1697C2.70445 11.48 3.74651 8.91975 5.49785 6.87068C7.24919 4.82162 9.61598 3.39357 12.2452 2.79952C14.8745 2.20548 17.6253 2.47726 20.0875 3.57434' stroke='%2300E4AC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M27.5 5L15 17.5125L11.25 13.7625' stroke='%2300E4AC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 35px center;
  background-color: #242833;
  border-radius: 20px;
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.15s;

  &.visible {
    top: 95px;
    // transition: cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.15s;
    transition: all 0.15s;
  }

  .undoButton {
    position: absolute;
    right: 39px;
    width: 81px;
    height: 30px;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #242833;
    background-color: white;
    border-radius: 10px;
    border: none;
  }
}

@media (max-width: 1024px) {
  .Notification {
    width: calc(100vw - 32px);
    height: unset;
    min-height: 74px;
    padding-top: 15px;
    padding-bottom: 11px;
    padding-left: 63px;
    padding-right: 19px;
    text-align: center;
    background-position: 18px center;
  }

  .undoButton {
    display: none;
  }
}
