.Button {
  width: 120px;
  height: 48px;
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: Gilroy-Regular;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  background: #242833;
  border: 0;
  border-radius: 16px;

  &:disabled {
    background-color: #c1c5d6;
    cursor: default;
  }
}

.feedbackButton {
  background-color: #00e4ac;
}

.editFeedback {
  background: #242833;
}

.viewFeedback {
  background: white;
  border: 1px solid rgba(36, 40, 51, 1);
  color: rgba(36, 40, 51, 1);
}
