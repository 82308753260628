.header {
  padding: 9px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .new {
    position: relative;
    width: 123px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 20px;
    color: #242833;
    background: #00e4ac;
    box-shadow: 0px 16px 60px rgba(134, 109, 201, 0.16);
    border-radius: 16px;
    border: none;
    cursor: pointer;

    img {
      margin-left: 10px;
    }
  }

  .itemsAmount {
    margin-right: 60px;
    color: #242833;
  }

  .inputs {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.notes {
  height: 485px;
  margin: -30px;
  display: flex;
  flex-direction: column;
  gap: 46px;
  overflow: scroll;
  overflow-x: hidden;
}

@media (max-width: 1024px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 20px;

    .itemsAmount {
      font-size: 14px;
      margin-right: 0;
      white-space: nowrap;
    }

    .inputs {
      gap: 15px;
      flex-wrap: wrap;
    }
  }

  .notes {
    width: 100%;
    height: unset;
    overflow: unset;
    gap: 20px;
    margin: 0;
  }
}
