.Header {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;

  .container {
    padding-bottom: 10px;
    border-bottom: 1.5px solid #d9d9d9;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 21px;

      h3 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 20px;
        line-height: 28px;
        color: #242833;
      }

      button {
        padding: 0;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        // text-decoration-line: underline;
        text-transform: capitalize;
        color: #242833;
        background-color: transparent;
        border: none;
      }
    }

    .options {
      display: flex;
      justify-content: space-between;

      .dropdown {
        position: relative;

        > ul {
          top: 46px;
          left: unset;
          right: 0;
        }
      }

      button {
        img {
          position: relative;
          top: -2px;
        }
      }

      .tabs {
        display: flex;
        gap: 15px;
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }
  }
}
