@import '../../../../../mixins';

.tab {
  width: 100%;
  max-width: 1067px;
  .candidates {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include onMobile {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
