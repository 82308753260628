.InterviewDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 30px;
  width: 100%;

  .title {
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 24px;
    color: #3c3c43;
  }

  & > div {
    flex-basis: calc(50% - 22px);
  }

  .mode {
    display: flex;
    gap: 22px;

    & > div {
      flex-basis: 50%;
    }
  }

  .connection {
    flex-basis: 100%;
  }

  .form {
    display: flex;
    flex-direction: column;
    flex: 1;

    .input {
      width: 100%;
      height: 45px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: center;
      font-family: Gilroy-SemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      background-color: white;
      border: none;
      outline: none;
      border-radius: 16px;

      &.textarea {
        height: 155px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .InterviewDetails {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
