.AverageTimeToFill {
  width: 450px;

  h3 {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 15px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 28px;
    color: #242833;
  }

  .col {
    flex: 1;
  }

  .data {
    padding-top: 37px;
    max-width: 365px;
    height: 100%;
    overflow: hidden;

    ul {
      position: relative;
      display: flex;
      gap: 20px;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      transition: left cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
    }
  }

  .graph {
    position: relative;
    display: flex;
    gap: 18px;
    height: 313px;
    padding-top: 7px;
    padding-left: 18px;
    padding-right: 15px;
    padding-bottom: 14px;
    background-color: white;
    border-radius: 16px;
    filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));

    .noData {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      p {
        width: 226px;
        margin: 0;
        font-family: 'Gilroy-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #c1c5d6;
      }
    }

    .scrollButton {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.21967 1.28033C-0.0732233 0.987436 -0.0732233 0.512564 0.21967 0.21967C0.512563 -0.0732231 0.987437 -0.0732231 1.28033 0.21967L6.28033 5.21967C6.57322 5.51256 6.57322 5.98744 6.28033 6.28033L1.28033 11.2803C0.987437 11.5732 0.512563 11.5732 0.21967 11.2803C-0.0732233 10.9874 -0.0732233 10.5126 0.21967 10.2197L4.68934 5.75L0.21967 1.28033Z' fill='%23242833'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
      border: none;

      &.left {
        right: unset;
        left: -25px;
        transform: translateY(-50%) scaleX(-1);
      }
    }

    .yAxis {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding-bottom: 5px;

      h4 {
        visibility: hidden;
        margin: 0;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: #a2a3a5;
      }

      .days {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0;
        list-style: none;
        flex: 1;

        li {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #a2a3a5;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .AverageTimeToFill {
    width: 100%;

    h3 {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 20px;
    }

    .data {
      min-width: 100%;
      padding-top: 0;

      ul {
        justify-content: space-between;
        gap: 24px;
      }
    }

    .graph {
      gap: 15px;
      height: 269px;
      padding-top: 18px;
      padding-left: 17px;
      padding-right: 17px;
      padding-bottom: 19px;
      overflow: hidden;
      .scrollButton {
        display: none;
      }

      .yAxis {
        h4 {
          display: none;
        }
      }
    }
  }
}
