.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 180px;
  padding: 18px 13px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  opacity: 0;
  border-radius: 16px;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  pointer-events: none;

  .option {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  
    &:hover {
      font-size: 18px;
      font-weight: 500;
    }
  }

}

.active {
  z-index: 3;
  opacity: 1;
  pointer-events: all;
}