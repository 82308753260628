.ReadonlyQuestions {
  display: flex;
  flex-direction: column;
  gap: 39px;

  .question {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .row {
      display: flex;
      gap: 46px;

      .col {
        flex: 1;
      }
    }
  }
}
