.AllCandidates {
  .arrowMenuContainer {
    position: fixed;
    z-index: 1000;
  }

  .candidateFiltersConainer {
    position: sticky;
    padding-left: 24px;
    padding-right: 24px;
  }

  .tableMenuContainer {
    display: flex;
    align-items: center;
    padding-right: 25px;

    &.withoutButton {
      padding-right: 0;
    }

    .innerContainer {
      flex: 1;
    }
  }

  .viewContainer {
    position: relative;
  }

  .noContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 95px;
    font-family: Gilroy-Bold;
    font-size: 20px;
  }

  .gridContainer {
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
      column-gap: 25px;
      row-gap: 33px;
      padding-top: 15px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 12px;
    }
  }
}

@media (max-width: 1024px) {
  .AllCandidates {
    display: grid;
    width: 100%;
    overflow: auto;

    .noContent {
      padding-top: 95px;
    }

    .candidateFiltersConainer {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .tableMenuContainer {
      min-width: 1200px;
      padding-right: 0;
    }
  }
}
