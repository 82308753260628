.Experience {
  display: flex;
  flex-direction: column;
  margin-top: 47px;

  &:last-child {
    .addButton {
      display: flex;
    }
  }

  .inputs {
    display: flex;
    gap: 15px;
  }

  .addButton {
    display: none;
    gap: 10px;
    align-self: flex-end;
    margin-top: 47px;
    padding: 0;
    background-color: transparent;
    font-family: Gilroy-SemiBod;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    border: none;
  }
}

@media (max-width: 1024px) {
  .Experience {
    margin-top: 37px;
    &:last-child {
      .addButton {
      }
    }

    .inputs {
      flex-direction: column;
      gap: 37px;
    }

    .addButton {
    }
  }
}
