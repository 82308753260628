.container {
  position: absolute;
  top: 60px;
  z-index: 1;
  width: 100%;
  height: 400px;
  padding: 10px 15px 20px 25px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  border-radius: 18px;
  box-shadow: 0px 66px 60px rgba(62, 63, 99, 0.05);
  background: white;
  overflow: hidden;

  .month {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 1px rgba(0, 14, 51, 5%);
    border-radius: 6px;
    font-size: 24px;
    line-height: 100%;
    color: #141414;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 1px 7px rgba(24, 39, 75, 28%);
    }
  }

  .activeMonth {
    background: linear-gradient(228.41deg, #00E4AC 0%, #10C5E9 53.88%, #9240DC 106.71%);
    color: white;
  }
}