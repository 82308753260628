.Assessments {
  &.readOnly {
    pointer-events: none;

    .selector {
      display: none;
    }
  }

  .labelContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    .label {
      font-family: Gilroy-Bold;
      font-size: 16px;
      line-height: 20px;
      color: #242833;
    }

    .amount {
      width: 48px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Gilroy-SemiBold;
      font-size: 14px;
      line-height: 24px;
      border-radius: 16px;
      background: #c1c5d6;
      color: #ffffff;

      &.green {
        background-color: #00e4ac;
      }
    }
  }

  .selector {
    position: relative;
    margin-top: 2px;
    width: 368px;
    height: 45px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-family: Gilroy-Regular;
    font-size: 16px;
    color: #c1c5d6;
    border-radius: 16px;
    background: #f2f4f8;
    cursor: pointer;

    .arrow {
      position: absolute;
      right: 21px;
      display: block;
      width: 12px;
      height: 8px;
      background: url('../../../../../../../../assets/images/dashboard/reports/filter/arrow.svg');
      transform: rotate(180deg);
      cursor: pointer;

      &.arrowUp {
        background: url('../../../../../../../../assets/images/dashboard/reports/filter/black-arrow.svg');
        transform: rotate(0);
      }
    }
  }
}

@media (max-width: 1024px) {
  .Assessments {
    &.readOnly {
      .selector {
      }
    }

    .labelContainer {
      .label {
      }

      .amount {
        &.green {
        }
      }
    }

    .selector {
      width: 100%;

      .arrow {
        &.arrowUp {
        }
      }
    }
  }
}
