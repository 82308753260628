.Notification {
  h4 {
    margin: 0;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 21px;
    color: #242833;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .timeago {
      color: #c1c5d6;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    margin-top: 15px;

    button {
      width: 112px;
      height: 30px;
      font-family: 'Gilroy-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      color: white;
      border-radius: 10px;
      border: none;

      &.blackButton {
        background-color: #242833;
      }

      &.greenButton {
        background-color: #00e4ac;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Notification {
    h4 {
      font-size: 14px;
      line-height: 24px;
    }

    .content {
      font-size: 12px;
      line-height: 24px;
      .timeago {
      }
    }

    .buttons {
      gap: 15px;

      button {
        width: 76px;
        border-radius: 8px;
      }
    }
  }
}
