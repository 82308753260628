.PinIcon {
  cursor: pointer;

  img {
    transition: transform 0.1s ease-in-out;
  }

  &.pinned {
    img {
      transform: rotate(-45deg);
    }
  }
}
