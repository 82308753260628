@keyframes backgroundBounce {
  0% {
    background-size: 0%;
  }
  25% {
    background-size: 50%;
  }
  50% {
    background-size: 110%;
  }
  75% {
    background-size: 80%;
  }
  100% {
    background-size: 100%;
  }
}

.TopMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 268px;
  .optionsWrapper {
    overflow: hidden;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    &:hover {
      .addOptionsContainer {
        transform: rotate(180deg);
      }
      .scaleWrapper {
        transform: scale(1.3);
      }
    }
  }

  .scaleWrapper {
    transition: all 0.3s ease-in-out;
  }

  .addOptionsContainer {
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }

  i {
    display: block;
    font-size: 0;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    .innerIcon {
      height: 32px;
      width: 32px;
    }

    &:hover {
      &.favoritesIcon {
        animation: backgroundBounce 0.3s ease-in-out;
        background-image: url('../../../../assets/images//dashboard/header/favoritesActive.svg') !important;
      }
      &.notificationIcon {
        animation: backgroundBounce 0.3s ease-in-out;
        background-image: url('../../../../assets/images//dashboard/header/notificationsActive.svg') !important;
      }
      &.chatIcon {
        animation: backgroundBounce 0.3s ease-in-out;
        background-image: url('../../../../assets/images//dashboard/header/chatActive.svg') !important;
      }
      &.helpIcon {
        animation: backgroundBounce 0.3s ease-in-out;
        background-image: url('../../../../assets/images//dashboard/header/helpActive.svg') !important;
      }
      &.mapIcon {
        animation: backgroundBounce 0.3s ease-in-out;
        background-image: url('../../../../assets/images//dashboard/header/mapActive.svg') !important;
      }
    }

    &.notificationIcon {
      &.hasUnreadNotifications {
        position: relative;
        &.active {
          &::after {
            top: 7px;
            right: 7px;
            width: 8px;
            height: 8px;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }

    &.chatIcon {
      &.hasUnreadChatMessages {
        position: relative;

        &.active {
          &::after {
            top: 7px;
            right: 5px;
            width: 8px;
            height: 8px;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }

    &.create {
      background-image: url(../../../../assets/images/dashboard/header/create.svg);
    }
  }
}
/* 
@media (max-width: 1024px) {
  .TopMenu {
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
}
 */

.TopMenu.mobile {
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
