.Delegates {
  display: flex;
  gap: 35px;

  .col {
    flex: 1;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 14px;

      .optional {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 24px;
        color: #c1c5d6;
      }
    }

    .radios {
      display: flex;
      gap: 21px;

      &.delegateRadios {
        margin-bottom: 89px;
      }

      &.durationRadios {
        margin-top: 12px;
      }
    }

    .delegate {
      display: flex;
      gap: 39px;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 1024px) {
  .Delegates {
    flex-direction: column;
    gap: 20px;
    .col {
      .header {
        .optional {
        }
      }

      .radios {
        &.delegateRadios {
          margin-bottom: 20px;
        }

        &.durationRadios {
        }
      }

      .delegate {
        margin-bottom: 20px;
      }
    }
  }
}
