.RedAlertJobs {
  position: relative;
  height: var(--parentHeight);
  min-height: 291px;
  padding-top: 12px;
  padding-left: 22px;
  padding-right: 5px;
  padding-bottom: 30px;
  background-color: white;
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 16px;
  --maxTableHeight: 197px;

  .noData {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 164px;
    background-color: transparent;
    pointer-events: none;

    p {
      width: 226px;
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin-bottom: 17px;
    padding-right: 17px;

    h2 {
      margin-bottom: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: #242833;
    }

    .dropdowns {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      .dropdownContainer {
        flex: 1;
        max-width: 140px;
      }
    }
  }

  .borderContainer {
    position: relative;
    .border {
      position: absolute;
      top: 0;
      z-index: 1;
      display: block;
      width: calc(100% - 11px - var(--rajScrollBarWidth));
      height: 35px;
      border: 1px solid #eff0f4;
      border-radius: 8px;
      pointer-events: none;
    }

    .tableContainer {
      position: relative;
      //  max-height: 197px;
      max-height: var(--maxTableHeight);
      overflow-y: scroll;
      scrollbar-color: #00e4ac transparent;
      scrollbar-width: thin;
      padding-right: 11px;

      &.noScroll {
        overflow-y: hidden;
      }

      table {
        position: relative;
        width: 100%;

        thead {
          position: sticky;
          top: 0;
          tr {
            th {
              height: 35px;
              font-family: 'Gilroy-SemiBold';
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #505470;
              background-color: #f7f8fa;
              cursor: pointer;

              &:first-child {
                border-radius: 8px 0 0 8px;
              }

              &:last-child {
                border-radius: 0 8px 8px 0;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: center;
              vertical-align: bottom;
              height: 46px;
              font-family: 'Gilroy-Regular';
              font-size: 12px;
              line-height: 14px;
              color: #242833;

              &:first-child {
                padding-left: 9px;
              }

              &.red {
                text-decoration: none;
                color: #ff2d55;

                a {
                  text-decoration: none;
                  color: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .RedAlertJobs {
    height: 291px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 18px;

    .header {
      margin-bottom: 20px;
      padding-right: 0;
      h2 {
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
      }

      .dropdowns {
        .dropdownContainer {
          width: 100px;
          max-width: 100px;
        }
      }
    }

    .borderContainer {
      .tableContainer {
        max-height: 210px;
        min-height: 210px;

        table {
          width: 700px;
          thead {
            tr {
              th {
                white-space: nowrap;
                &:first-child {
                  padding-left: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
