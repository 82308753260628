.dotMenu {
  display: flex;
  justify-content: center;
  line-height: 0.2;
  position: relative;
  top: 2px;
  width: 35px;
  height: 23px;
  border-radius: 25px;
  transition: background-color 0.5s;
  cursor: pointer;

  .selector {
    display: flex;
    width: 40px;
    justify-content: center;
    font-weight: bold;
    font-size: 28px;

    color: #c1c5d6;
  }

  .options {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 5px);
    right: 17px;
    width: 180px;
    height: max-content;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px 0 16px 16px;

    &::before {
      content: '';
      position: absolute;
      right: -5px;
      top: -16px;
      z-index: -1;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 40px solid white;
      transform: rotate(45deg);
      border-radius: 42% 0 0 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      background: #c1c5d6;
      height: 1px;
      width: 150px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0;
      padding: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 20px;
        color: #c1c5d6;
        cursor: pointer;

        &:hover {
          font-family: 'Gilroy-SemiBold';
          color: #242833;
        }

        &:nth-child(2) {
          margin-bottom: 35px;
        }
      }

      .disabled {
        cursor: default;
        color: #c1c5d6;

        &:hover {
          font-size: 16px;
          font-family: 'Gilroy-Regular';
          font-weight: 400;
          color: #c1c5d6;
          background-color: white;
        }
      }
    }
  }

  .smallOptions {
    &::after {
      top: 38px;
      left: 70px;
    }
  }
}
