.Details {
  padding-top: 49px;
  padding-bottom: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    .title {
      font-family: Gilroy-Bold;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #242833;
    }

    .warning {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 45px;
      font-family: Gilroy-SemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: white;
      background-color: #ffbf00;
      border-radius: 16px;
    }
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 41px;
  }
}

@media (max-width: 1024px) {
  .Details {
    padding-top: 16px;
    padding-bottom: 30px;
    .header {
      margin-bottom: 20px;

      .title {
        white-space: nowrap;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      row-gap: 37px;
    }
  }
}
