.ReportList {
  user-select: none;

  .listTitle {
    display: flex;
    justify-content: space-between;
    gap: 13px;
    align-items: center;
    cursor: pointer;

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 20px;
      color: #242833;
    }

    .infoIconContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      .arrow {
        margin-top: 2px;
        display: block;
        width: 12px;
        height: 8px;
        background: url('../../../../../assets/images/dashboard/reports/filter/arrow.svg');
        transform: rotate(180deg);
      }

      .arrowUp {
        background: url('../../../../../assets/images/dashboard/reports/filter/black-arrow.svg');
        transform: rotate(0);
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    list-style: none;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 24px;
    color: #c1c5d6;
  }

  .noData {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #242833;
  }
}
