.MessagesAndFeedback {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 22px;
      color: #242731;
    }

    button {
      border: none;
      padding: 0;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 17px;
      color: #c1c5d6;
      background-color: transparent;
    }
  }

  .noData {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 34px;

    p {
      width: 226px;
      font-family: 'Gilroy-SemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }
  }

  .users {
    display: flex;
    gap: 13px;

    .user {
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 21px;
        left: 6px;
        display: block;
        width: 36px;
        height: 30px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(6, 217, 195, 0.4) 100%
          ),
          url(../../../../../assets/images/chat/photo-1.jpg);
        opacity: 0.4;
        filter: blur(14.2718px);
        border-radius: 11.4174px;
      }

      &:hover {
        &::after {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          display: block;
          background: linear-gradient(
            228.41deg,
            #00e4ac 0%,
            #10c5e9 53.88%,
            #9240dc 106.71%
          );
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border-radius: 50%;
        }
      }

      .noAvatar {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        color: white;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 50%;
      }

      img {
        position: relative;
        z-index: 1;
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1024px) {
  .MessagesAndFeedback {
    .header {
      h3 {
        font-size: 16px;
        line-height: 20px;
      }

      button {
      }
    }

    .noData {
      p {
      }
    }

    .users {
      gap: 16px;
      .user {
        &::before {
        }

        &:hover {
          &::after {
          }
        }

        .noAvatar {
        }

        img {
        }
      }
    }
  }
}
