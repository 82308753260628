.Message {
  max-width: 725px;

  &.my {
    margin-left: auto;

    .text {
      background-color: white;
      border-radius: 16px 16px 0px 16px;
      box-shadow: 0px 4px 60px -0.4px rgba(78, 79, 114, 0.1);
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      .createdAt {
        margin-left: auto;
      }
    }
  }

  .header {
    margin-bottom: 8px;

    .userHeaderContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h5 {
      margin-bottom: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: black;
      opacity: 0.6;
    }
  }

  .text {
    margin-bottom: 6px;
    padding-top: 21px;
    padding-left: 20px;
    padding-right: 34px;
    padding-bottom: 21px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 16px 16px 16px 0px;
  }

  .footer {
    .createdAt {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 24px;
      opacity: 0.6;
      display: block;
    }
  }

  .messageTools {
    display: flex;
    align-items: center;
    gap: 10px;
    .speakButton {
      background: none;
      border: none;
      padding: 0;
    }
    .languageSelector {
      background: 'none';
      border: 'none';
      color: 'blue';
      padding: 0;
    }
  }
}
