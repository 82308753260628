.DocUploader {
  input {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    .title {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }

    span {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #c1c5d6;
    }
  }

  .uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 127px;
    background-color: #f2f4f8;
    border: 1.5px dashed #c1c5d6;
    border-radius: 16px;
    cursor: pointer;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #c1c5d6;

      .browse {
        color: #00e4ac;
      }

      .fileType {
        color: black;

        &.hasFile {
          position: relative;
          display: flex;
          align-items: center;
          height: 44px;
          padding-left: 29px;
          padding-right: 29px;
          background-color: #c1c5d6;
          border-radius: 10px;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: white;
          white-space: nowrap;
          user-select: none;

          i {
            position: absolute;
            top: -15px;
            right: -15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            background-color: #ff2d55;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
