.AuthCarousel {
  display: flex;
  align-items: center;
  max-width: 668px;
  height: 100%;
  // max-height: 902px;

  &.noAnimation {
    .background {
      .carouselSlideContainer {
        transition: none !important;
      }
    }
  }

  .background {
    position: relative;

    // width: 100%;
    flex: 1;
    max-height: 100%;
    width: 100%;
    height: 100%;
    //  height: 100%;
    // width: 100%;
    ///    aspect-ratio: 668 / 902;
    background-image: url(../../../assets/images/carousel/top-left-shape.svg),
      url(../../../assets/images/carousel/top-right-shape.svg),
      url(../../../assets/images/carousel/bottom-left-shape.svg),
      url(../../../assets/images/carousel/bottom-right-shape.svg);
    background-position: top left, top right, left bottom 91px, bottom right;
    background-repeat: no-repeat;
    background-color: #f2f4f8;
    border-radius: 16px;
    background: none;
    overflow: hidden;

    .carouselSlideContainer {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      gap: 65px;
      transition: right ease-in-out 0.3s;
    }

    .dots {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);
      display: flex;
      gap: 20px;
    }
  }
}
