.DaysFromCreation {
  text-align: center;

  &.orange {
    font-family: 'Gilroy-Bold';
    color: #ffbf00;
  }

  &.red {
    font-family: 'Gilroy-Bold';
    color: #ff2d55;
  }
}
