.Group {
  &.open {
    .header {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.53033 6.53033C1.23744 6.82322 0.762564 6.82322 0.46967 6.53033C0.176777 6.23744 0.176777 5.76256 0.46967 5.46967L5.46967 0.46967C5.76256 0.176776 6.23744 0.176776 6.53033 0.46967L11.5303 5.46967C11.8232 5.76256 11.8232 6.23744 11.5303 6.53033C11.2374 6.82322 10.7626 6.82322 10.4697 6.53033L6 2.06066L1.53033 6.53033Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 31px;
    padding-right: 51px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.28033 0.46967C0.987436 0.176777 0.512564 0.176777 0.21967 0.46967C-0.0732231 0.762563 -0.0732231 1.23744 0.21967 1.53033L5.21967 6.53033C5.51256 6.82322 5.98744 6.82322 6.28033 6.53033L11.2803 1.53033C11.5732 1.23744 11.5732 0.762563 11.2803 0.46967C10.9874 0.176777 10.5126 0.176777 10.2197 0.46967L5.75 4.93934L1.28033 0.46967Z' fill='%2300E4AC'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 36px;
    cursor: pointer;
    .threadCount {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 15px;
      margin-right: 5px;
      font-family: 'Gilroy-SemiBold';
      font-size: 12px;
      color: white;
      background-color: #242833;
      border-radius: 16px;
    }

    .title {
      width: 198px;
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #725ee8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      height: 15px;
      margin-left: auto;
      font-family: 'Gilroy-SemiBold';
      font-size: 12px;
      color: white;
      background-color: #c1c5d6;
      border-radius: 16px;

      &.active {
        background-color: #00e4ac;
      }

      &.onHold {
        background-color: #ffbf00;
      }
    }
  }

  .contactsList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0;
    padding: 0;
  }
}
