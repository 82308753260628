.UserMenu {
  position: absolute;
  z-index: 10;
  bottom: -115px;
  right: 0;

  li {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 20px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #242833;

    &:hover {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #141224;
      background-color: #f2f4f8;
    }

    &.active {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #00e4ac;
    }
  }

  .firstLevel {
    display: flex;
    flex-direction: column;
    width: 146px;
    height: 95px;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: white;
    border-radius: 16px;
    filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));

    .firstLevelOption {
      &:first-child {
        border-radius: 16px 16px 0 0;
      }

      &:last-child {
        border-radius: 0 0 16px 16px;
      }
    }
  }

  .secondLevel {
    position: absolute;
    left: -157px;
    padding-right: 9px;

    ul {
      width: 148px;
      height: 188px;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;
      background-color: white;
      border-radius: 16px;
      filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));
      overflow: hidden;
    }

    &.language {
      top: 50%;
    }

    &.online {
      top: 0;
    }

    .secondLevelOption {
      flex: 1;
    }
  }
}
