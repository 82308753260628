.Buttons {
  display: flex;
  align-items: center;
  height: 144px;
  min-height: 144px;
  margin-top: auto;

  .mainButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 34px;
    width: 34px;
    height: 34px;
    background-color: #00e4ac;
    border-radius: 50%;
    border: none;

    &:last-child {
      transform: rotate(180deg);
    }

    img {
      position: relative;
      left: -1.5px;
    }
  }
}

@media (max-width: 1024px) {
  .Buttons {
    height: unset;
    min-height: unset;
    background-color: white;
    border-radius: 0 0 16px 16px;

    .mainButtons {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 26px;

      button {
        width: 100% !important;
      }
    }

    .switch {
      display: none;
    }
  }
}
