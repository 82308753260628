.AddOptionsDropdown {
  position: absolute;
  top: 55px;
  width: 154px;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;

  li {
    display: flex;
    align-items: center;
    height: 43px;
    padding-left: 20px;
    font-family: Gilroy-Medium;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: white;
      background-image: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
    }
  }
}

@media (max-width: 1024px) {
  .outerContainer {
    transform: translate(-120px, -50px);
  }
}
