.eventGroup {
  border-radius: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #242833;
    border-radius: 16px;

    .group {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: normal;
      color: white;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .counter {
        width: 50px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #242833;
        background-color: #f2f4f8;
        border-radius: 16px;
      }

      .show {
        position: relative;
        width: 25px;
        height: 25px;
        border: 1.5px solid white;
        border-radius: 50%;
        cursor: pointer;

        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 1.5px;
          left: 50%;
          top: 50%;
          background-color: white;
          transform: translate(-50%, -50%);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      .hide {
        &::after {
          display: none;
        }
      }
    }
  }

  .isOpened {
    height: 50px;
    background-color: #c1c5d6;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 4px 60px 0px rgba(78, 79, 114, 0.1);
  }

  .events {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    border-radius: 0 0 16px 16px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #242833;
    background-color: white;

    &.noEvents {
      padding-top: 19px;
      padding-left: 20px;
      padding-bottom: 23px;
    }

    .event {
      display: flex;
      flex-direction: column;
      gap: 5px;
      cursor: pointer;

      .text {
        font-family: Gilroy-Bold;
        font-size: 16px;
        font-style: normal;
        line-height: 20px;
        color: #242833;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .date {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: #c1c5d6;
      }
    }
  }
}
