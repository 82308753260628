.Assessment {
  position: relative;
  z-index: 1;
  padding: 20px 27px 6px;
  min-height: 58px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #f2f4f8;
  box-shadow: 0px 0px 30px 0px rgba(62, 63, 99, 0.14);

  &.jobTemplate {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 132px;
    padding-left: 54px;
    padding-right: 40px;
    padding-top: 0;

    .titleContainer {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .delete {
      top: 10px;
      right: 10px;
    }

    .drag {
      left: 20px;
    }
  }

  &.isForm {
    flex-direction: row;
    justify-content: space-between;
    min-height: 132px;
    align-items: center;
    padding: 10px 80px 10px 40px;
  }

  .drag {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 12px;
    height: 18px;
    background-image: url('../../../../assets/images/dashboard/reports/bigDrag.svg');
    background-repeat: no-repeat;
    cursor: grab;
    transform: translateY(-50%);
  }

  .title {
    font-family: Gilroy-Bold;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .inputs {
    display: flex;
    gap: 25px;
    row-gap: 8px;
    flex-wrap: wrap;
  }

  .delete {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 22px;
    background-image: url('../../../../assets/images/dashboard/reports/x.svg');
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .Assessment {
    &.jobTemplate {
      flex-direction: column;
      align-items: flex-start;
      height: unset;
      padding: 20px;
      box-shadow: 0px 0.4px 60px 0px rgba(62, 63, 99, 0.1);
      .titleContainer {
        width: 100%;
        padding-left: 22px;
      }

      .delete {
      }

      .drag {
        top: 26px;
        transform: none;
      }
    }

    &.isForm {
    }

    .drag {
    }

    .title {
    }

    .inputs {
    }

    .delete {
    }
  }
}
