.PollButtons {
  display: flex;
  gap: 29px;

  .textButton {
    padding: 0;
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 32px;
    color: #c1c5d6;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #1a1a1a;
    }
  }
}
