@use '../../../../variables' as var;

@keyframes scaleBg {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.3);
  }
  50% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.NavButton {
  position: relative;
  width: 100%;
  .iconContainerBackground {
    height: 100%;
    width: 100%;
    background-color: var.$middle-gray-color;
    border-radius: 10px;
    transition: all;
    display: none;
  }
  .hoverImg {
    display: none;
    position: absolute;
  }
  &:hover {
    &:not(.active) {
      .mainImg {
        display: none;
      }
      .iconContainerBackground {
        display: block;
        animation: scaleBg 0.1s ease-in-out;
      }
      .hoverImg {
        display: block;
      }
      .iconTitle {
        color: #000;
      }
    }
  }

  &.hasScroll {
    a {
      span {
        visibility: visible;
      }
    }

    .iconContainer {
      display: none;
    }

    a {
      span {
        background: none;
        color: #000;
        background-clip: initial;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
      }
    }
  }

  &.active {
    a {
      span {
        background: linear-gradient(var.$linear-grandiend-default);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      margin-bottom: 5px;
    }

    span {
      font-family: 'Gilroy-Bold';
      font-size: 12px;
      line-height: 20px;
      color: transparent;
    }
  }
}
