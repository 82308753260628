.noData {
  margin-top: 14px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 21px;
  color: #c1c5d6;
}

.assessments {
  margin-top: 20px;
  height: 215px;
  position: relative;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    .refreshButtonContainer {
      position: absolute;
      left: 153px;
      z-index: 2;
    }
  }

  .title {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;
    color: #242833;
  }

  .amount {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    font-family: Gilroy-SemiBold;
    text-align: center;
    color: black;
    font-size: 14px;
    line-height: 20px;
    background: #00e4ac;
    border-radius: 16px;
  }

  .assessmentList {
    height: 140px;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .options {
    position: absolute;
    top: 2px;
    right: 0;
    left: 200px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 20px;
    color: #b1b6ca;
    ba & > * {
      margin: 0;
    }

    .optionScore {
      grid-area: 1 / 2;
    }

    .optionStatus {
      grid-area: 1 / 3;
    }

    .optionDate {
      grid-area: 1 / 4;
    }

    .optionReport {
      grid-area: 1 / 5;
    }
  }

  .assessment {
    width: 1012px;
    height: 60px;
    padding-left: 15px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 2.35fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    background-color: #f2f4f8;
    border-radius: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    & > * {
      margin: 0;
    }

    .name {
      justify-self: flex-start;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;
      color: black;
    }

    .score {
      margin-right: 25px;
      padding: 8px 11px;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 17px;
      border-radius: 16px;

      &.red {
        color: white;
        background-color: #ff2d55;
      }
    }

    .status,
    .date {
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 17px;
    }

    .date {
      margin-left: 10px;
    }

    .report {
      width: 24px;
      height: 24px;
      margin-left: 20px;
      background: no-repeat center
        url('../../../../../assets/images/dashboard/candidate/assessment/report.svg');
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .noData {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 17px;
  }

  .assessments {
    height: unset;
    margin-bottom: 40px;
    .container {
      gap: 15px;

      .refreshButtonContainer {
        position: static;
        order: 3;
        margin-left: auto;
      }
    }

    .title {
      font-size: 16px;
      line-height: 20px;
    }

    .amount {
      width: 30px;
      height: 20px;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
    }

    .assessmentList {
      height: unset;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
    }

    .options {
      display: none;
    }

    .assessment {
      margin-bottom: 5px;
      background-color: #f2f4f8;

      .name {
        font-size: 14px;
        line-height: 20px;
      }

      .score {
        font-size: 14px;
        line-height: 20px;
      }

      .status,
      .date {
        font-size: 14px;
        line-height: 20px;
      }

      .date {
        font-size: 14px;
        line-height: 20px;
      }

      font-size: 14px;
      line-height: 20px;
    }
  }
}
