.NewPostOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .visiable {
    z-index: 1;
    position: relative;
  }
  .buttonHoverBackground {
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00e4ac;
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    border-radius: 50%;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 175px;
    width: 175px;
    height: 160px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    &:hover {
      .buttonHoverBackground {
        transform: scale(1);
        border-radius: 16px;
      }
    }

    &.active {
      background-color: #00e4ac;

      p {
        color: #242833;
      }
    }

    p {
      width: 100px;
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #c1c5d6;
      overflow-wrap: break-word;
      z-index: 1;
      &.prefilled {
        font-family: 'Gilroy-Regular';
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .NewPostOptions {
    flex-wrap: nowrap;
    margin-bottom: -40px;
    margin-top: -40px;
    padding-top: 40px;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 40px;
    scrollbar-width: none;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    .button {
      &.active {
        p {
        }
      }

      p {
        &.prefilled {
        }
      }
    }
  }
}
