.PostStats {
  margin-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;

  &.insideModal,
  &.collapsed {
    padding-left: 0;
    padding-right: 0;
  }

  &.collapsed {
    padding-top: 0;
    padding-bottom: 19px;
    margin-bottom: 0;
    .container {
      padding-top: 10px;
      border-top: none;
    }

    .reactions {
      display: none;
    }
  }

  .container {
    position: relative;
    display: flex;
    gap: 20px;
    padding-top: 15px;
    border-top: 0.5px solid #c1c5d6;

    .stat {
      position: relative;
      display: flex;
      align-items: center;
      gap: 6px;

      .statImg {
        width: 16px;
        height: 16px;
      }

      & > span {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        color: #c1c5d6;
      }
    }
  }

  .reactions {
    position: absolute;
    top: calc(100% + 30px);

    .child {
      margin-left: 10px;
      font-family: 'Gilroy-Regular';
      white-space: nowrap;
      font-size: 14px;
      color: #0fc7e6;
    }
  }
}
