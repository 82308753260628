.StyleSelector {
  display: flex;
  gap: 15px;

  .button {
    padding: 0;
    font-family: Gilroy-SemiBold;
    color: #c1c5d6;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    background-color: transparent;
    border: none;

    &.active {
      color: #00e4ac;
    }
  }
}

@media (max-width: 1024px) {
  .StyleSelector {
    display: flex;
    gap: 15px;

    .button {
      padding: 0;
      font-family: Gilroy-Bold;
      color: #c1c5d6;
      font-size: 22px;
      line-height: 150%;

      &.active {
        color: #00e4ac;
      }
    }
  }
}
