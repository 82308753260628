.InviteCandidateButton {
  position: relative;
  width: 69px;
  height: 26px;
  font-family: 'Gilroy-Medium';
  font-size: 12px;
  color: white;
  background-color: #242833;
  border-radius: 16px;
  border: none;

  span {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    opacity: 0.3;
    border-radius: 16px;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      transform: scale(1);
    }
  }
}
