.MobileGallery {
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;

  .container {
    position: relative;
    width: 100%;
    display: flex;
    overflow-x: hidden;

    .button {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      left: 18px;
      padding: 0;
      background-color: transparent;
      border: none;

      &:last-child {
        left: auto;
        right: 18px;
      }
    }

    .images {
      display: flex;
      transition: transform 0.2s ease-in-out;
    }
  }
}
