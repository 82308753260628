.Thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;

  tr {
    //   display: flex;
    flex: 1;
  }

  th {
    position: relative;
    background: white;
    //  display: flex;
    // align-items: center;
    // justify-content: space-between;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background: #ebfffa;
    }

    &:last-child::after {
      content: '';
      top: -10px;
      left: 1px;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: white;
    }

    .thContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .sortContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .sortUp,
    .sortDown {
      width: 10px;
      height: 6px;
    }

    .sortUp {
      background: url('../../../../../../assets/images/dashboard/reports/table/sort-up.svg');

      &.sortActive {
        margin: 0;
        background: url('../../../../../../assets/images/dashboard/reports/table/sort-up-active.svg');
      }
    }

    .sortDown {
      background: url('../../../../../../assets/images/dashboard/reports/table/sort-down.svg');

      &.sortActive {
        background: url('../../../../../../assets/images/dashboard/reports/table/sort-up-active.svg');
        transform-origin: 5.5px 3px;
        transform: rotate(180deg);
      }
    }
  }
}
