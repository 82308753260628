.Announcement {
  display: flex;
  flex-direction: column;
  color: #242833;
  text-decoration: none;

  &:hover {
    color: #242833;
  }

  .title {
    margin-bottom: 5px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 20px;
    word-wrap: break-word;
    cursor: pointer;
  }

  .about {
    margin-bottom: 5px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;
  }

  .date {
    margin-top: 4px;
    font-family: 'Gilroy-Regular';
    font-size: 12px;
    line-height: 15px;
    color: #c1c5d6;
  }
}
