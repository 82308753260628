.Links {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (max-width: 1024px) {
  .Links {
    gap: 20px;
  }
}
