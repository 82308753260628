@use '../../../variables' as *;

.Discover {
  display: flex;
  width: calc(100vw - 80px);
  height: calc(100vh - 80px);
  overflow: hidden;
  scrollbar-color: #00e4ac transparent;

  .rightCol {
    // margin-left: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 342px;
    padding: 15px;
    padding-top: 20px;
    background-color: white;
    box-shadow: 0px -4px 60px rgba(78, 79, 114, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    margin-left: 7.5px;
    // border: 1px solid red;
  }

  .leftCol {
    flex: 1;
    padding-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    //  border: 1px solid red;

    .leftColContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 15px;
      padding-left: 15px;
      // padding-right: 15px;
      padding-right: 7.5px;
    }
  }
}

.quote {
  position: relative;
  padding-top: 22px;
  padding-bottom: 15px;
  padding-left: 33px;
  padding-right: 33px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: #242833;
  background-color: #f2f4f8;
  border-radius: 16px;

  &::before {
    content: '‘';
    position: absolute;
    top: -38px;
    left: 16px;
    font-family: 'Gilroy-Medium';
    font-size: 63px;
    line-height: 150%;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    transform: rotate(-180deg);
  }
}

@media (max-width: $breakpoint) {
  .Discover {
    flex-direction: column;
    width: unset;
    height: unset;
    overflow: unset;
    background-color: #f2f4f8;

    .rightCol {
      width: 100%;
      margin-left: 0;
      padding-top: 30px;
      background-color: transparent;
      box-shadow: none;
    }

    /*  .leftCol {
      .leftColContainer {
      }
    } */
  }

  /* .quote {
    &::before {
    }
  } */
}
