.AddOrEditJobTemplate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .flexContainer {
    display: flex;
    gap: 70px;
    padding-bottom: 34px;
  }

  .status {
    position: absolute;
    top: 47px;
    right: 56px;
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
      white-space: nowrap;
    }
  }

  .fillTemplateButton {
    width: 200px;
    min-height: 52px;
    align-self: center;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: white;
    background-color: #242833;
    border-radius: 16px;
    border: none;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 58px;
    flex: 1;
  }
}
