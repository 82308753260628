.SubMenu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translateX(calc(100% + 13px));

  &.onTop {
    transform: translate(calc(100% + 13px), -100%);
  }

  ul {
    display: block;
    min-width: 154px;
    margin: 0;
    padding: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: white;
    border-radius: 0px 16px 16px 0px;
    filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));

    .option {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      font-family: 'Gilroy-Medium';
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      color: #242833;
      white-space: nowrap;
      cursor: pointer;

      &.selected {
        font-family: 'Gilroy-SemiBold';
        background-image: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        color: white;
      }

      &:hover {
        font-family: 'Gilroy-SemiBold';
        background-color: #f2f4f8;
      }
    }
  }
}
