.BrowserPreview {
  position: relative;
  width: 291px;
  height: 60px;
  background-color: #fdf2e4;
  border-radius: 6px;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);

  &.active {
    border: 2px solid transparent;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
  }

  .container {
    width: 100%;
    height: 100%;
    background-image: url(../../../../../../../assets/images/dashboard/brand-kit/browser.png);
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 6px;
  }

  img {
    position: absolute;
    top: 4px;
    left: 14px;
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  h4 {
    position: absolute;
    top: 3px;
    left: 34px;
    width: 130px;
    margin: 0;
    font-size: 10px;
    line-height: 16px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 5%;
      height: 100%;
      background-color: white;
      opacity: 0.7;
    }
  }
}
