.Details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .buttons {
    padding-left: 20px;
    padding-right: 20px;

    .container {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
      padding-bottom: 15px;
      border-top: 2px solid #c1c5d6;
    }
  }
}

@media (max-width: 1024px) {
  .Details {
    .buttons {
      .container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;

        button {
          width: 100% !important;
          border-radius: 16px;
        }
      }
    }
  }
}
