.Attachment {
  display: flex;
  align-items: center;

  img {
    width: 21px;
    margin-right: 14px;
  }

  h4 {
    margin: 0;
    margin-right: 10px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 150%;
    color: #242833;
  }

  .links {
    display: flex;
    align-items: center;
    gap: 10px;
    span,
    a {
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 150%;
      color: #c1c5d6;
      text-decoration: none;
    }
  }
}
