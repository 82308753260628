.UserTag {
  height: 36px;
  padding: 13px 11px;
  font-family: 'Gilroy-Medium';
  display: flex;
  align-items: center;
  gap: 9px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
  background: rgba(0, 228, 172, 0.5);
  border-radius: 7px;

  .remove {
    height: 18px;
    width: 18px;
    background: url('../../../../../../../../assets/images/dashboard/discover/comments/remove.svg');
    cursor: pointer;
  }
}