.AvailableAssessments {
  padding: 0 22px 0 25px;
  flex: 1 1;

  .assessmentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Gilroy-SemiBold';

      h2 {
        font-size: 22px;
      }

      .amount {
        width: 48px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #c1c5d6;
        border-radius: 16px;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;

        &.green {
          background-color: #00e4ac;
        }
      }
    }

    .selectAll {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      cursor: pointer;

      &.hide {
        display: none;
      }
    }
  }

  .search {
    margin-top: 18px;
  }

  .content {
    margin-top: 24px;
    padding-bottom: 10px;
    height: 350px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }
}
