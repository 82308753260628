.SaveResetButtons {
  display: flex;
  align-items: center;
  gap: 20px;

  .resetButton {
    &:disabled {
      text-align: center;
      font-family: Gilroy-SemiBold;
      color: #c1c5d6;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      background-color: #f2f4f8;
      border: none;
    }
  }
}

@media (max-width: 1024px) {
  .SaveResetButtons {
    flex-direction: column;
    gap: 5px;
  }
}
