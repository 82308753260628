.Jobs {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  border-top: 1px solid #c1c5d6;

  &.noBorderTop {
    border-top: none;
    padding-top: 0;
  }

  .title {
    margin-bottom: 10px;
  }

  .jobs {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  .noMatch {
    font-family: 'Gilroy-Medium';
    color: #c1c5d6;
  }

  .seeAll {
    font-family: Gilroy-Bold;
    font-size: 14px;
    line-height: 24px;
    color: #00e4ac;
    text-decoration: none;
    cursor: pointer;
  }
}
