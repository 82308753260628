.MemberCard {
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover .imageContainer::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 15px;
    background: linear-gradient(222.81deg, #00E4AC -4.27%, #10C5E9 61.98%, #9240DC 113.72%);
  }

  .imageContainer {
    position: relative;
    width: 40px;
    height: 40px;

    img {
      position: relative;
      z-index: 1;
      width: 40px;
      height: 40px;
      border-radius: 14px;
      object-fit: cover;
    }

    .noPhoto {
      position: relative;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      font-size: 18px;
      color: white;
      border-radius: 14px;
    }
  }
  

  span {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 24px;
  }
}
