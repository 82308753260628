.MediaUploader {
  position: relative;

  h1 {
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #242833;
  }

  .addIcon,
  .removeIcon {
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }

  .removeIcon {
    background-color: #ff2d55;
  }

  .addIcon {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
  }

  .mediaPreview {
    width: 225px;
    height: 220px;
    object-fit: cover;
    border-radius: 20px;
  }

  .uploader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 220px;
    background-color: rgba(242, 244, 248, 0.5);
    border-radius: 20px;
    border: 1px dashed #000000;
    cursor: pointer;

    input {
      display: none;
    }

    span {
      display: inline-block;
      width: 100px;
      font-family: 'Gilroy-Regular';
      text-align: center;
      color: #8b8e95;
    }
  }
}
