.ReviewJobForm {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;

  .stepContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding-left: 15px;
    padding-right: 60px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 1' stroke='%2300E4AC' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 15px;
    box-shadow: 0px -4px 60px 0px rgba(78, 79, 114, 0.1);
    border-radius: 16px;
    cursor: pointer;

    &.active {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.921875 8L6.93096 2L12.94 8' stroke='%23242833' stroke-width='2'/%3E%3C/svg%3E%0A");
    }

    .refreshButtonContainer {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    span {
      display: inline-block;
      margin-bottom: 3px;
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 24px;
      color: #242833;
    }

    h1 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
    }
  }
}

@media (max-width: 1024px) {
  .ReviewJobForm {
    gap: 20px;

    .step {
      padding-right: 48px;
    }
  }
}
