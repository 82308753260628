.RightPanel {
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: calc(50% - 16px);
}

@media (max-width: 1024px) {
  .RightPanel {
    width: unset;
    height: unset;
    gap: 21px;
  }
}
