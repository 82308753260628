.InterviewInfo {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 20px;
  color: #242833;

  &.orange {
    color: #ffbf00;
  }

  &.green {
    color: #00e4ac;
  }

  &.red {
    color: #ff2d55;
  }
}
