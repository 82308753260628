.QuestionsHeader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 103px;
  padding-top: 27px;
  padding-left: 62px;
  padding-right: 56px;
  background-color: white;

  .questionCount {
    display: flex;
    align-items: center;
    gap: 20px;

    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
      background-color: #f2f4f8;
      border-radius: 8px;
    }

    span {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 11px;

    h1 {
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-size: 36px;
      line-height: 100%;
      color: #242833;
    }

    i {
      display: block;
      width: 30px;
      height: 30px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3169 1.87785C15.2277 2.78871 15.2277 4.26551 14.3169 5.17638L4.68152 14.8117C4.56096 14.9323 4.39744 15 4.22695 15H0.642857C0.287817 15 0 14.7122 0 14.3571V10.7731C0 10.6026 0.0677294 10.439 0.188288 10.3185L9.82362 0.683148C10.7345 -0.227716 12.2113 -0.227716 13.1222 0.683148L14.3169 1.87785ZM13.4077 4.26724C13.8165 3.85848 13.8165 3.19574 13.4077 2.78698L12.213 1.59229C11.8043 1.18352 11.1415 1.18352 10.7328 1.59229L8.97488 3.35017L11.6498 6.02512L13.4077 4.26724ZM10.7407 6.93426L8.06574 4.25931L1.28571 11.0393V13.7143H3.96067L10.7407 6.93426Z' fill='%23242833'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: #c1c5d6;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
