.candidates {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 15px;
  }

  .noMatch {
    font-family: 'Gilroy-Medium';
    color: #c1c5d6;
  }

  .candidates {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  .seeAll {
    font-family: Gilroy-Bold;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #00e4ac;
    cursor: pointer;
  }
}
