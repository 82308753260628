.CandidatesListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 25px;
    color: #242833;
  }

  .totalCount {
    display: flex;
    gap: 5px;

    .heading {
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 20px;
      color: #707070;
    }

    span {
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
    }
  }

  .addCandidateButton {
    width: 140px;
    height: 45px;
    font-family: Gilroy-SemiBold;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: white;
    background-image: linear-gradient(
      228deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    border: none;
    border-radius: 16px;
  }
}
