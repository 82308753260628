@import '../../../mixins';

.SearchScreen {
  height: calc(100vh - 80px);
  padding-top: 38px;
  padding-left: 40px;
  padding-right: 25px;
  padding-bottom: 49px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: overlay;

  @include onMobile {
    height: unset;
    padding-top: 43px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 51px;
    margin-right: 0;
    background-color: #f2f4f8;
  }

  .search {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 478px;
    min-height: 60px;
    margin-bottom: 40px;
    padding-left: 54px;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z' stroke='url(%23paint0_linear_0_1)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19 19.0004L14.65 14.6504' stroke='url(%23paint1_linear_0_1)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_0_1' x1='9' y1='-8.01297' x2='-9.02594' y2='7.98703' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_0_1' x1='16.825' y1='12.2' x2='11.9242' y2='16.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 23px center;
    border-radius: 16px;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);

    @include onMobile {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-left: 50px;
      background-position: 20px center;
    }

    .searchHistory {
      position: absolute;
      left: 0;
      top: calc(100% + 8px);
      z-index: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      margin: 0;
      padding: 0;
      padding-top: 15px;
      padding-bottom: 29px;
      list-style: none;
      background-color: white;
      border-radius: 16px;
      filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));

      li {
        padding-left: 45px;
        padding-right: 10px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0001 19.75C16.3849 19.75 20.7501 15.3848 20.7501 10C20.7501 4.61522 16.3849 0.25 11.0001 0.25C5.61532 0.25 1.2501 4.61522 1.2501 10C1.2501 12.3554 2.09097 14.5746 3.56702 16.3107H1.31812C0.903902 16.3107 0.568115 16.6464 0.568115 17.0607C0.568115 17.4749 0.903901 17.8107 1.31812 17.8107L5.56076 17.8107C5.97497 17.8107 6.31076 17.4749 6.31076 17.0607V12.818C6.31076 12.4038 5.97497 12.068 5.56076 12.068C5.14654 12.068 4.81076 12.4038 4.81076 12.818V15.4556C3.49915 13.9698 2.7501 12.0466 2.7501 10C2.7501 5.44365 6.44375 1.75 11.0001 1.75C15.5564 1.75 19.2501 5.44365 19.2501 10C19.2501 14.5563 15.5564 18.25 11.0001 18.25C10.5859 18.25 10.2501 18.5858 10.2501 19C10.2501 19.4142 10.5859 19.75 11.0001 19.75ZM11.0001 5.05072C11.4143 5.05072 11.7501 5.3865 11.7501 5.80072V10.6893L14.1858 13.1251C14.4787 13.418 14.4787 13.8929 14.1858 14.1858C13.893 14.4786 13.4181 14.4786 13.1252 14.1858L10.4698 11.5303C10.3291 11.3897 10.2501 11.1989 10.2501 11V5.80072C10.2501 5.3865 10.5859 5.05072 11.0001 5.05072Z' fill='%2300E4AC'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: left 10px center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }

    .inputContainer {
      @include onMobile {
        flex: 1;
        position: relative;
        flex-grow: 1; // Allows the inputContainer to expand to fill available space, but can be adjusted
        flex-shrink: 1; // Ensures that the container can shrink if necessary
        flex-basis: auto; // Sets the default size of the element before remaining space is distributed
      }
    }

    .selectorContainer {
      @include onMobile {
        min-width: 153px;
      }
    }

    button {
      position: absolute;
      right: 160px;
      padding: 0;
      background-color: transparent;
      border: none;

      @include onMobile {
        right: 10px;
      }
    }

    input {
      // max-width: 268px;
      height: 100%;
      font-family: 'Gilroy-Medium';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
      border: none;

      @include onMobile {
        width: 100%;
        // min-width: 0 !important;
        padding-right: 52px;
      }

      &::placeholder {
        color: #c1c5d6;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .searchText {
    margin-bottom: 42px;
    font-family: 'Gilroy-Bold';
    font-size: 30px;
    line-height: 40px;
    color: #242833;

    @include onMobile {
      margin-bottom: 40px;
      font-family: Gilroy-Bold;
      font-size: 40px;
      line-height: 40px;
    }
  }
}
