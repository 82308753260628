.jobCard {
  position: relative;
  max-width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-decoration: none;
  cursor: pointer;

  .container {
    font-family: 'Gilroy-Bold';
    line-height: 24px;
  }

  .name {
    margin-right: 5px;
    word-break: break-word;
  }

  .jobId {
    margin-right: 5px;
    font-family: 'Gilroy-Regular';
  }

  .jobStatus {
    min-width: 65px;
    padding: 0 10px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    background: #00e4ac;
    color: white;
    border-radius: 6px;
  }

  .inactive {
    background: #c1c5d6;
  }

  .onHold {
    background: #ffbf00;
  }

  .alert {
    background: #ff2d55;
  }

  .location,
  .person {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 24px;

    color: #c1c5d6;
  }

  .location {
    position: relative;
    margin-right: 5px;

    &::before {
      content: '';
      position: absolute;
      top: 45%;
      right: -16px;
      width: 4px;
      height: 4px;
      background: #c1c5d6;
      border-radius: 50%;
    }
  }

  .person {
    margin-left: 24px;
  }

  .settings {
    position: absolute;
    top: 0;
    right: -70px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
