.Insights {
  padding: 30px 18px;
  display: flex;
  gap: 25px;
  overflow-y: auto;

  .title {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
  }

  .graphs {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .topGraphs {
      display: flex;
      gap: 11px;
    }
  }

  .metrics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .timeline {
    padding: 15px;
    flex-grow: 1;
    height: 710px;
    background: #ffffff;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .title {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 20px;
    }

    .timelineContainer {
      overflow: auto;
    }
  }
}

@media (max-width: 1024px) {
  .Insights {
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    height: unset;

    .graphs {
      .topGraphs {
        flex-direction: column;
        gap: 20px;
      }
    }

    .metrics {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 18px;
    }

    .timeline {
      height: unset;
    }
  }
}
