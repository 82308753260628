.Dots {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  gap: 10px;

  .dot {
    width: 6px;
    height: 6px;
    background-color: #d9d9d9;
    opacity: 0.3;
    border-radius: 50%;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }
}
