.ColorsPreview {
  display: flex;
  gap: 17px;
  padding-right: 22px;
}

@media (max-width: 1024px) {
  .ColorsPreview {
    gap: 10px;
    width: 100%;
    padding-right: 0;
  }
}
