.eventNotification {
  display: flex;
  align-items: center;
  gap: 42px;
  
  div:first-child {
    width: 270px;
    display: flex;
    justify-content: space-between;
    
    .title {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 160%;
    }
  }


  .onNotification, .offNotification {
    margin-left: 50px;
    margin-right: 19px;
    position: relative;
    width: 54px;
    height: 30px;
    background: #00E4AC;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border-radius: 50%;
    }
  }

  .offNotification {
    background: #C1C5D6;

    &::after {
      left: 5px;
    }
  }

  .selectOption {
    display: flex;
    align-items: center;
    gap: 10px;
  
    .selected, .noSelected {
      position: relative;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 5px;
      background-color: #00E4AC;
      transition: background-color 0.3s;
      cursor: pointer;
    }

    .selected {
      &::after {
        content: '\2714';
        font-size: 16px;
      }
    }
    
    .noSelected {
      background-color: #C1C5D6;
      &::after {
        content: none;
      }
    }

    .option {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
    }
  }

  .noActive {
    color: #C1C5D6;
    pointer-events: none;

    .selected {
      background: #C1C5D6;
    }
  }
}