.SuggestedColors {
  display: flex;
  flex-direction: column;
  gap: 21px;
  height: 392px;
  padding-top: 17px;
  padding-bottom: 21px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: white;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
  border-radius: 16px;
}
