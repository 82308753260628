.CopyJobLinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 167px;
  height: 45px;
  font-family: Gilroy-Bold;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #242833;
  background-color: white;
  border-radius: 16px;
  border: none;
}
