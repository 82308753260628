.Tab {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &.active {
    h4 {
      color: #242833;
    }

    .count {
      background-color: #00e4ac;
    }
  }

  h4 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 29px;
    color: #c1c5d6;
  }

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 28px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    color: white;
    background-color: #c1c5d6;
    border-radius: 16px;
  }
}
