@use '../../../../../../variables' as *;

.Header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 51px;

  .avatarWrapper {
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 16px;
  }

  .avatarAnimation {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    &:hover {
      transform: scale(1.2);
    }
  }

  &.insideModal {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }

  .rightBlock {
    display: flex;
    gap: 10px;

    .collapseButton {
      position: relative;
      top: 2px;
      height: 21px;
      padding: 0;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 150%;
      color: #c1c5d6;
      background-color: transparent;
      border: none;
    }

    > div {
      align-self: flex-start;
    }
  }

  .leftBlock {
    display: flex;
    align-items: center;
    gap: 15px;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 14px;
      overflow: hidden;
    }

    .systemAvatar {
      width: 50px;
      height: 50px;
      background-size: 50px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #f4f4f4;
      border-radius: 14px;

      &.kfc {
        background-image: url(../../../../../../assets/images/logo-small.png);
      }

      &.zaphire {
        background-image: url(../../../../../../assets/images/zaphireLogo.svg);
        background-size: 20px;
      }
    }

    .noAvatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      font-family: 'Gilroy-SemiBold';
      font-size: 32px;
      line-height: 32px;
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 14px;
    }

    .postInfo {
      .createdAt {
        display: flex;
        align-items: center;
        gap: 9px;
        font-family: 'Gilroy-Regular';
        font-size: 12px;
        line-height: 20px;
        color: #c1c5d6;
      }

      .authorName {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 3.5px;
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 20px;
        color: #242833;

        span {
          font-family: 'Gilroy-Regular';
          font-size: 14px;
          line-height: 20px;
          color: #c1c5d6;

          &.arrow {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.425 16.5984L12.8583 11.1651C13.5 10.5234 13.5 9.47344 12.8583 8.83177L7.425 3.39844' stroke='%23C1C5D6' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  .Header {
    padding-left: 12px;
    padding-right: 12px;

    .leftBlock {
      gap: 6px;
      img {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 8px;
      }

      .systemAvatar {
        width: 40px;
        min-width: 40px;
        height: 40px;
        background-size: 30px;
        border-radius: 8px;
      }

      .noAvatar {
        width: 40px;
        min-width: 40px;
        height: 40px;
        font-size: 22px;
        border-radius: 8px;
      }

      .postInfo {
        .authorName {
          gap: 6px;
          span {
            &.arrow {
              width: 15px;
              height: 15px;
              min-width: 15px;
              min-height: 15px;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}
