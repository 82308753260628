.headerPreview {
  position: relative;
  width: 292px;
  height: 49px;
  border-radius: 6px;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);

  &.active {
    border: 2px solid transparent;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 40px;
    border-radius: 6px;
  }

  img {
    height: 12px;
    object-fit: contain;
  }

  p {
    margin: 0;
    padding-top: 5px;
    font-size: 12px;
    text-decoration: underline;
  }
}

@media (max-width: 1024px) {
  .headerPreview {
    width: 100%;
  }
}
