.Logo {
  padding-top: 28px;
  padding-left: 25px;
  padding-right: 19px;
  padding-bottom: 28px;

  .header {
    display: flex;
    gap: 64px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;

    .saveButtonsContainer {
      padding-top: 16px;
    }
  }

  .images {
    display: flex;
    flex-direction: column;
    gap: 77px;
  }
}

@media (max-width: 1024px) {
  .Logo {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
    .header {
      margin-bottom: 29px;

      .saveButtonsContainer {
        padding-top: 0;
      }
    }

    .images {
      gap: 40px;
    }
  }
}
