.PostOptions {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 60px;

  h2 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Gilroy-Bold';
    font-size: 24px;
    line-height: 36px;
    color: #242833;
  }

  .radios {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 1024px) {
  .PostOptions {
    padding-right: 0;
    padding-left: 0;
  }
}
