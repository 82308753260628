.User {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: white;
  background: linear-gradient(
    228.41deg,
    #00e4ac 0%,
    #10c5e9 53.88%,
    #9240dc 106.71%
  );
  border-radius: 14px;
  cursor: pointer;

  .photo {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 14px;
  }

  i {
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #ff2d55;
    border-radius: 50%;
    cursor: pointer;
  }
}
