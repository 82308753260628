.StatsList {
  display: flex;
  gap: 28px;
  width: 100%;
}

@media (max-width: 1024px) {
  .StatsList {
    gap: 15px;

    &::after {
      content: '';
      display: block;
      min-width: 0.1px;
      min-height: 100%;
    }
  }
}
