@use '../../../../../../variables' as *;

.Reactions {
  position: absolute;
  right: 0;
  bottom: -6px;
  display: flex;
  align-items: center;

  .reaction {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #f2f4f8;
    border-radius: 7px;

    cursor: pointer;

    &.selected {
      background: #ffffff;
      box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    }
  }

  .child {
    position: absolute;
    left: 100%;
  }
}

@media (max-width: $breakpoint) {
  .Reactions {
    bottom: -10px;

    .reaction {
      width: 25px;
      height: 25px;
    }
  }
}
