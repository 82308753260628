.UserWithAvatar {
  display: flex;
  align-items: center;
  gap: 10px;

  .avatar {
    border-radius: 50%;
  }

  .name {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
  }
}
