.Notification {
  position: relative;
  padding-top: 18px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 18px;
  background-color: rgba(193, 197, 214, 0.1);
  cursor: pointer;

  &.read {
    background-color: white;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 40px;
    bottom: -1.5px;
    display: block;
    width: calc(100% - 80px);
    height: 1.5px;
    background-color: #d9d9d9;
  }

  .icon {
    position: absolute;
    right: 36px;
    top: calc(50% - 10px);
    display: block;
    width: 20px;
    height: 20px;
    background-image: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    border-radius: 50%;

    &.delete {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.94628 3.39055L6.44953 0.887294C6.63259 0.704235 6.92939 0.704235 7.11245 0.887294C7.2955 1.07035 7.2955 1.36715 7.11245 1.55021L4.60919 4.05346L7.11245 6.55671C7.2955 6.73977 7.2955 7.03657 7.11245 7.21962C6.92939 7.40268 6.63259 7.40268 6.44953 7.21962L3.94628 4.71637L1.44303 7.21962C1.25997 7.40268 0.963174 7.40268 0.780116 7.21962C0.597058 7.03657 0.597058 6.73977 0.780116 6.55671L3.28337 4.05346L0.780116 1.55021C0.597058 1.36715 0.597058 1.07035 0.780116 0.887294C0.963174 0.704236 1.25997 0.704236 1.44303 0.887294L3.94628 3.39055Z' fill='%23242833'/%3E%3C/svg%3E%0A");
      background-color: rgba(36, 40, 51, 0.3);
    }
  }

  h4 {
    margin: 0;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
  }

  .content {
    padding-right: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    .detailsContainer {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .details {
        max-width: 100%;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
      }
    }

    .text {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
    }

    .timeAgo {
      color: #c1c5d6;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
    margin-top: 15px;
    button {
      min-width: 112px;
      height: 30px;
      padding-left: 23px;
      padding-right: 23px;
      font-family: 'Gilroy-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: white;
      border-radius: 10px;
      border: none;

      &.mainButton {
        background-color: #00e4ac;
      }

      &.secondaryButton {
        background-color: #242833;
      }

      &.snoozeButton {
        background-color: #c1c5d6;
      }
    }
  }
}
