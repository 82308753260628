.dialog {
  max-width: 815px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.ScheduleInterviewModal {
  border-radius: 20px;

  .content {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-left: 44px;
    padding-right: 44px;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 40px;
        line-height: 40px;
        color: #242833;
      }

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close-dark.svg);
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 35px;
      flex: 1;

      .row {
        display: flex;
        gap: 27px;

        .onboardingModeSelector {
          display: flex;
          align-items: center;
          gap: 60px;

          h2 {
            margin: 0;
            font-family: 'Gilroy-Bold';
            font-size: 18px;
            line-height: 24px;
            color: #242833;
          }

          .radios {
            display: flex;
            gap: 40px;
          }
        }

        .col {
          display: flex;
          flex-direction: column;
          flex: 1;

          &.positionCol {
            max-width: 100%;
            overflow: hidden;
          }

          .selectedUsers {
            margin-top: 26px;
          }

          .dropdownTitle {
            margin-bottom: 5px;
            font-family: 'Gilroy-Bold';
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: #242833;

            .note {
              margin-left: 8px;
              font-family: 'Gilroy-Regular';
              font-size: 14px;
              line-height: 24px;
              font-weight: normal;
              color: #c1c5d6;
            }
          }

          .dropdowns {
            display: flex;
            justify-content: space-between;
          }

          .applicationInfo {
            h3 {
              margin: 0;
              font-family: 'Gilroy-Bold';
              font-size: 18px;
              line-height: 24px;
              color: #3c3c43;
            }

            h2 {
              max-width: 100%;
              display: inline-block;
              max-width: 100%;
              margin: 0;
              font-family: 'Gilroy-SemiBold';
              font-size: 35px;
              line-height: 36px;
              color: #242833;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .status {
              display: flex;
              gap: 11px;
              flex-direction: column;

              .statusText {
                margin-top: 6px;
                padding: 0 15px;
                width: max-content;
                height: 28px;
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                font-family: 'Gilroy-Regular';
                font-size: 14px;
                line-height: 20px;
                color: white;
                background: #00e4ac;
                border-radius: 16px;
              }

              .statusDeclined {
                background-color: #ff2d55;
              }

              .changeRequest {
                background-color: #ffbf00;
              }

              .statusDate {
                font-family: 'Gilroy-SemiBold';
                font-size: 16px;
                line-height: 24px;
                color: #ffbf00;
              }
            }

            .jobId {
              font-family: 'Gilroy-Regular';
              font-size: 16px;
              line-height: 24px;
              color: #3c3c43;
            }
          }

          .linkButton {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 186px;
            height: 50px;
            margin-bottom: 41px;
            margin-left: auto;
            font-family: 'Gilroy-SemiBold';
            font-size: 16px;
            line-height: 24px;
            color: #242833;
            background-color: #f2f4f8;
            border: 1px solid #f2f4f8;
            border-radius: 16px;
          }
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 121px;
      gap: 10px;
    }
  }
}
