.month {
  align-self: flex-start;
  
  .card {
    margin-left: 7px;

    .title {
      display: block;
      position: relative;
      height: 20px;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
    }

    &:hover .popup{
      opacity: 1;
      visibility: visible;
      transition: opacity 2s step-end;
    }
    
    .popup {
      opacity: 0;
      visibility: hidden;
    }
  }


  .left {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
  }

  .canceled, .accepted, .declined, .pending, .completed  {
    .title::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: -13px;
      bottom: 5px;
      border-radius: 50%;
    }
  }

  .accepted {
    .title::after {
      background: #00E4AC;
    }
  }

  .completed {
    .title::after {
      background: #242833;
    }
  }
  
  .pending {
    .title::after {
      background: #C1C5D6;
    }
  }
  .declined {
    .title::after {
      background: #FFCC33;
    }
  }

  .canceled {
    .title::after {
      background: #FF2D55;
    }
  }
}