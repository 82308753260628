.CandidatesDropdown {
  display: flex;
  align-items: center;
  position: relative;
  width: 141px;
  height: 28px;
  padding-left: 10px;
  border-left: 2px solid #c1c5d6;

  &.open {
    height: 38px;
    border: 1.5px solid #242833;
    border-radius: 16px;
  }

  .selector {
    width: 100%;
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.39136L8.00002 8.69571L1.00002 1.39136' stroke='%2300E4AC' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 19px;
    cursor: pointer;

    &.isOpened {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999985 8.60864L7.99998 1.30429L15 8.60864' stroke='%23242833' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .options {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 29px);
    right: -10px;
    width: 146px;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: white;
    box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
    border-radius: 16px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        height: 38px;
        padding-left: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
        cursor: pointer;

        &.active {
          font-size: 16px;
          line-height: 24px;
          color: #00e4ac;
        }

        &:hover {
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #141224;
          background-color: #f2f4f8;
        }
      }
    }
  }
}

.CandidatesDropdown.mobile {
  width: 153px;
  min-width: 153px;

  .selector {
    background-size: 10px 5px;
  }
}
