@use '../../../../../../variables' as *;

.UsersPopup {
  position: relative;

  .container {
    position: absolute;
    z-index: 2;
    top: -281px;
    left: calc(50% - 198px);
    width: 396px;
    height: 281px;
    padding-top: 19px;
    padding-left: 17px;
    padding-right: 18px;
    padding-bottom: 18px;
    background-color: white;
    box-shadow: 0px 4px 4px -6px rgba(0, 0, 0, 0.25),
      0px 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 16px;

    .search {
      margin-bottom: 20px;

      input {
        width: 100%;
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        background-color: #f2f4f8;
        border-radius: 16px;
        border: none;

        &::placeholder {
          color: #c1c5d6;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .usersList {
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 177px;
      margin: 0;
      padding: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
    }
  }
}

@media (max-width: $breakpoint) {
  .UsersPopup {
    .container {
      width: 292px;
      .search {
        margin-bottom: 15px;
        input {
          height: 45px;
        }
      }
    }
  }
}
