.ImagePreview {
  display: flex;
  align-self: flex-start;
  gap: 10px;

  &:hover {
    .deleteButton {
      svg {
        transform: rotate(-180deg);
        path {
          fill: #ff2d55;

          &:first-child {
            fill: white;
          }
        }
      }
    }
  }

  &.small {
    .preview {
      max-width: 202px;
      max-height: 60px;
    }
  }

  .preview {
    width: auto;
    height: auto;
    max-width: 342px;
    max-height: 110px;
    object-fit: contain;
    overflow: hidden;
  }

  .deleteButton {
    padding: 0;
    background-color: transparent;
    border: none;

    svg {
      transition: all 0.35s ease-in-out;
      path {
        fill: #c1c5d6;
        transition: all 0.35s ease-in-out;

        &:first-child {
          fill: #242833;
        }
      }
    }

    /* 
    img {
      animation-timing-function: cubic-bezier(1, 0, 0, 1);
    }

    &.hovered {
      img {
        animation: rotate-in-with-bounce 0.8s forwards;
      }
    }

    &.notHovered {
      img {
        animation: rotate-out-with-bounce 0.8s forwards;
      }
    } */
  }

  @keyframes rotate-in-with-bounce {
    15% {
      transform: rotate(100deg);
    }

    30% {
      transform: rotate(75deg);
    }

    50% {
      transform: rotate(95deg);
    }

    80% {
      transform: rotate(85deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  @keyframes rotate-out-with-bounce {
    15% {
      transform: rotate(-100deg);
    }

    30% {
      transform: rotate(-75deg);
    }

    50% {
      transform: rotate(-95deg);
    }

    80% {
      transform: rotate(-85deg);
    }

    100% {
      transform: rotate(-90deg);
    }
  }
}
