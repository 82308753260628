.PreviewHeader {
  position: relative;

  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 17px;
      font-family: Gilroy-Bold;
      color: #242833;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px;
      text-align: center;
    }

    p {
      margin: 0;
      font-family: Gilroy-Regular;
      color: #242833;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;

      .mailLink {
        color: #00e4ac;
        text-decoration: none;
      }
    }
  }

  .publishButtonContainer {
    position: absolute;
    top: 0;
    right: 23px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 27px;
  }
}

@media (max-width: 1024px) {
  .PreviewHeader {
    .titleContainer {
      align-items: flex-start;
      padding-right: 100px;
      h2 {
        width: 100%;
        padding-left: 30px;
        text-align: left;
      }
    }

    .publishButtonContainer {
      right: 0;
    }

    .buttons {
      gap: 15px;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
