.AssessmentInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 20px;
  color: #242833;
  cursor: default;

  &.isForm {
    font-family: Gilroy-Bold;
    font-size: 18px;

    .inputContainer {
      height: 50px;
    }
  }

  &.jobTemplate {
    font-family: Gilroy-Bold;
    font-size: 18px;
    line-height: 24px;

    .inputContainer {
      height: 50px;
    }
  }

  .inputContainer {
    padding: 0 16px;
    width: 113px;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f4f8;
    border-radius: 16px;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;

    .button {
      font-size: 24px;
      font-family: 'Gilroy-Regular';
      font-weight: 700;
      cursor: pointer;
    }

    input {
      width: 50px;
      text-align: center;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }

    .red {
      color: #ff2d55;
    }

    .amber {
      color: #ffbf00;
    }
  }

  .warning {
    color: #ffbf00;
    font-family: 'Gilroy-Regular';
    font-size: 12px;

    &.red {
      color: #ff2d55;
    }
  }
}
