.Test {
  position: relative;
  left: 36px;
  display: flex;
  gap: 18px;
  flex-direction: column;

  .container {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    .checkbox {
      width: 25px;
      height: 25px;
      background-image: url('../../../../../assets/images/dashboard/reports/noChecked.svg');
      cursor: pointer;
    }

    .radio {
      cursor: pointer;

      &.circle {
        width: 20px;
        height: 20px;
        background: none;
        border: 2px solid rgba(189, 189, 189, 1);
        border-radius: 50%;
      }
    }

    span {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      color: #242833;
    }
  }

  &.active {
    .container {
      .checkbox {
        background-image: url('../../../../../assets/images/dashboard/reports/checked.svg');
      }

      .radio {
        &.circle {
          position: relative;
          width: 20px;
          height: 20px;
          background: none;
          border: 2px solid rgba(0, 228, 172, 1);
          border-radius: 50%;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            background-color: rgba(0, 228, 172, 1);
            border-radius: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      & > span {
        font-family: 'Gilroy-Bold';
        font-size: 18px;
      }
    }
  }
}
