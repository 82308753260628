.LeftPanelHeader {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 15px;
  padding-left: 31px;
  padding-right: 32px;
  padding-bottom: 25px;
  .row {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 8px;
      margin-bottom: 0;
      font-family: 'Gilroy-SemiBold';
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #242833;
    }

    .sortButton {
      width: 30px;
      height: 32px;
      margin-left: auto;
      padding: 0;
      font-size: 0;
      background-image: url(../../../../../assets/images/chat/sort-button.svg);
      border: none;
      border-radius: 38px;
    }
  }
}
