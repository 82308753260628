.Checkmark {
  .checkmark {
    width: 20px;
    height: 20px;
    background-image: url(../../../../../../../../assets/images/chat/checkmark.svg);
    border-radius: 50%;
    cursor: pointer;

    &.checked {
      background-image: url(../../../../../../../../assets/images/chat/checkmark-green.svg);
      cursor: auto;
    }
  }
}
