.Assessment {
  position: relative;
  display: flex;
  gap: 18px;
  flex-direction: column;

  .container {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    .checkbox {
      width: 25px;
      height: 25px;
      background-image: url('../../../../assets/images/dashboard/reports/noChecked.svg');
      cursor: pointer;

      &.circle {
        width: 20px;
        height: 20px;
        background: none;
        border: 2px solid rgba(189, 189, 189, 1);
        border-radius: 50%;
      }
    }

    span {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      color: #141224;
    }

    .arrow {
      position: absolute;
      right: 21px;
      display: block;
      width: 12px;
      height: 8px;
      background: url('../../../../assets/images/dashboard/reports/filter/arrow.svg');
      transform: rotate(180deg);
      cursor: pointer;

      &.arrowUp {
        background: url('../../../../assets/images/dashboard/reports/filter/black-arrow.svg');
        transform: rotate(0);
      }
    }
  }

  &.active {
    .container {
      .checkbox {
        background-image: url('../../../../assets/images/dashboard/reports/checked.svg');
        border-radius: 0;

        &.circle {
          width: 20px;
          height: 20px;
          background: none;
          border: 2px solid rgba(0, 228, 172, 1);
          border-radius: 50%;
        }
      }

      & > span {
        font-family: 'Gilroy-Bold';
        font-size: 18px;
      }
    }
  }
}

.testInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
