.PostAJobBanner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 140px;
  padding-top: 21px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #10c5e9;
  box-shadow: 0px -4px 60px rgba(78, 79, 114, 0.1);
  border-radius: 20px;

  h2 {
    font-family: 'Gilroy-Bold';
    font-size: 32px;
    line-height: 100%;
    color: white;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 129px;
    height: 44px;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #6722d2;
    white-space: nowrap;
    background-color: white;
    box-shadow: 0px 16px 60px rgba(134, 109, 201, 0.16);
    border-radius: 15px;
    border: none;
  }
}

@media (max-width: 1024px) {
  .PostAJobBanner {
    height: 132px;
    padding-top: 33px;
    padding-left: 23px;
    padding-right: 23px;
    h2 {
      font-size: 28px;
      line-height: 20px;
    }
  }
}
