.Badge {
  height: 27px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Gilroy-Regular';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
  background-color: #c1c5d6;
  border-radius: 8.5px;
  cursor: pointer;

  &.active {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
  }
}
