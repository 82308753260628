.Details {
  .recruiter {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 15px;

    & > div {
      flex-basis: 50%;
      display: flex;
      gap: 15px;
    }

    .title {
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #3c3c43;
    }

    .description {
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #242833;
    }
  }

  .eventInfo {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 30px;

    & > div {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .headerCol {
      gap: 10px;
    }

    .name {
      font-family: Gilroy-SemiBold;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .title {
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #242833;
    }

    .jobId {
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #3c3c43;

      span {
        font-family: Gilroy-SemiBold;
        text-decoration: underline;
      }
    }

    .view {
      width: 102px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 16px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .Details {
    .recruiter {
      margin-bottom: 20px;
      & > div {
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}
