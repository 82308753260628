.DefaultAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: white;
  background: linear-gradient(
    228.41deg,
    #00e4ac 0%,
    #10c5e9 53.88%,
    #9240dc 106.71%
  );
  border-radius: 50%;
}
