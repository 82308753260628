.GridView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(329px, 1fr));
  gap: 12px;
  min-height: 446px;
  height: calc(100vh - 80px - 37px - 300px - 26px - 95px - 13px);
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00e4ac;
    border-radius: 6px;
  }
}

@media (max-width: 1024px) {
  .GridView {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    min-height: unset;
    height: unset;
    padding-right: 15px;
    padding-bottom: 17px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
