.Group {
  user-select: none;

  .listTitle {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    cursor: pointer;

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 20px;
      color: #242833;
    }

    .infoIconContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: auto;
      .arrow {
        margin-left: auto;
        margin-top: 2px;
        display: block;
        width: 12px;
        height: 8px;
        background: url('../../../../../assets/images/dashboard/reports/filter/arrow.svg');
        transform: rotate(180deg);
      }

      .arrowUp {
        background: url('../../../../../assets/images/dashboard/reports/filter/black-arrow.svg');
        transform: rotate(0);
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    padding-top: 4px;
    list-style: none;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #c1c5d6;

    li {
      height: 40px;
      padding: 8px;
      display: flex;
      gap: 12px;
      align-items: center;
      border-radius: 16px;
      cursor: pointer;

      img {
        width: 18px;
      }

      &:hover {
        color: #242833;

        img {
          filter: brightness(0%);
        }
      }

      &.active {
        font-family: 'Gilroy-SemiBold';
        background: #f2f4f8;
        color: #242833;

        img {
          filter: brightness(0%);
        }
      }
    }
  }
}
