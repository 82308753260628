$breakpoint: 1224px;

:root {
  --breakpoint: #{$breakpoint};
}

$linear-grandiend-default: 242deg,
#00e4ac 8.42%,
#10c5e9 51.73%,
#9240dc 94.19%;

$middle-gray-color: #9fa3b6;

$light-gray-color: #c1c5d6;

$main-purple: #725ee8;

$light-pink: #FF2D55;

$light-green: #00e4ac;