.Column {
  position: relative;
  padding: 0 22px 0;
  min-height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #F2F4F8;
  
  .drag {
    width: 5px;
    height: 10px;
    background-image: url('../../../../../../../assets/images/dashboard/reports/drag.svg');
    cursor: grab;
  }

  span {
    margin-left: 8px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 36px;
  }
  
  .delete {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 22px;
    background-image: url('../../../../../../../assets/images/dashboard/reports/x.svg');
    cursor: pointer;
  }
}

.mandatory {
  background-color: #F2F4F8;
  border-radius: 16px;

  .delete {
    background-image: url('../../../../../../../assets/images/dashboard/reports/lock.svg');
    cursor: auto;
  }
}