.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background-color: transparent;
    border: none;
    border-radius: 50%;

    &.green {
      svg {
        path {
          fill: rgba(0, 228, 172, 1);
        }
      }
    }

    &:active {
      background-color: black !important;
      /* background-image: url(../../../../../../assets/images/dashboard/candidate/chevron-white.svg);
      background-position: center;
      background-repeat: no-repeat;

      img {
        display: none;
      } */

      svg {
        path {
          fill: white;
        }
      }
    }

    &.hoveredOut {
      svg {
        animation: bounce-reversed 0.3s cubic-bezier(0.42, 0, 0.58, 1) both;
      }
    }

    &:last-child {
      transform: scaleX(-1);
    }

    &:hover {
      background-color: rgb(236, 237, 242);
      animation: blink-animation 0.3s cubic-bezier(0.42, 0, 0.58, 1) forwards;

      svg {
        animation: bounce 0.3s cubic-bezier(0.42, 0, 0.58, 1) both;
      }
    }
  }

  .pages {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}

@keyframes blink-animation {
  0%,
  100% {
    background-color: rgba(236, 237, 242, 1);
  }
  50% {
    background-color: rgba(236, 237, 242, 0.6);
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-3px);
  }

  80% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(-3px);
  }
}

@keyframes bounce-reversed {
  0% {
    transform: translateX(-3px);
  }

  40% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(0);
  }
}
