.Chat {
  position: fixed;
  z-index: 2;
  top: 80px;
  left: 100px;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: white;
}
