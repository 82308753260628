.dialog {
  max-width: 826px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.OfferModal {
  border-radius: 20px;

  .content {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-left: 44px;
    padding-right: 44px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 40px;
        line-height: 40px;
        color: #242833;
      }

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close-dark.svg);
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 35px;
      flex: 1;

      .row {
        display: flex;
        gap: 49px;

        .col {
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 350px;

          .docUploaderContainer {
            margin-top: 24px;
          }

          .selectedUsers {
            margin-top: 26px;
          }

          .dropdowns {
            display: flex;
            align-items: flex-end;
            gap: 20px;
          }

          .applicationInfo {
            position: relative;

            .location {
              font-family: 'Gilroy-Regular';
              font-size: 16px;
              line-height: 24px;
              color: #3c3c43;
            }

            .editIcon {
              position: absolute;
              top: 30px;
              right: 0;
              display: none;
              width: 30px;
              height: 30px;
              font-size: 0;
              background-image: url(../../assets/images/edit-icon.svg);
              cursor: pointer;
            }
            h3 {
              margin: 0;
              font-family: 'Gilroy-Bold';
              font-size: 18px;
              line-height: 24px;
              color: #3c3c43;
            }

            h2 {
              margin: 0;
              font-family: 'Gilroy-SemiBold';
              font-size: 35px;
              line-height: 36px;
              color: #242833;

              &.jobTitle {
                width: 300px;
                word-wrap: break-word;
              }
            }

            .jobId {
              font-family: 'Gilroy-Regular';
              font-size: 16px;
              line-height: 24px;
              color: #3c3c43;
            }
          }

          .linkButton {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 186px;
            height: 50px;
            margin-bottom: 41px;
            margin-left: auto;
            font-family: 'Gilroy-SemiBold';
            font-size: 16px;
            line-height: 24px;
            color: #242833;
            background-color: #f2f4f8;
            border: 1px solid #f2f4f8;
            border-radius: 16px;
          }
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 121px;
      gap: 49px;
    }
  }
}
