.CurrencyInput {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Gilroy-SemiBold';
  font-size: 35px;
  line-height: 36px;
  color: #242833;
  background-color: #f2f4f8;
  border-radius: 16px;
  border: none;

  &::placeholder {
    color: #c1c5d6;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    &:not(:read-only) {
      background-color: white;
      box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.12);
      border-radius: 16px;
    }
  }
}
