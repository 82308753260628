.Dropdown {
  position: relative;
  display: inline-flex;

  &.open {
    .selector {
      z-index: 1000;
      .arrow {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999985 8.60864L7.99998 1.30429L15 8.60864' stroke='%23242833' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &:focus {
    outline: none;

    .selector {
      box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.25);
    }
  }

  .selector {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 48px;
    padding-left: 20px;
    padding-right: 40px;
    font-size: 16px;
    line-height: 100%;
    font-family: 'Gilroy-Regular';
    color: #c1c5d6;
    background-color: white;
    border: 1px solid #c1c5d6;
    border-radius: 16px;
    cursor: pointer;

    .hasOption {
      color: #242833;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .arrow {
      position: absolute;
      right: 21px;
      width: 16px;
      height: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.39136L8.00002 8.69571L1.00002 1.39136' stroke='%2300E4AC' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .optionsContainer {
    position: absolute;
    z-index: 1002;
    top: calc(100% + 7px);
    left: 50%;
    width: max-content;
    min-width: 100%;
    height: 200px;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: white;
    box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
    border-radius: 16px;
    overflow: hidden;
    transform: translateX(-50%);

    .options {
      height: 100%;
      overflow: auto;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
  
        li {
          display: flex;
          align-items: center;
          height: 38px;
          padding-left: 20px;
          padding-right: 20px;
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 24px;
          color: #242833;
          white-space: nowrap;
          cursor: pointer;
  
          &.title {
            position: relative;
            padding-top: 6px;
            font-size: 18px !important;
            line-height: 24px !important;
          }
  
          &.active {
            font-size: 16px;
            line-height: 24px;
            color: #00e4ac;
          }
  
          &:hover {
            font-size: 18px;
            line-height: 24px;
            font-family: 'Gilroy-Bold';
            color: #141224;
            background-color: #f2f4f8;
          }
        }
      }
    }
  }
}
