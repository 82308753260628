.Slider {
  position: relative;
  height: 100%;
  padding-left: 78px;
  padding-right: 60px;

  .mediaContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 548px;
    height: 100%;
  }

  .switchButton {
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-color: rgba(36, 40, 51, 0.6);
    border-radius: 50%;
    border: none;

    &:first-child {
      left: 18px;
      transform: scale(-1, 1);
    }

    &:disabled {
      background-color: #c1c5d6;
    }
  }
}
