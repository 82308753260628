.dialog {
  max-width: 497px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 16px;
  border: none;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}

.MessageModal {
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 21px;
    padding-left: 16px;
    padding-right: 19px;
    padding-bottom: 17px;
    border-radius: 16px;

    .closeButton {
      position: absolute;
      right: 11px;
      top: 11px;
      display: flex;
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}
