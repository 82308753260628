.PasswordSignInPage {
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    flex: 1;
    width: 100%;
    height: 100%;

    form {
      display: flex;
      flex-direction: column;
      gap: 47px;
      width: 100%;
    }

    .helper {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #c1c5d6;

      a {
        text-decoration: none;
      }

      span {
        color: #00e4ac;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .PasswordSignInPage {
    height: 567px;
  }
}
