@use '../../../../../../../variables' as *;

@keyframes shake {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(60deg);
  }
  75% {
    transform: rotate(70deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes shakeReverse {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(70deg);
  }
  50% {
    transform: rotate(60deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes scaleAnimationReverse {
  0% {
    transform: scale(1.5);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.AddComment {
  position: sticky;
  bottom: 5px;
  z-index: 1;
  display: flex;
  gap: 16px;
  margin-top: 17px;

  .container {
    position: relative;
    width: calc(100% - 16px - 60px);
    padding: 12px;
    min-height: 60px;
    display: flex;
    align-items: center;
    background: #f2f4f8;
    opacity: 0.92;
    border-radius: 20px;

    .scaleTransition {
      transition: all 0.2s ease-in-out;
    }
    .mouseOverScale {
      animation: scaleAnimation 0.2s ease-in-out;
      transform: scale(1.5);
    }
    .mouseLeaveScale {
      animation: scaleAnimationReverse 0.2s ease-in-out;
      transform: scale(1);
    }

    .mouseOverUsers {
      animation: shake 0.2s ease-in-out;
      transform: rotate(90deg);
    }
    .mouseLeaveUsers {
      animation: shakeReverse 0.2s ease-in-out;
      transform: rotate(0deg);
    }
    .addUserWrapper {
      overflow: hidden;
      background: linear-gradient($linear-grandiend-default);
      border-radius: 10px;
    }

    .addUsers {
      transition: all 0.2s ease-in-out;
      flex-shrink: 0;
      align-self: flex-start;
      width: 35px;
      height: 35px;
      background: url('../../../../../../../assets/images/dashboard/discover/comments/add.svg');
      cursor: pointer;
    }

    .content {
      padding: 0px 18px 0 18px;
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;

      .userReply {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }

      .textBox {
        flex-grow: 1;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 25px;
        color: black;
        background-color: inherit;
        word-break: break-word;
        outline: none;
        border: none;
        resize: none;

        &:empty:before {
          content: 'Say something...';
          color: #b1b6ca;
        }
      }
    }

    .emodji {
      flex-shrink: 0;
      margin-top: 3px;
      width: 30px;
      height: 30px;
      align-self: flex-start;
      background: url('../../../../../../../assets/images/dashboard/discover/comments/smile.svg');
      cursor: pointer;
    }

    .charsLeft {
      position: absolute;
      bottom: calc(100% + 10px);
      right: 0;
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 20px;
      color: #c1c5d6;

      &.red {
        color: #ff2d55;
      }
    }
  }
  .sentWrapper {
    position: relative;
    cursor: pointer;
    background: #c1c5d6;
    overflow: hidden;
    border-radius: 20px;
    &:hover {
      .graybackgroundExpanded {
        transform: scale(1);
        border-radius: 10px;
      }
      .sent {
        &::after {
          left: 15px;
        }
      }
    }
  }
  .graybackgroundExpanded,
  .greenbackgroundExpanded {
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .graybackgroundExpanded {
    background: $middle-gray-color;
  }
  .greenbackgroundExpanded {
    background: $light-green;
  }
  .showGreenBg {
    transform: scale(1);
    border-radius: 10px;
  }

  .sent {
    z-index: 1;
    position: relative;
    width: 60px;
    height: 60px;
    opacity: 0.92;
    border-radius: 20px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 27px;
      transition: all 0.3s ease-in-out;
      height: 27px;
      background: url('../../../../../../../assets/images/dashboard/discover/comments/sent.svg');
    }
    &.moveArrow {
      &::after {
        left: 70px !important;
        transform: scale(1.5);
      }
    }

    &.active {
      background: #00e4ac;
      cursor: pointer;
    }
    &.moveArrowLeft {
      &::after {
        transform: scale(1);
      }
    }
  }
}

.emojiPicker {
  position: fixed;
  z-index: 3;
}

@media (max-width: $breakpoint) {
  .AddComment {
    margin-top: 30px;
  }
}
