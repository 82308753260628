.InnerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .titleContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .backButton {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  .buttonContainer {
    background-color: white;
    border-radius: 16px;

    button {
      text-transform: capitalize;
    }
  }

  .signin {
    margin: 0;
    font-family: Gilroy-SemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
  }
}
