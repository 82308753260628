.ImageUploader {
  display: flex;
  flex-direction: column;

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
    p {
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .uploadButton {
    width: 150px;
    height: 47px;
    font-family: Gilroy-SemiBold;
    color: #242833;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #f2f4f8;
    border: none;
    border-radius: 16px;
  }
}
