.PostAJob {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;

  &.formVisible {
    .footer {
      .container {
        button {
          margin-left: unset;
        }
      }
    }
  }

  .backButton {
    display: flex;
    align-self: flex-start;
    gap: 15px;
    margin-bottom: 20px;
    padding: 0;
    font-family: Gilroy-Bold;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    color: #242731;
    background-color: transparent;
    border: none;
  }

  .header {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border-radius: 16px 16px 0 0;
    .container {
      padding-bottom: 15px;
      border-bottom: 2px solid #f2f4f8;
      h1 {
        margin: 0;
        margin-bottom: 15px;
        font-family: 'Gilroy-Bold';
        font-size: 24px;
        line-height: 20px;
        color: #242833;
      }

      p {
        margin: 0;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 20px;
        color: #c1c5d6;
      }
    }
  }

  .content {
    height: calc(100vh - 80px - 20px - 45px - 87px - 78px - 30px);
    background-color: white;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background-color: #00e4ac;
      border-radius: 6px;
    }
  }

  .footer {
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border-radius: 0 0 16px 16px;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 2px solid #f2f4f8;

      button {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1024px) {
  .PostAJob {
    height: calc(100dvh - 54px - 20px - 30px);
    padding-left: 0;
    padding-right: 0;

    .content {
      flex: 1;
      height: unset;
      padding-left: 15px;
      padding-right: 15px;
      overflow-y: unset;

      &.lastStep {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .footer {
      .container {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
