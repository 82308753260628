.Header {
  height: 145px;
  .inputs {
    display: flex;
    gap: 15px;
  }
}

@media (max-width: 1024px) {
  .Header {
    height: unset;
    padding-bottom: 38px;
    .inputs {
      flex-direction: column;
      gap: 32px;
    }
  }
}
