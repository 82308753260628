.Container {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px -4px 60px 0px rgba(78, 79, 114, 0.1);
}

@media (max-width: 1024px) {
  .Container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
