.CalendarEventCard {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: calc(100% - 70px);
  cursor: pointer;

  .calendarIcon {
    width: 50px;
    height: 50px;
    padding: 4px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: center;
    background: #F2F4F8;
    border-radius: 8px;

    .month {
      font-size: 12px;
      line-height: 14px;
    }

    .date {
      width: 42px;
      height: 27px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      line-height: 21px;
      background: #FFFFFF;
      border-radius: 8px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .container {
      display: flex;
      gap: 24px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 24px;

      .time {
        position: relative;
        color: #C1C5D6;

        &::before {
          content: '';
          position: absolute;
          right: -14px;
          top: 45%;
          width: 4px;
          height: 4px;
          background: #C1C5D6;
          border-radius: 50%;
        }
      }

      .person {
        color: #242833;   
      }
    }

  }

  .settings {
    position: absolute;
    top: 0;
    right: -70px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
}