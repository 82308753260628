.days {
  position: absolute;
  width: calc(100% - 48px - 65px - 14px);
  display: flex;
  gap: 3px;

  .cardContainer {
    position: relative;
    width: 100%;

    &:hover {
      z-index: 2;
      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 9px;
      }
    }

    &:hover .popup {
      opacity: 1;
      visibility: visible;
      transition: opacity 2s step-end;
    }

    .popup {
      opacity: 0;
      visibility: hidden;
    }
  }

  .card {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 8px 12px 5px 20px;
    flex: 1;

    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 60px rgba(24, 39, 75, 0.1);
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background-color: transparent;
      border-radius: 8px 0 0 8px;
    }

    .cardInfo {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    .title {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 18px;
      color: #242833;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .person {
      margin-top: 3px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 18px;
    }

    .container {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-family: 'Gilroy-Medium';
    }

    .date {
      font-size: 12px;
      line-height: 150%;
      color: #c1c5d6;
    }

    .bold {
      font-size: 12px;
      color: #242833;
    }
  }

  .joining {
    background: #d2faff;

    .date {
      color: #242833;
    }
  }

  .onboarding {
    background: #cc9cff;

    .date {
      color: #242833;
    }
  }

  .canceled {
    &::before {
      background-color: #ff2d55;
    }
  }

  .accepted {
    &::before {
      background-color: #00e4ac;
    }
  }

  .completed {
    &::before {
      background-color: #242833;
    }
  }

  .pending {
    &::before {
      background-color: #c1c5d6;
    }
  }
  .declined {
    &::before {
      background-color: #ffcc33;
    }
  }

  .selected {
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 9px;
    }
  }
}
