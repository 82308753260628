.graph {
  position: relative;
  margin: 46px 31px;
  height: 254px;
  width: calc(100% - 25px);
  // border: 1px solid red;

  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: 'Gilroy-SemiBold';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #c1c5d6;
  }
}

.percents {
  position: absolute;
  width: 100%;
  bottom: 35px;
  display: flex;
  flex-direction: column-reverse;
  gap: 31px;

  &::before {
    content: '%';
    position: absolute;
    bottom: calc(100% + 7px);
    left: 6px;
    opacity: 0.7;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
  }

  .percent {
    position: relative;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      top: 8px;
      left: 32px;
      width: calc(100% - 75px);
      opacity: 0.05;
      background: #030229;
    }
  }
}

.bottom {
  position: absolute;
  bottom: 12px;
  left: 52px;
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 20px;
  color: #030229;
  opacity: 0.7;
}

.days {
  display: flex;
  gap: 50px;

  .day {
    width: 53px;
  }
}

.months {
  display: flex;
  gap: 33px;

  .month {
    width: 25px;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .graphContainer {
    padding-top: 33px;
    padding-left: 19px;
  }

  .graph {
    width: unset;
    height: unset;
    height: 184px;
    margin: unset;

    &:not(.empty) {
      transform: scale(var(--x-scaling-factor), 0.73);
      transform-origin: bottom left;
    }

    .noData {
      display: flex;
      justify-content: center;
      font-size: 16px;
      line-height: normal;

      .text {
        font-family: 'Gilroy-Bold';
        text-align: center;
        width: 185px;
      }
    }
  }

  .percents {
    &::before {
      left: 0;
      font-size: 10px;
      line-height: 7px;
    }

    .percent {
      font-size: 10px;
      line-height: 7px;
    }
  }

  .bottom {
    width: 100%;
    left: 0;
    padding-left: 37px;
    padding-right: 15px;
    font-size: 8px;
    line-height: 20px;
  }

  .days {
    justify-content: space-between;
    gap: unset;
    .day {
      width: unset;
    }
  }

  .months {
    justify-content: space-between;
    gap: unset;
    .month {
      width: unset;
    }
  }
}
