.MyJobs {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;

  .noData {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    // margin-bottom: 30px;
    /// padding: 80px;
    // padding-top: 40px;
    padding-bottom: 95px;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    p {
      max-width: 700px;
      font-family: Gilroy-Bold;
      font-size: 20px;
      text-align: center;

      .bold {
        font-family: Gilroy-Semibold;
      }
    }
  }

  .header {
    display: flex;
    align-items: flex-end;
    gap: 21px;
    margin-bottom: 20px;

    .col {
      &:first-child {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .title {
        margin-bottom: 20px;
        font-family: 'Gilroy-Bold';
        font-size: 20px;
      }

      .stats {
        display: flex;
        gap: 20px;
        margin-top: 20px;
      }
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: transparent;
    border-radius: 16px 16px 16px 16px;
    // box-shadow: 0px 4px 60px rgba(24, 39, 75, 0.1);
    flex: 1;

    &.noShadow {
      box-shadow: none;
    }

    .tableMenuContainer {
      display: flex;
      align-items: center;
      border-top-right-radius: 16px;

      .innerContainer {
        flex: 1;
      }
    }

    .outerGridContainer {
      display: flex;
      justify-content: center;
      // height: calc(100vh - 80px - 37px - 300px - 26px - 95px - 13px);

      .gridContainer {
        width: 100%;
        //  box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.1);
        //
      }
    }
  }
}

@media (max-width: 1024px) {
  .MyJobs {
    padding-left: 0;
    padding-right: 0;

    .noData {
      flex: unset;
      margin-bottom: 0;
      margin-bottom: 30px;
      padding: 80px;
      padding-top: 40px;
    }

    .header {
      flex-direction: column;
      .col {
        width: 100%;

        .title {
          display: none;
        }

        .stats {
          margin-top: 30px;
        }
      }
    }

    .tableContainer {
      .tableMenuContainer {
        background-color: white;
        border-radius: 16px;
      }
    }
  }
}
