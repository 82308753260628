@use '../../../../../../variables' as *;

.Tags {
  display: flex;
  align-items: center;
  gap: 38px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #c1c5d6;

  .title {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 16px;
  }

  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    min-height: 51px;
    padding: 7px;
    border: 1px solid #c1c5d6;
    border-radius: 7px;

    .addMoreButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      padding: 0;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;
      color: #0fc7e6;
      background-color: transparent;
      border: none;
    }

    .tagInput {
      padding-left: 10px;
      padding-right: 10px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;

      &:focus {
        outline: 1px solid #00e4ac;
        border: none;
      }
    }
  }
}

@media (max-width: $breakpoint) {
  .Tags {
    gap: 15px;

    .tagsContainer {
      .addMoreButton {
        display: none;
      }
    }
  }
}
