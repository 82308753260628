.Members {
  height: 178px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  scrollbar-width: thin;

  &:hover {
    overflow: auto;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}
