@use '../../../../variables' as *;

.AddContent {
  min-height: 97px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: white;
  box-shadow: 0px -4px 60px rgba(78, 79, 114, 0.1);
  border-radius: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

@media (max-width: $breakpoint) {
  .AddContent {
    border-radius: 8px;
    overflow-y: unset;
    overflow-x: unset;
  }
}
