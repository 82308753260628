@use '../../../../../variables' as *;

.Tabs {
  position: relative;
  display: flex;
  gap: 40px;
  border-bottom: 0.5px solid #c1c5d6;

  .activeTabBorder {
    position: absolute;
    bottom: -2.5px;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    border-radius: 40px;
    transition: all 0.2s linear;
  }

  .tab {
    padding-top: 15px;
    padding-bottom: 17px;
    max-height: 47px;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 15px;
    color: #c1c5d6;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      color: #242833;
    }

    &.active {
      position: relative;
      color: #242833;
      /*    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: -2.5px;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 40px;
      } */
    }
  }
}

@media (max-width: $breakpoint) {
  .Tabs {
    gap: unset;
    justify-content: space-between;
  }
}
