.AssessmentInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      color: white;
    }
  }
}
