@use '../../../../../../../variables' as *;

.File {
  display: flex;
  flex-direction: column;
  width: 96px;
  height: 106px;
  padding: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 12px;
  background-color: white;
  border-radius: 16px;

  &:hover {
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  }

  .header {
    .menuContainer {
      display: flex;
      justify-content: flex-end;
      height: 25px;
      padding-right: 10px;

      button {
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }

    .uploadProgress {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;
      color: white;
      background-color: #c1c5d6;
      border-radius: 16px 16px 0px 0px;
      overflow: hidden;

      span {
        position: relative;
        z-index: 1;
      }

      .progressBar {
        position: absolute;
        left: 0;
        height: 25px;
        background-color: #00e4ac;
        // transition: all 0.3s ease-in;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding-top: 8px;
    padding-left: 5px;
    padding-right: 5px;

    .nameInput {
      width: 100%;
      border: none;

      &:focus {
        outline: 1px solid #00e4ac;
      }
    }

    .name {
      width: 100%;
      font-family: 'Gilroy-Medium';
      font-size: 10px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      width: 32px;
    }
  }
}

@media (max-width: $breakpoint) {
  .File {
    width: 60px;
    height: 63px;
    border-radius: 8px;

    .header {
      .menuContainer {
        padding-right: 4px;
        height: 16px;

        button {
          position: relative;
          top: -8px;
          width: 10px;
          height: 2px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .uploadProgress {
        height: 16px;
        font-size: 10px;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .body {
      padding-top: 7px;

      .name {
      }

      img {
        height: 20px;
      }
    }
  }
}
