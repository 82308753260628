.Font {
  padding-top: 13px;
  padding-left: 16px;
  padding-right: 27px;
  padding-bottom: 18px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f4f8;

    .titleContainer {
      .title {
        margin: 0;
        margin-bottom: 11px;
        font-family: Gilroy-Bold;
        color: #242833;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .gilroy {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-family: Gilroy-Bold;
        font-size: 16px;
        line-height: 150%;
      }
    }

    .saveResetButtonsContainer {
      display: flex;
      justify-content: flex-end;
    }
  }

  .fonts {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .Font {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .fonts {
      gap: 15px;
      padding-top: 7px;
    }
  }
}
