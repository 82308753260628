.CountriesDropdown {
  position: fixed;
  z-index: 1;
  padding: 13px;
  padding-right: 3px;
  width: 272px;
  height: 287px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17),
    0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transform: translateX(54px);

  .content {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
    }

    .countries {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;

      .all {
        font-family: Gilroy-SemiBold;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #242833;
      }
    }

    .noData {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'National 2 Condensed';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
