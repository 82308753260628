.groups {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1024px) {
  .groups {
    gap: 18px;
  }
}
