@use '../../../../../../variables' as *;

.Recipients {
  width: 100%;
  margin-top: 25px;
  padding-top: 23px;
  padding-bottom: 21px;
  padding-left: 30px;
  padding-right: 42px;
  display: flex;
  align-items: center;
  gap: 54px;
  background-color: #f2f4f8;
  border-radius: 7px;

  h4 {
    margin: 0;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 16px;
    color: #242833;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    min-height: 50px;
    padding: 7px;
    background-color: white;
    border-radius: 7px;

    .addMoreButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      padding: 0;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;
      color: #0fc7e6;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width: $breakpoint) {
  .Recipients {
    gap: 8px;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
