.Resume {
  height: 100%;
  .resumeContainer {
    height: 100%;
    padding-top: 230px;
  }
}

@media (max-width: 1024px) {
  .Resume {
    height: 615px;

    .resumeContainer {
      padding-top: 152px;
    }
  }
}
