.JobDetails {
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: auto;

  .backButton {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 18px;
    padding: 0;
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 25px;
    color: #242833;
    background-color: transparent;
    border: none;
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 347px);
    position: relative;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    .innerTableContainer {
      padding-left: 18px;
      padding-right: 18px;
    }
  }
}

@media (max-width: 1024px) {
  .JobDetails {
    position: relative;
    padding-top: 36px;
    padding-left: 0;
    padding-right: 0;

    .backButton {
      top: 0;
      left: 0;
      gap: 10px;
      font-size: 14px;
      line-height: 21px;
    }

    .tableContainer {
      min-height: unset;
      margin-top: 35px;
    }
  }
}
