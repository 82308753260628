.SetNewPasswordPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  flex: 1;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 100%;

    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .SetNewPasswordPage {
    height: 567px;
  }
}
