.Template {
  width: 100%;

  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    user-select: none;
    cursor: pointer;

    &.secondOne {
      margin-top: 50px;
    }

    .arrowBlock {
      display: flex;
      align-items: center;
      gap: 33px;
      padding-right: 31px;
    }

    .info {
      display: flex;
      flex-direction: column;
      padding-left: 26px;
      span {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 24px;
        color: #242833;
      }

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 36px;
        color: #242833;
      }
    }
  }

  .stepOneContainer {
    margin-top: 34px;
  }
}
