.AddCandidate {
  position: absolute;
  top: 0;
  left: 80px;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;

  &.visible {
    pointer-events: all;
    .backdrop {
      display: block;
    }

    .popup {
      right: 0;
    }
  }

  .backdrop {
    display: none;
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 80px;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 40, 51, 0.1);
    backdrop-filter: blur(1.5px);
  }

  .popup {
    position: absolute;
    z-index: 1003;
    top: 0;
    right: -120%;
    bottom: 0;
    width: 1062px;
    background-color: white;
    transition: right 0.2s ease-in-out;

    .closeIconContainer {
      position: absolute;
      top: 34px;
      left: 0;
      transform: translateX(-100%);
    }

    .container {
      height: 100vh;
      overflow-y: auto;

      .innerContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 1105px;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .AddCandidate {
    position: fixed;
    z-index: 2222;
    left: 0;
    height: 100dvh;
    overflow-y: auto;

    &.visible {
      .popup {
        display: block;
        left: 15px;
        right: 15px;
      }
    }

    .backdrop {
      left: 0;
    }

    .popup {
      display: none;
      width: calc(100vw - 30px);
      background-color: transparent;

      .closeIconContainer {
        display: none;
      }

      .container {
        height: unset;
        overflow-y: unset;

        .innerContainer {
          min-height: unset;
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 27px;
        }
      }
    }
  }
}
