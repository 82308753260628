.Stat {
  position: relative;
  padding: 15px;
  width: 175px;
  height: 162px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 7px 60px rgba(0, 0, 0, 0.3);
    .amount {
      transform: scale(1.3);
      margin-left: 10px;
    }
  }

  .title {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .amount {
      display: flex;
      // position: relative;
      // top: 10px;
      font-family: 'Gilroy-SemiBold';
      font-size: 24px;
      line-height: 100%;
      //  line-height: 17px;
      color: #6941ff;
      transition: all 0.3s ease-in-out;

      /*  .minutes {
        // content: 'In minutes';
        // position: absolute;
        top: 28px;
        left: 0;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 10px;
        line-height: 160%;
        white-space: pre;
        color: #959595;
      } */
    }

    .percentage {
      display: flex;
      align-items: flex-end;
      &::after {
        content: '%';
        font-size: 12px;
        line-height: 16px;
      }
    }

    .change {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .last {
        //  position: relative;
        //  bottom: 15px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #00e4ac;
      }
      .lastTenDays {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        white-space: pre;
        color: rgba(36, 40, 51, 1);
      }
    }

    .percentageLast {
      &::after {
        content: '%';
        //  position: absolute;
        left: 100%;
        top: 2px;
        font-size: 12px;
      }
    }

    .zero {
      top: 0;
      color: #c1c5d6;
    }
  }

  .noData {
    position: absolute;
    left: 20px;
    top: 73px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 36px;
    color: #c1c5d6;
  }
}

@media (max-width: 1024px) {
  .Stat {
    width: 100%;
  }
}
