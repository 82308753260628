.Chip {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #00e4ac;
  font-family: 'Gilroy-SemiBold';
  font-size: 14px;
  line-height: 20px;
  color: #242833;
  white-space: nowrap;
  user-select: none;
  border-radius: 16px;

  i {
    position: relative;
    top: -2px;
    cursor: pointer;
  }
}
