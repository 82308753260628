.NoMessages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .container {
    position: relative;
    top: -16%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      position: relative;
      left: 30px;
      margin-bottom: 61px;
    }

    p {
      font-family: 'Gilroy-SemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }
  }
}
