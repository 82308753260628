@use '../../../../../../variables' as *;

.Comments {
  display: flex;
  flex-direction: column;

  .commentsContainer {
    &.scrollable {
      margin-bottom: 15px;
    }
  }

  .commentsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: $breakpoint) {
  .Comments {
    .commentsContainer {
      gap: 6px;
    }
  }
}
