@use '../../../../../../../../variables' as *;

.Answer {
  display: flex;
  align-items: center;
  gap: 17px;

  .container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 8px;
    cursor: pointer;

    &.hasVoted {
      pointer-events: none;

      .input {
        display: none;
      }
    }

    &.active:not(.hasVoted) {
      background-color: #00e4ac;
      .input {
        border-color: white;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='10' height='10' rx='5' fill='white' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");

        &.multiple {
          background-color: #00e4ac;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.01' fill-rule='evenodd' clip-rule='evenodd' d='M0 0V24H24V0H0Z' fill='%2350A1FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 11C3 8.19974 3 6.79961 3.54497 5.73005C4.02433 4.78924 4.78924 4.02433 5.73005 3.54497C6.79961 3 8.19974 3 11 3H13C15.8003 3 17.2004 3 18.27 3.54497C19.2108 4.02433 19.9757 4.78924 20.455 5.73005C21 6.79961 21 8.19974 21 11V13C21 15.8003 21 17.2004 20.455 18.27C19.9757 19.2108 19.2108 19.9757 18.27 20.455C17.2004 21 15.8003 21 13 21H11C8.19974 21 6.79961 21 5.73005 20.455C4.78924 19.9757 4.02433 19.2108 3.54497 18.27C3 17.2004 3 15.8003 3 13V11Z' fill='%2300E4AC'/%3E%3Cmask id='mask0_9410_69117' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.54497 5.73005C3 6.79961 3 8.19974 3 11V13C3 15.8003 3 17.2004 3.54497 18.27C4.02433 19.2108 4.78924 19.9757 5.73005 20.455C6.79961 21 8.19974 21 11 21H13C15.8003 21 17.2004 21 18.2699 20.455C19.2108 19.9757 19.9757 19.2108 20.455 18.27C21 17.2004 21 15.8003 21 13V11C21 8.19974 21 6.79961 20.455 5.73005C19.9757 4.78924 19.2108 4.02433 18.2699 3.54497C17.2004 3 15.8003 3 13 3H11C8.19974 3 6.79961 3 5.73005 3.54497C4.78924 4.02433 4.02433 4.78924 3.54497 5.73005ZM16.7179 8.80385C16.3334 8.40737 15.7003 8.39763 15.3039 8.7821L10.8438 13.107L9.19615 11.5094C8.79966 11.1249 8.16657 11.1346 7.7821 11.5311C7.39763 11.9276 7.40737 12.5607 7.80385 12.9452L10.1476 15.2179C10.5355 15.594 11.152 15.594 11.5399 15.2179L16.6961 10.2179C17.0926 9.83343 17.1024 9.20034 16.7179 8.80385Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_9410_69117)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H23.976V24H0V0Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
      }
    }

    .progress {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #725ee8;
      border-radius: 8px;
    }

    .title {
      z-index: 1;

      &.white {
        color: white;
      }
    }

    .input {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid #c1c5d6;
      border-radius: 50%;

      &.multiple {
        width: 18px;
        height: 18px;
        background-color: white;
        border: none;
        border-radius: 4px;
      }
    }
  }

  .voteStats {
    display: flex;
    gap: 20px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 150%;
    color: #242833;

    &.noStats {
      color: #c1c5d6;
    }

    .percentage {
      text-align: right;
      min-width: 43px;
    }

    .voteCount {
      text-align: right;
      min-width: 20px;
    }
  }
}

@media (max-width: $breakpoint) {
  .Answer {
    .voteStats {
      font-size: 14px;
    }
  }
}
