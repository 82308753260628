.Color {
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 12px;

  .header {
    margin-bottom: 6px;
    padding-right: 7px;
    padding-bottom: 21px;
    border-bottom: 1px solid #f2f4f8;

    &.active {
      padding-bottom: 16px;
      .title {
        margin-bottom: 9px;
      }
    }

    .title {
      margin-bottom: 14px;
      font-family: Gilroy-Bold;
      color: #242833;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .colorsContainer {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      .colors {
        display: flex;
        align-items: center;
        gap: 13px;
      }
    }
  }

  .options {
    display: flex;
    gap: 17px;

    .col {
      flex: 1;

      .suggestedColorsHeader {
        padding-top: 44px;
        h3 {
          margin-bottom: 30px;
          font-family: Gilroy-Bold;
          color: #242833;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .pickColorTitle {
        margin-bottom: 14px;
        font-family: Gilroy-Regular;
        color: #242833;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .prevColors {
        display: flex;
        gap: 15px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Color {
    .header {
      margin-bottom: 20px;
      padding-bottom: 0 !important;
      border-bottom: none;

      .colorsContainer {
        gap: 13px;
        flex-wrap: wrap;
        .colors {
          gap: 10px;
        }
      }
    }

    .options {
      flex-direction: column;
      gap: 22px;
      .col {
        .suggestedColorsHeader {
          padding-top: 0;

          h3 {
            margin-bottom: 22px;
          }
        }

        .pickColorTitle {
          margin-bottom: 10px;
          font-family: Gilroy-SemiBold;
        }
      }
    }
  }
}
