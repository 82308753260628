.dialog {
  max-width: 375px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  height: 601px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.ShowQRQModal {
  border-radius: 20px;

  .content {
    padding: 38px 40px;

    header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 40px;
        line-height: 40px;
        color: #242833;
      }

      .closeIcon {
        position: absolute;
        right: 0;
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close.svg);
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      gap: 50px;
      margin-bottom: 21px;

      .col {
        display: flex;
        flex-direction: column;
        flex: 1;

        .QRQContainer {
          width: 295px;
          height: 308px;
          display: flex;
          gap: 13px;
          flex-direction: column;
          align-items: center;
          background: #F2F4F8;
          border-radius: 12px;

          .QRQ {
            margin-top: 40px;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border: 1px solid #242833;
            border-radius: 12px;
          }
        }

        .jobTitle {
          width: 100%;
          padding: 0 15px;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          text-align: center;
          color: #242833;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    footer {
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
  }
}
