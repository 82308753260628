.GraphBar {
  display: flex;
  flex-direction: column;
  gap: 0px;

  .name {
    margin-bottom: 10px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 16px;
    color: #3c3c43;
  }

  .bar {
    position: relative;
    width: 100%;
    height: 6px;
    background: #f2f4f8;
    border-radius: 0px 8px 8px 0px;

    .greenBar {
      position: absolute;
      top: -3px;
      left: 0;
      width: 0;
      height: 12px;
      background: #00e4ac;
      border-radius: 0px 8px 8px 0px;
    }

    .amount {
      position: absolute;
      bottom: -4px;
      left: calc(100% + 3px);
      font-family: 'Gilroy-Regular';
      font-size: 10px;
      line-height: 20px;
    }
  }
}
