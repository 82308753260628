.OtpSignInPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    height: 100%;

    .inputs {
      width: 100%;

      .label {
        display: inline-block;
        margin-bottom: 12px;
        font-family: Gilroy-Medium;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }

      & > div {
        & > div {
          display: flex;
          justify-content: space-between !important;

          .input {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            margin: 0;
            padding-left: 0;
            padding-top: 0;
            font-family: Gilroy-Bold;
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            text-align: center;
            background-color: #f2f4f8;
            border-radius: 16px;
            border: none;

            &::placeholder {
              color: #c1c5d6;
              transform: translateY(-3px);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .helper {
      font-family: 'Gilroy-Bold';
      p {
        &:first-child {
          margin: 0;
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;

          span {
            color: #00e4ac;
            cursor: pointer;
          }
        }

        &:last-child {
          margin: 0;
          font-family: Gilroy-Medium;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          color: #c1c5d6;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .OtpSignInPage {
    height: 567px;
  }
}
