.Attachments {
  padding-top: 28px;
  padding-left: 15px;
  padding-right: 15px;

  h3 {
    margin-bottom: 17px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #c1c5d6;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
