.dialog {
  max-width: 720px;
}

.backdrop {
  background-color: rgba(37, 40, 51, 0.1);
  backdrop-filter: blur(1.5px);
}

.modalContent {
  height: 847px;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.ProductAnnouncementsModal {
  border-radius: 16px;

  .content {
    height: 100%;
    padding: 20px;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 20px;
        line-height: 20px;
      }

      .col {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      .closeIcon {
        display: block;
        width: 23px;
        height: 23px;
        font-size: 0;
        background-image: url('../../assets/images/modals/close-v2.svg');
        cursor: pointer;
      }
    }

    .container {
      // height: 680px;
      height: calc(100% - 78px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      .announcements {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      .noData {
        margin-top: 257px;
        display: flex;
        justify-content: center;
        font-family: 'Gilroy-Medium';
        font-size: 16px;
        color: #c1c5d6;
      }
    }
  }
}

@media (max-width: 767px) {
  .dialog {
  }

  .backdrop {
  }

  .modalContent {
  }

  .ProductAnnouncementsModal {
    .content {
      header {
        position: relative;
        flex-direction: column;
        margin-bottom: 20px;

        h1 {
          width: 100%;
          margin-bottom: 22px;
          text-align: left;
        }

        .col {
          width: 100%;
        }

        .closeIcon {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .container {
        &::-webkit-scrollbar {
        }

        .announcements {
          gap: 20px;
        }

        .noData {
        }
      }
    }
  }
}
