.MainStats {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 14px;
  row-gap: 13px;
}

@media (max-width: 1024px) {
  .MainStats {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    row-gap: 24px;
  }
}
