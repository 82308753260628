.Stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;

  &.completed {
    .stageNumber {
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='13' viewBox='0 0 19 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4886 1L6.48865 12L1.48865 7' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: #00e4ac;
      border: none;
    }
  }

  &.pending {
    .stageNumber {
      border: 2px solid #242833;
      color: #242833;
      background-color: white;
    }
  }

  &.requested {
    .stageNumber {
      color: white;
      background-color: #242833;
      border: 1px solid #c1c5d6;
    }
  }

  &.current {
    .stageNumber {
      color: white;
      background-color: #00e4ac;
      border: none;
    }
  }

  &.rejected {
    .stageNumber {
      color: white;
      background-color: #ff2d55;
      border: none;
    }
  }

  &.declined {
    .stageNumber {
      color: white;
      background-color: #ffbf00;
      border: none;
    }
  }

  .stageNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #c1c5d6;
    border: 1px solid #c1c5d6;
    border-radius: 50%;
  }

  .stageTitle {
    font-family: 'Gilroy-Regular';
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    color: #707070;
    white-space: nowrap;
  }
}
