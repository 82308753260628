/* .TitleDropdown {
  margin: 0;
  font-family: 'Gilroy-Bold';
  font-size: 22px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #6722d2;
}

@media (max-width: 1024px) {
  .TitleDropdown {
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 20px;
  }
}
 */

.Dropdown {
  position: relative;
  z-index: 10000;
  min-width: 175px;

  &.open {
    &::after {
      content: '';
      position: absolute;
      top: 43px;
      z-index: 1;
      display: block;
      width: 100%;
      min-width: 175px;
      height: var(--optionsHeight);
      border-radius: 16px;
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }

    .selector {
      &::after {
        transform: rotate(180deg);
        background-image: url(../../../../../../assets/images/dashboard/dashboard/chevron-black.svg);
      }
    }
  }

  .selector {
    position: relative;
    width: fit-content;
    padding-right: 26px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(../../../../../../assets/images/dashboard/dashboard/chevron-green.svg);
      background-repeat: no-repeat;
      background-position: center;
    }

    span {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 22px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #6722d2;
      white-space: nowrap;
    }
  }

  .optionsContainer {
    position: absolute;
    top: 43px;
    right: 0;
    left: 0;
    z-index: 1000;
    min-width: 175px;
    border-radius: 16px;
    overflow: hidden;

    &.calendar {
      right: unset;

      .options {
        max-height: unset;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      gap: 16px;
      min-width: 175px;
      max-height: 215px;
      margin: 0;
      padding: 0;
      padding-top: 16px;
      padding-bottom: 15px;
      list-style: none;
      background-color: white;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin;

      .option {
        height: 24px;
        padding-left: 16px;
        padding-right: 5px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #3c3c43;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Dropdown {
    .selector {
      span {
        margin-bottom: 20px;
        font-size: 17px;
        line-height: 20px;
      }
    }
  }
}
