@use '../../../../../../variables' as *;

.Appreciation {
  padding-left: 15px;
  padding-right: 15px;
  .container {
    display: flex;
    gap: 20px;
    padding-right: 63px;
    background-color: #c1f5ff;
    border-radius: 16px;

    &.milestone {
      background-color: rgba(0, 228, 172, 0.14);

      .content {
        padding-top: 37px;
        padding-bottom: 43px;
        .title {
          font-family: Gilroy-Bold;
          font-size: 24px;
          line-height: 34px;
        }

        .message {
          font-family: Gilroy-Regular;
          font-size: 20px;
          line-height: 30px;
        }

        .milestoneCount {
          margin-bottom: 5px;
          font-family: Gilroy-Bold;
          font-size: 40px;
          line-height: 45px;
        }
      }

      .background {
        background-image: url(../../../../../../assets/images/dashboard/discover/milestone.png);
        background-size: 180px;
      }
    }

    &.fiveStarFeedback {
      background-color: #c1f5ff;

      .content {
        .title {
          font-size: 16px;
          font-family: Gilroy-Bold;
          line-height: 28px;
        }

        .stars {
          display: flex;
          gap: 8px;
          list-style: none;
          margin: 0;
          margin-bottom: 10px;
          padding: 0;
        }

        .message {
          margin-bottom: 43px;
        }

        .likeBadges {
          display: flex;
          gap: 24px;

          .outerBadge {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            background-color: white;
            border-radius: 50%;

            .innerBadge {
              width: 66px;
              height: 66px;
              background: url(../../../../../../assets/images/dashboard/discover/thumbsUp.svg),
                linear-gradient(228deg, #00e4ac 0%, #10c5e9 50.5%, #9240dc 100%);
              background-position: center;
              background-repeat: no-repeat;
              border-radius: 50%;
            }
          }
        }
      }

      .background {
        background-image: url(../../../../../../assets/images/dashboard/discover/fiveStarFeedback.png);
      }
    }

    .content {
      flex: 1;
      padding-top: 19px;
      padding-left: 24px;
      padding-bottom: 21px;

      .title {
        margin-bottom: 5px;
        font-family: 'Gilroy-Bold';
        font-size: 20px;
        line-height: 150%;
        color: black;
      }

      .text {
        margin-bottom: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: black;
      }

      .recipients {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
      }
    }

    .background {
      width: 236px;
      min-height: 202px;
      background-image: url(../../../../../../assets/images/dashboard/discover/appreciation.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: $breakpoint) {
  .Appreciation {
    .container {
      padding-right: 0;
      &.milestone {
        align-items: center;
        padding: 15px;

        .content {
          padding-right: 0;
          padding: 0;
          min-width: 207px;

          .title {
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 20px;
          }

          .message {
            font-size: 14px;
            line-height: 20px;
          }

          .milestoneCount {
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 20px;
          }
        }

        .background {
          background-size: contain;
        }
      }

      &.fiveStarFeedback {
        .content {
          overflow: hidden;

          .userContainer {
            width: 100%;
            display: inline-block;

            word-break: break-all;
          }

          .message {
            word-break: break-all;
          }

          .likeBadges {
            gap: 0;
            justify-content: space-between;

            .outerBadge {
              min-width: 80px;
              .innerBadge {
                min-width: 66px;
              }
            }
          }
        }
      }

      .content {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .background {
        aspect-ratio: 1 / 1;
        //  flex: 1;
        height: 24%;
        width: 24%;
        // width: unset;
        min-height: unset;
        max-height: 120px;
        background-size: contain;
      }
    }
  }
}
