.dialog {
  max-width: 921px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 16px;
  border: none;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}

.ChangePasswordModal {
  .content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      padding-bottom: 32px;
      border-bottom: 1px solid rgba(193, 197, 214, 1);

      .title {
        margin-bottom: 0;
        font-family: Gilroy-Bold;
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
      }

      .closeButton {
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 26px;

      .row {
        display: flex;
        gap: 24px;
        width: 100%;

        .col {
          flex: 1;
        }
      }
    }

    .buttons {
      padding-top: 28px;
      border-top: 1px solid rgba(193, 197, 214, 1);
    }
  }
}

@media (max-width: 1024px) {
  .backdrop {
    background-color: (242, 244, 248, 1);
  }

  .ChangePasswordModal {
    .content {
      padding-bottom: 25px;
      .header {
        border-bottom: none;
        padding-bottom: 0;
      }

      .form {
        margin-bottom: 48px;
        .row {
          flex-direction: column;
          .col {
            flex: unset;
          }
        }
      }

      .buttons {
        position: relative;
        border-top: 0;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
          display: block;
          width: calc(100% + 40px);
          height: 1px;
          background-color: rgba(193, 197, 214, 1);
        }
      }
    }
  }
}
