@use '../../../variables' as var;
@keyframes scaleRadio  {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(1.2);
  }
  50% {
    transform: scaleX(1.5);
  }
  75% {
    transform: scaleX(1.2);
  }
  100% {
    transform: scaleX(1);
  }
}

.RadioButton {
  display: flex;
  gap: 10px;
  cursor: pointer;

  &.bigAndBold {
    .label {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }

    .button {
      width: 24px;
      height: 24px;
    }

    &.selected {
      .buttonWrapper {
        border-color: var.$light-green;
      }
      .button {
        background-size: 24px;
      }
    }
  }

  &.selected {
    .buttonWrapper {
      border-color: var.$light-green;
    }
    .button {
      background: var.$light-green;
      animation: scaleRadio 0.3s ease-in-out;
    }

    .label {
      font-family: Gilroy-SemiBold;
      color: #242833 !important;
    }
  }

  .buttonWrapper {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border-color: var.$light-gray-color;
    border-width: 2px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    min-width: 12px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition:  all 0.3s ease-in-out;
  }

  .label {
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    line-height: 24px;
    color: #c1c5d6;
  }
}

@media (max-width: 1024px) {
  .RadioButton {
    &.bigAndBold {
      .label {
      }

      .button {
      }

      &.selected {
        .button {
        }
      }
    }

    &.selected {
      .button {
      }

      .label {
      }
    }

    .button {
    }

    .label {
      white-space: nowrap;
    }
  }
}
