.CalendarMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Gilroy-Bold';

  .searchResultCount {
    align-self: center;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #242833;
  }

  .container {
    width: 339px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 0;
      font-size: 20px;
      line-height: 20px;
      color: #242833;
    }

    .today {
      width: 221px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      color: #c1c5d6;
      background-color: white;
      border-radius: 16px;
      transition: background-color 0.3s, color 0.3s;
      cursor: pointer;

      &:hover {
        background: #c1c5d6;
        border: #c1c5d6;
        color: white;
      }

      &:active {
        background: #242833;
        color: white;
      }
    }
  }

  .flexContainer {
    display: flex;
    flex-grow: 1;
    gap: 15px;
    justify-content: space-between;
    margin-left: 15px;
  }

  .viewContainer {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    width: max-content;
    height: 45px;
    padding-left: 9px;
    padding-right: 6px;
    justify-content: space-between;
    background-color: white;
    border-radius: 16px;

    .view {
      height: 32px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 16px;
      // transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        background: #c1c5d6;

        .counter {
          background: white;
          color: black;
        }

        .text {
          color: white;
        }
      }

      .text {
        font-family: Gilroy-Bold;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        color: #c1c5d6;
      }

      .counter {
        min-width: 30px;
        width: 30px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 24px;
        color: #c1c5d6;
        background: #f2f4f8;
        border-radius: 16px;
        // transition: background-color 0.3s, color 0.3s;
      }
    }

    .active {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );

      &:hover {
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }

      .text {
        color: white;
      }

      .counter {
        color: #c1c5d6;
        background: white;
        border-radius: 16px;
      }
    }
  }

  .zone {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    // transform: translateX(-50%);
    // max-width: 155px;
    word-wrap: break-word;
    align-self: center;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 150%;
    white-space: nowrap;
    color: #242833;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 15px;
      top: calc(50% - 2px);
      width: 8px;
      height: 16px;
      background: url('../../../../assets/images/dashboard/candidate/arrow.svg');
      transform: rotate(270deg) translateX(50%);
    }

    .list {
      position: absolute;
      display: flex;
      flex-direction: column;
    }
  }

  .form {
    display: flex;
    gap: 10px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;

    .search {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 279px;
      flex: 1;
      height: 45px;
      padding-left: 44px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z' stroke='url(%23paint0_linear_0_1)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19 19.0004L14.65 14.6504' stroke='url(%23paint1_linear_0_1)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_0_1' x1='9' y1='-8.01297' x2='-9.02594' y2='7.98703' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_0_1' x1='16.825' y1='12.2' x2='11.9242' y2='16.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 15px center;
      background-color: white;
      border-radius: 16px;

      .searchInput {
        &::placeholder {
          transition: all 0.4s ease-in-out;
        }

        &:hover {
          &::placeholder {
            opacity: 0;
          }
        }
      }

      .searchHistory {
        position: absolute;
        left: 0;
        top: calc(100% + 8px);
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-top: 15px;
        padding-bottom: 29px;
        list-style: none;
        background-color: white;
        border-radius: 16px;
        filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));

        li {
          padding-left: 45px;
          padding-right: 10px;
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0001 19.75C16.3849 19.75 20.7501 15.3848 20.7501 10C20.7501 4.61522 16.3849 0.25 11.0001 0.25C5.61532 0.25 1.2501 4.61522 1.2501 10C1.2501 12.3554 2.09097 14.5746 3.56702 16.3107H1.31812C0.903902 16.3107 0.568115 16.6464 0.568115 17.0607C0.568115 17.4749 0.903901 17.8107 1.31812 17.8107L5.56076 17.8107C5.97497 17.8107 6.31076 17.4749 6.31076 17.0607V12.818C6.31076 12.4038 5.97497 12.068 5.56076 12.068C5.14654 12.068 4.81076 12.4038 4.81076 12.818V15.4556C3.49915 13.9698 2.7501 12.0466 2.7501 10C2.7501 5.44365 6.44375 1.75 11.0001 1.75C15.5564 1.75 19.2501 5.44365 19.2501 10C19.2501 14.5563 15.5564 18.25 11.0001 18.25C10.5859 18.25 10.2501 18.5858 10.2501 19C10.2501 19.4142 10.5859 19.75 11.0001 19.75ZM11.0001 5.05072C11.4143 5.05072 11.7501 5.3865 11.7501 5.80072V10.6893L14.1858 13.1251C14.4787 13.418 14.4787 13.8929 14.1858 14.1858C13.893 14.4786 13.4181 14.4786 13.1252 14.1858L10.4698 11.5303C10.3291 11.3897 10.2501 11.1989 10.2501 11V5.80072C10.2501 5.3865 10.5859 5.05072 11.0001 5.05072Z' fill='%2300E4AC'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: left 10px center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }

      .button {
        position: absolute;
        right: 15px;
        padding: 0;
        background-color: transparent;
        border: none;
      }

      input {
        max-width: 80px;
        height: 100%;
        font-family: 'Gilroy-Medium';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
        border: none;

        &::placeholder {
          color: #c1c5d6;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .schedule {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 103px;
      height: 45px;
      padding: 0;
      padding-left: 18px;
      padding-right: 8px;
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      color: white;
      background-image: linear-gradient(
        228deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border: none;
      border-radius: 16px;
      cursor: pointer;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .CalendarMenu {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .container {
      width: 100%;

      .today {
        width: 191px;
      }
    }

    .flexContainer {
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      margin-left: 0;
      .viewContainer {
        display: none;
      }
    }

    .form {
      width: 100%;

      .schedule {
        width: 131px;
        padding-left: 20px;
        padding-right: 20px;
        img {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}
