.Controls {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 4px;
  transition: all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);

  .control {
    width: 20px;
    height: 4px;
    background-color: #c1c5d6;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.51, 0.92, 0.24, 1.15);

    &.active {
      width: 60px;
      background-color: #00e4ac;
    }
  }
}
