.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #3C3C43;
}

.date {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  align-items: end;

  .container {
    display: flex;
    gap: 40px;
    flex-grow: 1;

    .field {
      &:first-child {
        max-width: 765px;
        flex-grow: 1;
      }
    
      .flexContainer {
        display: flex;
        gap: 60px;
      }
    }

    .from, .to, .duration {
      position: relative;
      padding: 10px 20px;
      max-width: 350px;
      height: 50px;
      display: flex;
      align-items: center;
      text-align: center;
      background: #F2F4F8;
      border-radius: 16px;
    }

    .from, .to {
      flex-grow: 1;
      
      .calendarIcon {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 20px;
        height: 20px;
        background: url('../../../../../../../assets/images/dashboard/candidate/header/offCalendar.svg');
        cursor: pointer;
      }
    }

    .from {
      pointer-events: none;
  
      &::after {
        content: 'to';
        position: absolute;
        right: -37px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
      }
    }

    .duration {
      width: 140px;
      display: flex;
      justify-content: space-between;
      color: #242833;

      .value {
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #242833;
      }

      .plus, .minus {
        height: 50px;
        display: flex;
        padding-bottom: 8px;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        user-select: none;
        cursor: pointer;
      }
    }
  }

  .button {
    width: 160px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Bold';
    line-height: 32px;
    color: white;
    background: #00E4AC;
    box-shadow: 0px 16px 60px rgba(134, 109, 201, 0.16);
    border-radius: 15px;
    cursor: pointer;
  }

  .disabled {
    background: #C1C5D6;
    pointer-events: none;
  }

}