.Rating {
  display: flex;
  gap: 12px;

  .star {
    width: 25px;
    height: 25px;
    background-image: url(../../../../../../../../../assets/images/chat/white-star.svg);
    background-size: contain;

    &.red {
      background-image: url(../../../../../../../../../assets/images/chat/red-star.svg);
    }

    &.orange {
      background-image: url(../../../../../../../../../assets/images/chat/orange-star.svg);
    }

    &.green {
      background-image: url(../../../../../../../../../assets/images/chat/green-star.svg);
    }
  }
}
