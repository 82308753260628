.PopupButton {
  width: 100%;
  height: 45px;
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #242833;

  &:hover {
    background-color: #c1c5d6 !important;
    color: white !important;
  }

  &:active {
    background-color: #242833 !important;
    color: white !important;
  }

  &:disabled {
    background-color: #f2f4f8 !important;
    color: #c1c5d6 !important;
    border: none !important;
    pointer-events: none;
  }

  &.muted {
    border-color: #c1c5d6;
    color: #c1c5d6;
  }

  &.green {
    background-color: #00e4ac;
    border: none;
  }

  &.dark {
    background-color: #c1c5d6;
    border: none;
  }

  &.mutedFilled {
    color: #c1c5d6;
    background-color: #f2f4f8;
    border: none;
  }
}
