.calendar {
  position: relative;
  z-index: 0;
  width: 339px;
  height: max-content;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 6px;
  background-color: white;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .monthYearButtons {
      display: flex;
      gap: 10px;
    }

    .container {
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 6px;
      font-size: 24px;
      line-height: 100%;
      color: #141414;

      cursor: pointer;
      transition: background-color 0.3s;

      .month,
      .year {
        display: flex;
        align-items: center;
        height: 40px;
        padding-left: 18px;
        padding-right: 35px;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
        background-color: #f2f4f8;
        position: relative;
        border-radius: 16px;

        &:hover {
          background-color: #f6f6f6;
        }

        &::after {
          content: '';
          position: absolute;
          top: 15px;
          right: 18px;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-right: 7px solid #00e4ac;
          transform: rotate(225deg);
        }
      }

      .isActive {
        &::after {
          content: none;
        }
      }
    }

    .isActive {
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 16px;
    }

    .prev,
    .next {
      width: 40px;
      height: 40px;
      background: no-repeat center
        url('../../assets/images/dashboard/candidate/calendar/chevron.svg');
      background-color: #f2f4f8;
      border-radius: 80px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f6f6f6;
      }
    }

    .prev {
      transform: rotate(180deg);
    }
  }

  .days {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    place-items: center;
    align-items: center;
    margin-bottom: 8px;
    font-family: Gilroy-SemiBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: #242833;

    .day {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .calendarMonth {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    place-items: center;
    row-gap: 8px;
    column-gap: 4.83px;
    font-family: Gilroy-SemiBold;
    font-size: 18px;
    line-height: 100%;
    color: #1f1f1f;
    border-radius: 6px;

    .day {
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0 9px rgba(24, 39, 75, 0.28);
        border-radius: 6px;
      }
    }

    .selected {
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      box-shadow: 0px 1.5px 1px rgba(0, 31, 112, 0.25),
        0px 3px 2px rgba(0, 71, 255, 0.2);
    }

    .today {
      color: white;
      background: #6722d2;
      box-shadow: none;
      border-radius: 12px;
    }
    .anotherMonth {
      color: #c1c5d6;
    }

    .hasEvent {
      &:after {
        content: '';
        position: absolute;
        top: 5px;
        right: 6px;
        width: 6px;
        height: 6px;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 50%;
      }
    }
  }
}

.isMode {
  padding: 15px;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: none;

  .header {
    margin-bottom: 25px;
  }

  .date {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 20px;
    color: #242833;

    .month,
    .year {
      &::after {
        content: none;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 15px;
  }

  .flexItem {
    width: 100%;
    align-self: center;
  }

  .days {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 15px;
    columns: 29px;
    margin-bottom: 15px;

    .day {
      display: flex;
      justify-content: center;
      width: unset;
      height: unset;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;

      &:first-child,
      &:last-child {
        color: #c1c5d6;
      }
    }
  }

  .calendarMonth {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    gap: 15px;

    .day {
      padding: 8px 11px;
      width: 120px;
      height: 120px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: 1px solid #c2c2c2;
      border-radius: 10px;

      transition: box-shadow 0.2s;

      &:hover {
        box-shadow: 0px 0px 9px rgba(24, 39, 75, 0.28);
        border-radius: 10px;
      }

      .flexContainer {
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }

      &:nth-child(7n),
      &:nth-child(7n - 6) {
        background: #f6f6f6;
      }

      .eventAmount {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        background-color: #242833;
        color: white;
        border-radius: 50%;
      }
    }

    .selected {
      position: relative;
      padding: 7px 10px;
      color: #242833;
      box-shadow: none;
      background: white;
      background-clip: padding-box;
      border: solid 2px transparent;
      border-radius: 10px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }

      &:nth-child(7n),
      &:nth-child(7n - 6) {
        padding: 7px 10px;
        color: #242833;
        box-shadow: none;
        background: #f6f6f6;
        background-clip: padding-box;
        border: solid 2px transparent;
        border-radius: 10px;
      }
    }

    .today {
      padding: 7px 10px;
      color: #242833;
      background: white;
      border: 2px solid #6722d2;
      border-radius: 10px;

      &::before {
        content: none;
      }

      &:nth-child(7n),
      &:nth-child(7n - 6) {
        background: #f6f6f6;
        &::after {
          content: none;
        }
      }
    }
    .anotherMonth {
      color: #c1c5d6;
    }

    .hasEvent {
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 2px;
        top: 2px;
        width: 116px;
        height: 116px;
        background: white;
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .calendar {
    width: 100%;
    margin-bottom: 18px;
  }

  .isMode {
    width: 1047px;
    overflow: hidden;
  }
}
