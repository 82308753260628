.CandidateApplications {
  padding-top: 20px;
}

@media (max-width: 1024px) {
  .CandidateApplications {
    min-height: 470px;
    margin-bottom: 30px;
  }
}
