.SocialMedia {
  display: flex;
  gap: 32px;
  height: 790px;
  padding-top: 17px;
  padding-left: 21px;
  padding-right: 22px;
  padding-bottom: 26px;
  overflow-y: auto;

  .col {
    flex: 1;

    .header {
      h2 {
        margin-bottom: 10px;
        font-family: Gilroy-Bold;
        font-size: 16px;
        color: #242833;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .description {
        font-family: Gilroy-Regular;
        font-size: 14px;
        font-style: normal;
        color: #242833;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .links {
    .header {
      margin-bottom: 18px;
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      margin-bottom: 18px;
      h2 {
        text-align: center;
      }
    }
  }
}

@media (max-width: 1024px) {
  .SocialMedia {
    flex-direction: column;
    gap: 40px;
    height: unset;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
  }
}
