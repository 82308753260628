.Report {
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    position: relative;
    height: 34px;
    width: max-content;
    min-width: 100%;
    padding: 0 16px 0;
    display: flex;
    gap: 12px;
    align-items: center;
    white-space: nowrap;
    border-radius: 8px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      left: 0;
      background: #c1c5d6;
      border-radius: 50%;
    }

    &:hover {
      color: #242833;
    }

    &.active {
      font-family: 'Gilroy-SemiBold';
      background: #f2f4f8;
      color: #242833;
    }

    &.active::before {
      content: none;
    }
  }

  .report {
    position: relative;
    padding: 5px 10px;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      left: 0;
      background: #c1c5d6;
      border-radius: 50%;
    }

    &:hover input {
      color: #242833;
    }

    &.active,
    &.active input {
      font-family: 'Gilroy-SemiBold';
      background: #f2f4f8;
      color: #242833;
    }

    &.active::before {
      content: none;
    }

    input {
      padding: 0 4px;
      width: 132px;
      line-height: 20px;
      outline: none;
      border: none;
      color: #c1c5d6;
      background: transparent;
      border: 1px solid #00e4ac;

      &.disabled {
        caret-color: transparent;
        border-color: transparent;
        cursor: pointer;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 4px;

      &.disabled {
        display: none;
      }

      span {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }

      .save {
        background: url('../../../../../../assets/images/dashboard/reports/filter/save.svg')
          center no-repeat;
      }

      .cancel {
        background: url('../../../../../../assets/images/dashboard/reports/filter/cancel.svg')
          center no-repeat;
      }

      .edit {
        background: url('../../../../../../assets/images/dashboard/reports/filter/edit.svg')
          center no-repeat;
      }

      .delete {
        background: url('../../../../../../assets/images/dashboard/reports/filter/delete.svg')
          center no-repeat;
      }
    }
  }
}
