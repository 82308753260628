.Pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 45px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Gilroy-SemiBold;
  font-size: 14px;
  line-height: 20px;
  background-color: white;
  border: 1px solid #f2f4f8;
  border-radius: 16px;
  box-shadow: 0px -4px 60px 0px #3e3f631a;

  span {
    width: 24px;
    text-align: center;
  }

  .page {
    display: flex;
    justify-content: center;
    width: 24px;

    &.currentPage {
      width: 34px;
      border: 1px solid #f2f4f8;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  input {
    &.currentPage {
      text-align: center;

      &:focus {
        outline: 1px solid #00e4ac;
      }

      &.invalid {
        &:focus {
          outline: 1px solid #ffbf00;
        }
      }
    }
  }

  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;

    .disabled {
      display: none;
    }

    &:disabled {
      .disabled {
        display: initial;
      }

      .default {
        display: none;
      }
    }

    &:last-child {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
