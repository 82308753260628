@use '../../../../../../variables' as *;

.Poll {
  padding-top: 18px;
  padding-left: 15px;
  padding-right: 15px;

  .container {
    padding-top: 14px;
    border-top: 0.5px solid #c1c5d6;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 100px;
    padding-bottom: 20px;

    .totalVotes {
      font-family: 'Gilroy-Regular';
      font-size: 18px;
      line-height: 150%;
      color: #242833;
    }
  }
}

@media (max-width: $breakpoint) {
  .Poll {
    .footer {
      padding-right: 0;
      padding-bottom: 5px;
      .totalVotes {
        font-size: 14px;
      }
    }
  }
}
