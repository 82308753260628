.FontUploader {
  display: flex;
  align-items: center;
  gap: 5px;
  h4 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #242833;
  }
}
