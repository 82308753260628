.CommButton {
  position: relative;
  width: 48px;
  height: 48px;
  font-size: 0;
  background-color: #f9fafc;
  border-radius: 50%;
  border: none;

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #64dfae;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.25s ease-in-out;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 48px;
    height: 48px;
    background-color: white;
    opacity: 0;
    border-radius: 50%;
  }

  img {
    position: relative;
    z-index: 2;
  }

  &.flash {
    &::before {
      opacity: 0.5;
      //  animation: flash 0.05s;
    }
  }

  &.clicked {
    &::after {
      transform: scale(1);
    }

    img {
      filter: brightness(10000%);
      animation: none !important;
    }
  }

  &.hovered {
    img {
      animation: bounce 0.4s ease-in-out forwards;
    }
  }

  &.notHovered {
    img {
      animation: bounceOut 0.4s ease-in-out forwards;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(0.83);
  }
  80% {
    transform: scale(0.79);
  }

  90% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes bounceOut {
  0% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(0.97);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flash {
  0% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}
