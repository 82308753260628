@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.stars {
  display: inline-block;
  border: 0;
  
  
  & > input {
    display: none;
  }

  & > label {
    float: right;
  }

  & > label:before {
    content: "\f005 ";
    padding: .3rem .2rem;
    margin: 0 12px 0 0;
    display: inline-block;
    font-size: 1.5rem;
    cursor: pointer;
    font-family: FontAwesome;
 }

  & > label {
    color: #C1C5D6;
  }

  & .halfStar:before {
    content: "\f089 ";
    position: absolute;
    padding-right: 0;
  }

  label:hover, label:hover ~ label { 
    color: #D9D9D9;
  }
}

.blue {
  input:checked ~ label {
    background: -webkit-linear-gradient(228.41deg, #00E4AC 0%, #10C5E9 53.88%, #9240DC 106.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .halfStar:before {
    content: "\f089 ";
    background: -webkit-linear-gradient(285.41deg, #00E4AC 0%, #10C5E9 53.88%, #9240DC 106.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.green input:checked ~ label{
  color: #00E4AC;
}

.orange input:checked ~ label{
  color: #FFBF00;
}

.red input:checked ~ label{
  color: #FF2D55;
}