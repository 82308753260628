.BrandName {
  .inputContainer {
    input {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0;
      padding-bottom: 5px;
      font-family: Gilroy-Bold;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      border: none;

      &::placeholder {
        color: #c1c5d6;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .savedName {
    display: flex;
    gap: 20px;
    align-items: center;

    span {
      position: relative;
      top: -2px;
      font-family: Gilroy-Bold;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      word-break: break-all;
    }

    .editButton {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      padding: 0;
      background-color: #e0e2ea;
      border: none;
      overflow: hidden;
      border-radius: 50%;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #c1c5d6; /* Color to fill */
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.25s ease-in; /* Adjust time as needed */
        border-radius: 50%;
      }

      &:hover::before {
        transform: scale(1);
      }

      img {
        // position: relative;
        z-index: 1;
      }
    }
  }
}
