.SelectedAssessments {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
