.Badge {
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #c1c5d6;
  background-color: #f2f4f8;
  border-radius: 16px;
  cursor: pointer;

  &.active {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    color: white;
  }
}
