.AddCandidateMagnifier {
  position: absolute;
  //  z-index: 22;
  top: 18px;
  left: 0;
  bottom: 20px;
  width: 230px;

  .view {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 222px;
    height: 275px;
    background: url('./../../../../../../assets/images/dashboard/candidate/wordResume/miniScroll.svg');
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .magnifier {
      position: absolute;
      top: 85px;
      left: 21px;
      width: 183px;
      height: 121px;
      border-radius: 11px;
      background-color: transparent;
      cursor: zoom-in;
    }
  }
}
