.Slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 49px;
  padding-left: 49px;
  padding-right: 49px;
  padding-bottom: 63px;
  backdrop-filter: blur(25px);
  border-radius: 16px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  h1 {
    margin-bottom: 40px;
    font-family: Gilroy-Bold;
    font-size: 40px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: white;
  }

  .text {
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: white;
  }
}
