@use '../../../../../../variables' as *;

.Text {
  --initial-height: 48px;

  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  &.insideModal {
    padding-left: 0;
    padding-right: 0;

    .container {
      &.expanded {
        max-height: calc(var(--text-height) + 100px);
      }
    }
  }

  .container {
    max-height: var(--initial-height);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 150%;
    color: #242833;
    word-wrap: break-word;
    word-wrap: anywhere;
    overflow-wrap: anywhere;
    white-space: break-spaces;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.notClamped {
      -webkit-line-clamp: unset;
    }

    &.expanded {
      max-height: var(--text-height);
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    .seeMoreButton {
      padding: 0;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 150%;
      color: #10c5e9;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width:#{$breakpoint}) {
  .Text {
    .buttonContainer {
      justify-content: flex-start;
    }
  }
}
