.ColorPalette {
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);

  .colorPickerContainer,
  .colorInfoContainer {
    margin-bottom: 16px;
  }
}
