.UpcomingEvents {
  position: relative;
  min-height: 258px;
  padding-bottom: 24px;
  box-shadow: 0px 4px 60px rgba(78, 79, 114, 0.1);
  border-radius: 16px;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding-left: 16px;
    padding-right: 15px;
    background-color: #c1c5d6;
    margin-bottom: 14px;

    h3 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 22px;
      color: white;
    }

    .addButton {
      width: 20px;
      height: 20px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 1V9' stroke='%23242833' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M9 5L1 5' stroke='%23242833' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: 1.5px solid #242833;
      border-radius: 50%;
    }
  }

  .events {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .noData {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        width: 226px;
        font-family: 'Gilroy-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #c1c5d6;
      }
    }
  }

  .seeAll {
    position: absolute;
    right: 11px;
    bottom: 7px;
    border: none;
    padding: 0;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #c1c5d6;
    background-color: transparent;
  }
}

@media (max-width: 1024px) {
  .UpcomingEvents {
    padding-bottom: 33px;
    .header {
      h3 {
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .events {
      gap: 20px;
    }

    .seeAll {
      right: 13px;
      bottom: 13px;
    }
  }
}
