.Button {
  min-width: 94px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(
    228.41deg,
    #00e4ac 0%,
    #10c5e9 53.88%,
    #9240dc 106.71%
  );
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 32px;
  color: white;
  border-radius: 7px;
  border: none;

  &:disabled {
    background: #c1c5d6;
  }
}
