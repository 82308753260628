.Color {
  position: relative;
  width: 58px;
  height: 78px;
  background-color: white;
  border-radius: 6px;
  // overflow: hidden;
  filter: invert(100%);

  .overlay {
    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.8;
  }

  &.active {
    height: 80px;
    border: 2px solid transparent;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
    filter: none;
    overflow: hidden;

    .overlay {
      display: none;
    }
  }

  .color {
    height: 54px;
    border-radius: 6px 6px 0px 0px;
  }

  .code {
    display: flex;
    align-items: center;
    height: 24px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: white;
    border-radius: 0px 0px 6px 6px;
    border: 0.2px solid #dfdfdf;

    h4 {
      margin: 0;
      font-family: Gilroy-Regular;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px;
      text-align: center;
    }
  }
}

@media (max-width: 1024px) {
  .Color {
    width: unset;
    flex: 1;
  }
}
