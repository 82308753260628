.Table {
  // height: calc(100vh - 29px - 80px - 62px - 55px - 24px - 85px);
  height: calc(100vh - 80px - 20px - 53px - 37px - 24px - 45px - 85px - 36px);
  font-family: 'Gilroy-Regular';
  overflow-y: auto;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  &.noData {
    position: relative;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00e4ac;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  .noData {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 266px;

    span {
      width: 656px;
      font-family: Gilroy-SemiBold;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #c1c5d6;
      text-align: center;
    }
  }

  table {
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      z-index: 1001;
      height: 80px;
      background-color: #f2f4f8;

      tr {
        th {
          // padding-top: 21px;
          font-family: 'Gilroy-SemiBold';
          font-size: 14px;
          line-height: 24px;
          font-weight: normal;
          color: black;
          white-space: nowrap;

          &.notSortable {
            .thContainer {
              cursor: default;
            }
          }

          &.thHiringPipelineHeading {
            position: relative;
            padding-top: 0;
            font-size: 12px;

            .thContainer {
              justify-content: center;
              gap: 5px;
            }
          }

          &:first-child {
            padding-left: 36px;
          }

          .thContainer {
            display: flex;
            gap: 13px;
            cursor: pointer;

            &.withCheckbox {
              padding-right: 35px;
            }

            &.hiringPipeline {
              justify-content: center;
            }

            &.jobStatus {
              justify-content: center;
              padding-right: 20px;
            }

            &.daysFromCreation {
              justify-content: center;
              padding-left: 35px;
            }

            .columnName {
              position: relative;
              padding-right: 35px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        height: 40px;
        background-color: #f2f4f8;
        cursor: pointer;

        &:nth-child(odd) {
          background-color: white;
        }

        > td {
          padding-right: 20px;
        }

        td {
          font-family: 'Gilroy-Medium';
          font-size: 14px;
          line-height: 22px;
          word-break: break-word;
          color: #242833;

          &.short {
            width: 20px;
            padding-right: 30px;
          }

          &:first-child {
            padding-left: 36px;
          }

          &.hiringStagesContainer {
            width: 200px;
            padding-right: 46px;
          }

          &.countContainer {
            .count {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 38px;
              height: 25px;
              font-family: 'Gilroy-Regular';
              font-size: 14px;
              line-height: 20px;
              color: white;
              background-color: #00e4ac;
              border-radius: 50px;
            }
          }

          video {
            width: 98px;
            height: 110px;
            overflow: hidden;
            object-fit: cover;
            border-radius: 20px;
          }

          .rolesContainer {
            display: flex;
            flex-wrap: wrap;
            padding: 5px 0;
            gap: 10px;
          }

          .candidateNoteContainer {
            position: relative;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Table {
    height: auto;
    overflow-y: unset;
    overflow-x: auto;

    &.noData {
      min-height: 500px;
    }

    table {
      tbody {
        tr {
          td {
            padding-right: 15px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
