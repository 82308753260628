.Question {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 30px;

  .durationWithDeleteButtonContainer {
    display: flex;
    align-items: center;
  }

  .deleteIcon,
  .editIcon {
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    background-color: #ff2d55;
    border-radius: 50%;
    cursor: pointer;
  }

  .editIcon {
    margin-left: 15px;
    background-color: rgba(242, 244, 248, 1);

    &.active {
      background-color: #c1c5d6;
    }

    img {
      z-index: 1;
    }
  }

  &.jobPostForm {
    flex-direction: row;

    .vrQuestionContainer {
      flex: 1;
    }
  }

  .row {
    display: flex;
    align-items: center;

    .titleContainer {
      display: flex;
      align-items: flex-end;
      gap: 15px;

      .handle {
        margin-bottom: 5px;
      }
    }

    .numberInput {
      margin-left: 46px;
    }
  }
}

@media (max-width: 1024px) {
  .Question {
    &.jobPostForm {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    .row {
      flex-direction: column;
      width: 100%;
      .titleContainer {
        width: 100%;
      }
    }
  }
}
