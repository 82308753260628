.PasswordInput {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 5px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 20px;
    color: #242833;
  }

  .input {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 45px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #c1c5d6;
    outline: none;
    border: none;
    background: #f2f4f8;
    border-radius: 16px;
  }

  .passwordButton {
    position: absolute;
    top: 34px;
    right: 15px;
    padding: 0;
    background-color: transparent;
    border: none;
  }
}
