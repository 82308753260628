.Answer {
  display: flex;
  align-items: center;
  gap: 25px;

  .deleteIcon {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.99975 3.99582L8.7878 0.207778C9.06483 -0.0692594 9.514 -0.0692595 9.79104 0.207778C10.0681 0.484816 10.0681 0.933983 9.79104 1.21102L6.00299 4.99906L9.79233 8.7884C10.0694 9.06544 10.0694 9.5146 9.79233 9.79164C9.51529 10.0687 9.06612 10.0687 8.78908 9.79164L4.99975 6.00231L1.21102 9.79104C0.933983 10.0681 0.484816 10.0681 0.207778 9.79104C-0.0692595 9.514 -0.0692594 9.06483 0.207778 8.7878L3.99651 4.99906L0.209068 1.21162C-0.0679701 0.934586 -0.0679701 0.485419 0.209068 0.208381C0.486105 -0.0686568 0.935272 -0.0686568 1.21231 0.208381L4.99975 3.99582Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ff2d55;
    border-radius: 50%;
    cursor: pointer;
  }

  .editIcon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    padding: 0;
    font-size: 0;
    border: none;
    cursor: pointer;

    img {
      position: absolute;
      top: -6.5px;
    }
  }

  .prefix {
    display: flex;
    align-items: center;
    gap: 13px;
    .alphabet {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #c1c5d6;
    }
  }

  .inputContainer {
    flex: 1;
  }
}
