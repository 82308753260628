.ContactsList {
  .header {
    padding-left: 33px;
    margin-bottom: 15px;
    h3 {
      display: flex;
      align-items: center;
      gap: 6px;
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #c1c5d6;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
