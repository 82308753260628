@use '../../../../../../variables' as *;

.ReactionPicker {
  position: relative;
  z-index: 2;
  display: inline-block;
  align-self: flex-start;

  .trigger {
    cursor: pointer;
  }

  .emojiList {
    position: fixed;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    box-shadow: 0px 10.7329px 40.2484px rgba(78, 79, 114, 0.2);
    border-radius: 13.4161px;

    li {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  .ReactionPicker {
    .emojiList {
      transform: translate(-90px, -14px);
    }
  }
}
