.TimeDropdown {
  position: relative;
  flex: 1;

  .selector {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 20px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #c1c5d6;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11ZM11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM11.75 5C11.75 4.58579 11.4142 4.25 11 4.25C10.5858 4.25 10.25 4.58579 10.25 5V11C10.25 11.2841 10.4105 11.5438 10.6646 11.6708L14.6646 13.6708C15.0351 13.8561 15.4856 13.7059 15.6708 13.3354C15.8561 12.9649 15.7059 12.5144 15.3354 12.3292L11.75 10.5365V5Z' fill='%23C1C5D6'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 19px;
    background-color: #f2f4f8;
    border-radius: 16px;
    cursor: pointer;

    &.error {
      border: 1px solid #ff2d55;
    }

    .value {
      width: 85px;
      color: #242833;
      background: transparent;
      border: none;
      outline: none;
    }
  }

  .options {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: -12px;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 131px;
    height: 165px;
    margin: 0;
    padding: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow-y: auto;

    .option {
      height: 23px;
      padding-left: 16px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
      cursor: pointer;

      &.active {
        font-family: 'Gilroy-Bold';
        color: #00e4ac;
      }
    }
  }
}
