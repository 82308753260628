.RequestAccessPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 100%;

    h1 {
      width: 100%;
      margin-bottom: 20px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      text-align: left;
      color: #242833;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      .shortInputsContainer {
        display: flex;
        justify-content: space-between;

        .inputContainer {
          width: 180px;

          > div {
            > div {
              bottom: -40px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .RequestAccessPage {
    height: 675px;
  }
}
