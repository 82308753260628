.RightPanel {
  display: flex;
  flex-direction: column;
  width: 347px;
  height: calc(100vh - 80px);

  padding-top: 19px;
  padding-left: 24px;
  padding-right: 35px;
  background-color: white;
  overflow-y: auto;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .calendarContainer {
    margin-bottom: 31px;
  }

  .upcomingEventsContainer {
    margin-bottom: 20px;
  }

  .messagesAndFeedbackContainer {
    margin-bottom: 28px;
  }
}

@media (max-width: 1024px) {
  .RightPanel {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 20px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 15px;
    background-color: transparent;
    overflow-y: unset;

    .container {
      margin-bottom: 20px;
      background-color: white;
      border-radius: 16px;

      .calendarContainer {
        margin-bottom: 20px;
      }

      .upcomingEventsContainer {
        padding-left: 15px;
        padding-right: 15px;
      }

      .messagesAndFeedbackContainer {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .funnelAndJobsContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
