.QualifiedSwitch {
  display: flex;
  gap: 20px;
  width: 305px;
  height: 48px;
  margin-left: auto;
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  background-color: #f2f4f8;
  border-radius: 16px;
  cursor: pointer;

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex: 1;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #242833;

    &.active {
      font-family: 'Gilroy-Bold';
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 16px;

      .count {
        background-color: white;
        color: #242833;
      }
    }

    .count {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 22px;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      color: white;
      text-align: center;
      background-color: #c1c5d6;
      border-radius: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .QualifiedSwitch {
    margin-left: unset;
  }
}
