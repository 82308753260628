.ChipsDropdown {
  position: relative;
  &.optionsTop {
    .field {
      .searchAndOptions {
        // top: calc(var(--optionsHeight) - 10px + 24px);
        top: 0;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    .note {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;
    }

    .label {
      display: flex;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: rgba(193, 197, 214, 0.5);
      border-radius: 50%;
    }
  }

  .field {
    position: relative;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    min-height: 192px;
    padding: 15px;
    background-color: #f2f4f8;
    border-radius: 16px;
    overflow-y: auto;
    overflow-x: hidden;

    &.optionsVisible {
      overflow: visible;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00e4ac;
      border-radius: 6px;
    }

    .searchAndOptions {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      left: 0;
      z-index: 1001;
      background-color: transparent;

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: flex-end;
        width: 100%;
        height: 55px;
        margin-bottom: 5px;
        padding-left: 28px;
        padding-right: 13px;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);

        input {
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 19px;
          background-color: transparent;
          border: none;

          &::placeholder {
            color: #c1c5d6;
          }

          &:focus {
            outline: none;
          }
        }
        i {
          display: block;
          width: 32px;
          height: 32px;
          font-size: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.5' width='32' height='32' rx='16' fill='%23F2F4F8'/%3E%3Cpath d='M16 10H16.05' stroke='%23242833' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 22V14' stroke='%23242833' stroke-width='2.5' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          cursor: pointer;
        }
      }
    }

    .options {
      width: 100%;
      padding-top: 9px;
      padding-bottom: 9px;
      // padding-right: 9px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);

      ul {
        max-height: 269px;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        list-style: none;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          width: 4px;
          background-color: #00e4ac;
          border-radius: 6px;
        }

        li {
          display: flex;
          align-items: center;
          gap: 15px;
          height: 38px;
          padding-left: 20px;
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 24px;
          color: #242833;
          cursor: pointer;

          &.danger {
            color: #ff2d55;
          }

          &.active {
            font-size: 16px;
            line-height: 24px;
            color: #00e4ac;
          }

          &:hover {
            font-size: 18px;
            line-height: 24px;
            font-family: 'Gilroy-Bold';
            color: #141224;
            background-color: #f2f4f8;
          }
        }
      }
    }

    .buttonContent {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .error {
    color: #ff2d55;
  }
}
