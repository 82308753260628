@use '../../../../../variables' as *;

.PollEditor {
  .welcome {
    padding-top: 17px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 15px;
    color: #c1c5d6;
  }

  .buttons {
    display: flex;
    gap: 13px;
    padding-bottom: 18px;
    margin-top: 20px;

    .cancelButton {
      width: 94px;
      background-color: transparent;
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 32px;
      color: #c1c5d6;
      border: none;
    }
  }
}

@media (max-width: $breakpoint) {
  .PollEditor {
    .buttons {
      button {
        &:nth-child(2) {
          min-width: 126px;
          white-space: nowrap;
        }
      }
    }
  }
}
