.AddRole {
  .row {
    display: flex;
    gap: 70px;
    margin-bottom: 75px;

    .col {
      display: flex;
      flex-direction: column;
      gap: 77px;
      flex: 1;

      &:last-child {
        gap: 51px;
      }
    }

    .innerRow {
      display: flex;
      gap: 23px;

      .innerCol {
        display: flex;
        flex-direction: column;
        gap: 39px;

        &:last-child {
          gap: 25px;
        }

        .checkboxes {
          display: flex;
          flex-direction: column;
          gap: 30px;
          visibility: hidden;

          &.visible {
            visibility: visible;
          }
        }
      }
    }
  }
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  i {
    position: absolute;
    display: block;
    width: 10px;
    height: 18px;
    font-size: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='18' viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.66205 1.96981C10.1127 1.51921 10.1127 0.788633 9.66205 0.33803C9.21144 -0.112576 8.48087 -0.112576 8.03026 0.33803L0.337954 8.03034C-0.112652 8.48094 -0.112652 9.21152 0.337954 9.66212L8.03026 17.3544C8.48087 17.805 9.21144 17.805 9.66205 17.3544C10.1127 16.9038 10.1127 16.1732 9.66205 15.7226L2.78563 8.84623L9.66205 1.96981Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    cursor: pointer;

    &.prevIcon {
      left: 0;
    }

    &.nextIcon {
      right: 0;
      transform: rotate(180deg);
    }
  }
}
