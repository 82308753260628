.PrevColors {
  h3 {
    font-family: Gilroy-Regular;
    color: #242833;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 8px;
    min-height: 56px;

    .color {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: -4px;
          left: -4px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 2px solid #bdbdbe;
        }
      }
    }

    .noContent {
      width: 100%;
      height: 100%;
      padding-top: 9px;
      font-family: Gilroy-Regular;
      color: #c1c5d6;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
}

@media (max-width: 1024px) {
  .PrevColors {
    h3 {
      font-family: Gilroy-SemiBold;
    }
  }
}
