.popup {
  position: fixed;
  padding: 8px 0 5px;
  top: 0;
  left: 0;
  width: 180px;
  display: flex;
  flex-direction: column;
  background-color: white;
  opacity: 0;
  border-radius: 16px;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  
  .option {
    height: 40px;
    padding: 0 13px;
    display: flex;
    align-items: center;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: black;
    cursor: pointer;
  
    &:hover {
      font-size: 18px;
      font-family: 'Gilroy-Bold';
      background: #F5F6F6;
    }
  }
}

.active {
  z-index: 3;
  opacity: 1;
  pointer-events: all;
}