.JobSummary {
  width: 352px;
  padding-top: 12px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px -4px 60px rgba(78, 79, 114, 0.1);
  border-radius: 20px;

  h2 {
    margin: 0;
    margin-bottom: 31px;
    font-family: 'Gilroy-SemiBold';
    font-size: 20px;
    line-height: 150%;
  }

  .data {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 11px;

    .total {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 78px;
      height: 78px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      color: #10c5e9;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }

    .graphBorder {
      position: absolute;
      top: -2.5px;
      left: -2.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 167px;
      height: 167px;
      background-color: #f7f9fb;
      border-radius: 50%;
    }

    .graph {
      position: relative;
      height: 100%;
      border-radius: 50%;
    }

    .stats {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
      max-width: 131px;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: space-between;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 150%;
        color: #c1c5d6;

        div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .JobSummary {
    width: 100%;
    h2 {
    }

    .data {
      justify-content: space-between;
      .total {
      }

      .graphBorder {
      }

      .graph {
      }

      .stats {
        li {
        }
      }
    }
  }
}
