@import '../../../../../mixins';

.tab {
  width: 100%;
  max-width: 1067px;

  .jobs {
    display: grid;
    grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
    gap: 15px;
    width: 100%;
    max-width: 1067px;

    @include onMobile {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
