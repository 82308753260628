.ResumeCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;

  span {
    font-family: 'Gilroy-Regular';
    font-size: 10px;
    text-align: center;
    color: #707070;
  }
}
