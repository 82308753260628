@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.3);
  }
}

@keyframes scaleAnimationReverse {
  0% {
    transform: scale(1.3);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.LinkWrapper {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 10px;

  &.wasHovered {
    .LinkButton {
      &:hover {
        box-shadow: 0px 7px 18px rgba(41, 41, 41, 0.14902);
        animation: scaleAnimation 0.3s ease-in-out;
        transform: scale(1.3);
      }

      &:not(:hover) {
        animation: scaleAnimationReverse 0.3s ease-in-out;
        transform: scale(1);
      }
    }
  }

  .LinkButton {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &.disabled {
      cursor: auto;
    }
  }

  .ActiveButton {
    position: relative;
    margin-left: -5px;
    margin-right: -5px;

    .outer {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      border: 2px solid transparent;
      background: linear-gradient(
          223deg,
          #00e4ac 20.85%,
          #10c5e9 65.29%,
          #9240dc 100%
        )
        border-box;
      -webkit-mask: linear-gradient(white 0 0) padding-box,
        linear-gradient(white 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .inner {
      position: absolute;
      z-index: 122;
      left: 5px;
      top: 5px;
      width: 40px;
      height: 40px;
    }
  }
}
