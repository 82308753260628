.Dashboard {
  display: flex;
  height: calc(100vh - 80px);
  background-color: #f2f4f8;
}

@media (max-width: 1024px) {
  .Dashboard {
    flex-direction: column;
    height: auto;
    padding-top: 27px;
    padding-left: 15px;
    padding-right: 0;
    padding-bottom: 23px;
  }
}
