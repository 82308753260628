.LogoPreview {
  position: relative;
  flex: 1;
  min-width: 222px;
  max-height: 80px;
  border-radius: 6px;
  border: 2px solid transparent;

  .container {
    width: 100%;
    height: 100%;
    padding-top: 12px;
    padding-bottom: 14px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;
    border-radius: 6px;
  }

  &.active {
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
  }

  h4 {
    margin-bottom: 4px;
    font-family: National 2 Condensed;
    color: #242833;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    leading-trim: both;
    text-edge: cap;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    max-width: 82px;
    max-height: 24px;
  }
}

@media (max-width: 1024px) {
  .LogoPreview {
    width: 235px;
    max-width: 235px;
    min-width: unset;
    height: 80px;
    overflow: hidden;

    .container {
      max-height: 80px;
    }
  }
}
