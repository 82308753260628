.SearchResultCount {
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #242833;
  white-space: nowrap;
}
