.dialog {
  max-width: 898px;
}

.backdrop {
  background: rgba(37, 40, 51, 0.1);
  backdrop-filter: blur(1.5px);
}

.modalContent {
  position: relative;
  height: 685px;
  min-width: 898px;
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 16px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);

  .header {
    padding-top: 33px;
    padding-left: 35px;
    display: flex;

    div {
      h1 {
        margin: 0;
        font-family: 'Gilroy-SemiBold';
        font-size: 28px;
        line-height: 30px;
        color: #242833;
      }

      span {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
      }
    }

    .closeIcon {
      position: absolute;
      top: 36px;
      right: 32px;
      display: block;
      width: 23px;
      height: 23px;
      font-size: 0;
      background-image: url('../../../../../assets/images/modals/close-v2.svg');
      cursor: pointer;
    }
  }

  .container {
    max-height: 499px;
    margin-top: 14px;
    position: relative;
    // padding: 20px 10px 10px;

    border: 2px solid #f2f4f8;
    border-left: none;
    border-right: none;
    display: flex;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background-color: #f2f4f8;
      transform: translateX(-50%);
    }
  }

  .footer {
    position: absolute;
    left: 35px;
    right: 32px;
    bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftPanel {
      display: flex;
      align-items: center;
      gap: 121px;

      .reset {
        font-family: 'Gilroy-SemiBold';
        font-size: 14px;
        line-height: 36px;
        cursor: pointer;

        &.disabled {
          color: #c1c5d6;
          cursor: auto;
        }
      }

      .moveSelected {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        .arrow {
          margin-top: 2px;
          font-size: 20px;
        }
      }
    }

    .rightPanel {
      display: flex;
      gap: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .dialog {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    overflow-x: auto;
  }
}
