.ViewBlocking {
  display: flex;
  flex-direction: column;
  gap: 42px;
  .row {
    display: flex;
    gap: 70px;

    .col {
      flex: 1;
    }
  }
}

.deactivateButton {
  width: 250px;
  height: 52px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: white;
  background-color: #ff2d55;
  border: 1px solid #ff2d55;
  border-radius: 16px;

  &:disabled {
    color: #c1c5d6;
    background-color: transparent;
    border-color: #c1c5d6;
  }
}
