@use '../../../../variables' as var;

.Profile {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .row {
    width: 100%;
    display: flex;
    gap: 50px;

    &.buttonsRow {
      flex: 1;
      justify-content: flex-end;
      margin-top: 50px;
    }

    .title {
      margin-bottom: 30px;
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      color: #242833;
    }

    .avatar {
      max-width: 120px;
      margin-bottom: 30px;
      display: flex;
      gap: 56px;

      .container {
        position: relative;
        &:hover {
          &.animateHover {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: var.$light-pink;
              opacity: 0.1;
              border-radius: 50%;
              box-shadow: inset 1px 1px 1px;
            }
          }
          .deletePhoto {
            transform: rotate(90deg);
            background-color: var.$light-pink;
            .crossIcon {
              background-image: url('../../../../assets/images/common/close-icons/cross-white.svg');
            }
          }
        }

        .photo,
        .addPhoto {
          position: relative;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          object-fit: cover;
        }

        .deletePhoto {
          position: absolute;
          top: 12px;
          right: 0;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: var.$light-gray-color;
          cursor: pointer;
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out;
          .crossIcon {
            height: 12px;
            width: 12px;
            background-image: url('../../../../assets/images/common/close-icons/cross-black.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
          }
        }

        .addPhoto {
          display: flex;
          padding-top: 48px;
          align-items: center;
          border: 2px dashed #c1c5d6;
          background: white;
          font-family: 'Gilroy-Bold';
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #c1c5d6;
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            top: 25px;
            left: 50%;
            width: 30px;
            height: 30px;
            transform: translateX(-50%);
            background: url('../../../../assets/images/dashboard/profile-settings/addIcon.svg');
          }
        }

        .file {
          display: none;
        }
        .crossWrapper {
          position: absolute;
          top: 12px;
          right: -6px;
        }
      }
    }
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .emailDisabled {
      position: relative;
      pointer-events: none;
    }
  }

  .noActive {
    pointer-events: none;
  }

  .buttons {
    margin-top: auto;
    left: 50%;
    bottom: 0;
    display: flex;
    gap: 30px;
  }
}

@media (max-width: 1024px) {
  .Profile {
    .row {
      flex-direction: column;
    }

    .column {
      gap: 50px;
    }
  }
}
