.Country {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
  gap: 15px;
  cursor: pointer;

  &.isActive {
    background-color: #f2f4f8;
  }

  &:hover {
    background-color: #f2f4f8;
  }

  img {
    width: 20px;
    height: 14px;
  }

  .name,
  .code {
    padding-top: 4px;
    font-family: 'National 2 Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .code {
    position: absolute;
    right: 0;
    color: var(--gray-color);
  }
}
