.SectionTitle {
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;
    color: #242833;
  }

  .note {
    margin-left: 10px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
  }

  .info {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .SectionTitle {
    &.column {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      div {
        position: absolute;
        right: -15px;
      }

      .note {
        margin-left: 0;
      }
    }

    h1 {
      white-space: nowrap;
    }

    .note {
      font-size: 12px;
    }

    .info {
    }
  }
}
