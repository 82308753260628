.CandidatesCol {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 332px;
  height: calc(100vh - 80px - 37px - 154px - 20px - 53px - 95px - 46px - 35px);
  max-height: calc(
    100vh - 80px - 37px - 154px - 20px - 53px - 95px - 46px - 35px
  );
  min-height: 320px;
  padding: 15px;
  padding-right: 0;
  background-color: #f2f4f8;
  border-radius: 16px;

  .sectionContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    scrollbar-color: #00e4ac transparent;
    scrollbar-width: thin;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00e4ac;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    .viewAllButton {
      width: 302px;
      min-height: 40px;
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
      background-color: white;
      border-radius: 8px;
      border: none;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 302px;
    min-height: 40px;
    padding-left: 16px;
    padding-right: 21px;
    background-color: white;
    border-radius: 8px;

    h2,
    .count {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }
  }
}

@media (max-width: 1024px) {
  .CandidatesCol {
    min-width: 320px;
    height: unset;
    max-height: unset;
    min-height: unset;
    padding: 28px;
    padding-right: 0;

    .sectionContainer {
      .viewAllButton {
        width: 264px;
      }
    }

    .header {
      width: 264px;
      padding-left: 17px;
      padding-right: 13px;
    }
  }
}
