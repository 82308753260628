.ApplicantsGraph {
  position: relative;
  min-width: 354px;
  height: 280px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  // gap: 15px;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .graphs {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .noData {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: 'Gilroy-SemiBold';
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #c1c5d6;
    transform: translate(-50%, -50%);
  }

  .title {
    margin-bottom: 15px;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;

    &::after {
      content: ' (Top 4)';
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 20px;
    }
  }

  .percents {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    display: flex;
    justify-content: space-between;

    .percent {
      width: 24px;
      font-family: 'Gilroy-Regular';
      font-size: 10px;
      line-height: 20px;
      opacity: 0.7;
    }
  }
}

@media (max-width: 1024px) {
  .ApplicantsGraph {
    min-width: unset;

    .noData {
      width: 139px;
      font-family: 'Gilroy-Bold';
      font-size: 16px;
    }
  }
}
