.Slider {
  height: 100%;
  display: flex;
  overflow: hidden;

  .container {
    max-width: 100%;
    display: flex;
    overflow: hidden;

    a {
      flex-shrink: 0;
      width: 100%;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 16px;
        cursor: pointer;
      }
    }
  }


  .dots {
    position: absolute;
    left: 50%;
    bottom: 18px;
    display: flex;
    gap: 20px;
    transform: translateX(-50%);

    .dot {
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;

      &.current {
        width: 25px;
        background: #0FC7E6;
        box-shadow: 0px 7px 18px rgba(41, 41, 41, 0.14902);
        border-radius: 36px;
      }
    }
  }
  
}