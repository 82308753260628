.TypeSwitch {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 575px;
  height: 45px;
  min-height: 45px;
  padding-left: 9px;
  padding-right: 6px;
  justify-content: space-between;
  background-color: white;
  border-radius: 16px;

  .zone {
    display: none;
  }

  .view {
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 16px;
    // transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
      background: #c1c5d6;

      .counter {
        background: white;
        color: black;
      }

      .text {
        color: white;
      }
    }

    .text {
      font-family: Gilroy-Bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #c1c5d6;
    }

    .counter {
      min-width: 30px;
      width: 30px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;
      background: #f2f4f8;
      border-radius: 16px;
      // transition: background-color 0.3s, color 0.3s;
    }
  }

  .active {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );

    &:hover {
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
    }

    .text {
      color: white;
    }

    .counter {
      color: #c1c5d6;
      background: white;
      border-radius: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .TypeSwitch {
    max-width: unset;
  }
}
