.JobStatus {
  padding-right: 20px;

  .jobStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100px;
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 160%;
    color: white;
    white-space: nowrap;
    background-color: #c1c5d6;
    border-radius: 6px;

    &.green {
      background-color: #00e4ac;
    }

    &.orange {
      background-color: #ffbf00;
    }
  }
}
