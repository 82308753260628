.SocialButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 17px;
  padding-bottom: 16px;

  h4 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 24px;
  }

  .buttons {
    display: flex;
    gap: 20px;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 33px;
      }
    }
  }
}
