.DateRange {
  position: relative;
  display: flex;
  gap: 107px;

  &:focus {
    outline: none;
    .dateContainer {
      .value {
        background-color: white;
      }
    }
  }

  &.readonly {
    pointer-events: none;
  }

  &.staticErrorPositon {
    flex-direction: column;
    .error,
    .warning {
      position: static;
      margin-top: 6px;
    }
  }

  &.withFieldWidth {
    gap: 0;
    justify-content: space-between;
  }

  &.withCalendarPosition {
    .calendarContainer {
      position: absolute;
      top: unset;
      left: unset;
      bottom: unset;
      right: 0;
    }
  }

  .error,
  .warning {
    position: absolute;
    z-index: 0;
    bottom: -24px;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 18px;
    color: #ff2d55;
  }

  .warning {
    color: #ffbf00;
  }

  .dateContainer {
    width: 269px;

    .placeholder {
      color: #c1c5d6;
    }

    h2 {
      margin: 0;
      margin-bottom: 5px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }

    .value {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding-left: 20px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      color: #242833;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5 1.71094C14.5 1.29672 14.1642 0.960938 13.75 0.960938C13.3358 0.960938 13 1.29672 13 1.71094V2.96094H6.5V1.71094C6.5 1.29672 6.16421 0.960938 5.75 0.960938C5.33579 0.960938 5 1.29672 5 1.71094V2.96094H2.75C1.23122 2.96094 0 4.19215 0 5.71094V9.71094V19.7109C0 21.2297 1.23122 22.4609 2.75 22.4609H16.75C18.2688 22.4609 19.5 21.2297 19.5 19.7109V9.71094V5.71094C19.5 4.19215 18.2688 2.96094 16.75 2.96094H14.5V1.71094ZM18 8.96094V5.71094C18 5.02058 17.4404 4.46094 16.75 4.46094H14.5V5.71094C14.5 6.12515 14.1642 6.46094 13.75 6.46094C13.3358 6.46094 13 6.12515 13 5.71094V4.46094H6.5V5.71094C6.5 6.12515 6.16421 6.46094 5.75 6.46094C5.33579 6.46094 5 6.12515 5 5.71094V4.46094H2.75C2.05964 4.46094 1.5 5.02058 1.5 5.71094V8.96094H18ZM1.5 10.4609H18V19.7109C18 20.4013 17.4404 20.9609 16.75 20.9609H2.75C2.05964 20.9609 1.5 20.4013 1.5 19.7109V10.4609ZM9.75 12.9609C10.1642 12.9609 10.5 13.2967 10.5 13.7109V14.9609H11.75C12.1642 14.9609 12.5 15.2967 12.5 15.7109C12.5 16.1252 12.1642 16.4609 11.75 16.4609H10.5V17.7109C10.5 18.1252 10.1642 18.4609 9.75 18.4609C9.33579 18.4609 9 18.1252 9 17.7109V16.4609H7.75C7.33579 16.4609 7 16.1252 7 15.7109C7 15.2967 7.33579 14.9609 7.75 14.9609H9V13.7109C9 13.2967 9.33579 12.9609 9.75 12.9609Z' fill='%23C1C5D6'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center right 22px;
      background-color: #f2f4f8;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  .calendarContainer {
    position: fixed;
    z-index: 1000;
    top: var(--top);
    left: var(--left);
    bottom: var(--bottom);
  }

  .calendarContainerPopup {
    position: absolute;
    top: 100px;
  }
}
