@use '../../../../variables' as *;

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(242, 244, 248, 0);
  }
}

.Feed {
  .pulseAnimation {
    animation: pulse-animation 2s infinite;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 26px;
    padding-right: 7.5px;
    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 20px;
      line-height: 14px;
      color: #242731;
    }

    .filters {
      display: flex;
      gap: 20px;

      .newPostCountButton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;
        width: 181px;
        height: 48px;
        font-family: 'Gilroy-Medium';
        font-size: 16px;
        line-height: 150%;
        color: white;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 16px;
        border: none;
      }
    }
  }

  .content {
    position: relative;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 7.5px;
    min-height: 111px;
    // max-height: calc(100vh - 80px - 303px - 20px - 97px - 20px - 48px - 40px);
    /*  overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin; */

    .posts {
      display: flex;
      flex-direction: column;
      gap: 28px;
      padding-bottom: 20px;
    }

    .noContent {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-left: 26px;
      padding-right: 7.5px;
      letter-spacing: 0.01em;
      color: #c1c5d6;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-family: 'Gilroy-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        box-shadow: 0px -4px 60px rgba(78, 79, 114, 0.1);
        border-radius: 16px;
      }
    }
  }
}

@media (max-width: $breakpoint) {
  .Feed {
    .header {
      gap: 15px;
      padding-left: 15px;
      h2 {
        font-size: 16px;
        line-height: 20px;
      }

      .filters {
        gap: 15px;

        .newPostCountButton {
          gap: 5px;
          width: 63px;
          padding-left: 15px;
          padding-right: 15px;
          border-radius: 8px;
          font-size: 18px;
        }
      }
    }

    .content {
      .posts {
        gap: 15px;
      }

      .noContent {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
      }
    }
  }
}
