.Button {
  width: 180px;
  height: 45px;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 20px;
  color: #f2f4f8;
  background: #c1c5d6;
  border: none;
  border-radius: 16px;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;

  &:hover {
    background: #c1c5d6;
    color: white;
    border-color: transparent;
  }

  &:active {
    background: #242833;
  }

  &.save {
    color: white;
    background: #00e4ac;
    border-color: transparent;

    &:hover {
      color: #00e4ac;
      background: white;
      border-color: #00e4ac;
    }

    &:disabled {
      color: #c1c5d6;
      background: white;
      border-color: #c1c5d6;
    }

    &:active {
      color: white;
      border: none;
      background: #242833;
    }
  }
}

@media (max-width: 1024px) {
  .Button {
    width: 100%;
  }
}
