.RequestNewPasswordPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    height: 100%;

    form {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .RequestNewPasswordPage {
    height: 567px;
  }
}
