.Jobs {
  height: 480px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 163px;
  gap: 15px;
  overflow: hidden;
  overflow-y: auto;

  &.scroll {
    margin-right: 4px;
    padding-right: 6px;
  }

  &.noFavorites {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
}

@media (max-width: 1024px) {
  .Jobs {
    height: calc(100dvh - 121px - 48px - 165px - 25px);
    display: flex;
    flex-direction: column;
  }
}
