.AnimatedText {
  position: relative;

  &:hover {
    &::after {
      animation: growAndBounce 0.6s ease-in-out forwards;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 0;
    height: 1px;
    background-color: #242833;
  }
}

@keyframes growAndBounce {
  0% {
    width: 0;
  }
  20% {
    width: 125%;
  }
  40% {
    width: 90%;
  }
  60% {
    width: 105%;
  }
  80% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
