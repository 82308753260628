.Stat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100px;
  padding: 12px;
  padding-right: 25px;
  background-color: white;
  border-radius: 12px;

  &.active {
    box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);

    .header {
      .title {
        h2 {
          font-family: 'Gilroy-Bold';
          color: black;
          white-space: nowrap;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    /*    .graph {
      position: relative;
      top: -4px;
      width: 49px;
      height: 28px;

      &.purple {
        background-image: url("data:image/svg+xml,%3Csvg width='49' height='28' viewBox='0 0 49 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 1C45.0094 7.5 42.1894 15.7979 36.187 15.7979C28.8618 15.9971 27.3645 8.60639 21.0841 9.02128C10.4673 9.02128 14.3552 27 -1.43051e-06 27' stroke='url(%23paint0_linear_1491_8309)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1491_8309' x1='48' y1='1' x2='7.40341' y2='35.6692' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.125' stop-color='%236722D2' stop-opacity='0'/%3E%3Cstop offset='0.488932' stop-color='%236722D2'/%3E%3Cstop offset='1' stop-color='%236722D2' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      }

      &.orange {
        background-image: url("data:image/svg+xml,%3Csvg width='49' height='28' viewBox='0 0 49 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 1C45.0094 7.5 42.1894 15.7979 36.187 15.7979C28.8618 15.9971 27.3645 8.60639 21.0841 9.02128C10.4673 9.02128 14.3552 27 -1.43051e-06 27' stroke='url(%23paint0_linear_1491_15333)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1491_15333' x1='48' y1='1' x2='7.40341' y2='35.6692' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.125' stop-color='%23F6921E' stop-opacity='0'/%3E%3Cstop offset='0.488932' stop-color='%23F6921E'/%3E%3Cstop offset='1' stop-color='%23F6921E' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      }

      &.red {
        background-image: url("data:image/svg+xml,%3Csvg width='49' height='28' viewBox='0 0 49 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 1C45.0094 7.5 42.1894 15.7979 36.187 15.7979C28.8618 15.9971 27.3645 8.60639 21.0841 9.02128C10.4673 9.02128 14.3552 27 -1.43051e-06 27' stroke='url(%23paint0_linear_1491_3480)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1491_3480' x1='48' y1='1' x2='7.40341' y2='35.6692' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.125' stop-color='%23FF2D55' stop-opacity='0'/%3E%3Cstop offset='0.488932' stop-color='%23FF2D55'/%3E%3Cstop offset='1' stop-color='%23FF2D55' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      }

      &.green {
        background-image: url("data:image/svg+xml,%3Csvg width='49' height='28' viewBox='0 0 49 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 1C45.0094 7.5 42.1894 15.7979 36.187 15.7979C28.8618 15.9971 27.3645 8.60639 21.0841 9.02128C10.4673 9.02128 14.3552 27 -1.43051e-06 27' stroke='url(%23paint0_linear_1491_15739)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1491_15739' x1='48' y1='1' x2='7.40341' y2='35.6692' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.125' stop-color='%2300E4AC' stop-opacity='0'/%3E%3Cstop offset='0.488932' stop-color='%2300E4AC'/%3E%3Cstop offset='1' stop-color='%2300E4AC' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      }

      &.blue {
        background-image: url("data:image/svg+xml,%3Csvg width='49' height='28' viewBox='0 0 49 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 1C45.0094 7.5 42.1894 15.7979 36.187 15.7979C28.8618 15.9971 27.3645 8.60639 21.0841 9.02128C10.4673 9.02128 14.3552 27 -1.43051e-06 27' stroke='url(%23paint0_linear_1491_15748)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1491_15748' x1='48' y1='1' x2='7.40341' y2='35.6692' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.125' stop-color='%2310C5E9' stop-opacity='0'/%3E%3Cstop offset='0.488932' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%2310C5E9' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      }
    } */

    .title {
      display: flex;
      flex-direction: column;
      h2 {
        margin: 0;
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
        line-height: 24px;
        color: #707070;
      }

      span {
        font-family: 'Gilroy-Regular';
        font-size: 10px;
        line-height: 8px;
        color: #c1c5d6;
        white-space: nowrap;
      }
    }
  }

  .details {
    display: flex;
    justify-content: space-between;

    .value {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #6941ff;
    }

    .dynamic {
      display: flex;
      flex-direction: column;

      .dynamicValue {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #10c5e9;
      }

      .info {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #959595;
        white-space: nowrap;
      }
    }
  }
}
