.FilterTabs {
  border-bottom: 1.5px solid #c1c5d6;
  .list {
    display: flex;
    justify-content: space-between;
    max-width: 1127px;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &.active {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -17px;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          background-color: #00e4ac;
        }

        .title {
          color: #242833;
        }

        .count {
          background-color: #00e4ac;
        }
      }

      .title {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 16px;
        line-height: 20px;
        color: #c1c5d6;
      }

      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 48px;
        width: 48px;
        height: 28px;
        font-family: 'Gilroy-SemiBold';
        font-size: 14px;
        line-height: 24px;
        color: white;
        background-color: #c1c5d6;
        border-radius: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .FilterTabs {
    width: 100%;

    .list {
      li {
        .title {
          white-space: nowrap;
        }
      }
    }
  }
}
