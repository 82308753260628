.dialog {
  max-width: 826px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 16px;
  border: none;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}

.InviteToJobModal {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 33px;
    border-radius: 16px;

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 30px;

      h2 {
        margin: 0;
        font-family: 'Gilroy-SemiBold';
        font-size: 35px;
        line-height: 36px;
        color: #242833;
      }

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.9142 5.02175L9.9194 1.01654C10.2123 0.723652 10.6872 0.723652 10.9801 1.01654C11.273 1.30944 11.273 1.78431 10.9801 2.07721L6.97486 6.08241L10.9801 10.0876C11.273 10.3805 11.273 10.8554 10.9801 11.1483C10.6872 11.4412 10.2123 11.4412 9.9194 11.1483L5.9142 7.14307L1.90899 11.1483C1.6161 11.4412 1.14123 11.4412 0.848332 11.1483C0.555439 10.8554 0.555439 10.3805 0.848332 10.0876L4.85354 6.08241L0.848332 2.07721C0.555439 1.78431 0.555439 1.30944 0.848332 1.01655C1.14123 0.723652 1.6161 0.723652 1.90899 1.01655L5.9142 5.02175Z' fill='%23242833'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgba(193, 197, 214, 0.5);
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .candidateInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 30px;
      h3 {
        margin-bottom: 4px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #3c3c43;
      }

      .candidateName {
        margin-bottom: 4px;
        font-family: 'Gilroy-SemiBold';
        font-size: 35px;
        line-height: 36px;
        color: #242833;
      }

      .location {
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #3c3c43;
      }
    }

    .textareaContainer {
      margin-bottom: 48px;
      .label {
        h4 {
          margin-bottom: 2px;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: #3c3c43;
        }

        .sublabel {
          display: flex;
          justify-content: space-between;

          span {
            font-family: 'Gilroy-Regular';
            font-size: 14px;
            line-height: 20px;
            color: #242833;
          }

          button {
            padding: 0;
            font-family: 'Gilroy-Bold';
            font-size: 16px;
            line-height: 24px;
            color: #00e4ac;
            background-color: transparent;
            border: none;
          }
        }
      }
    }

    .jobsDropdownContainer {
      width: 100%;
      margin-bottom: 31px;
    }

    .buttons {
      display: flex;
      gap: 23px;
    }
  }
}
