.JobFormSteps {
  position: relative;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 16px 16px 0 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    display: block;
    height: 2px;
    background-color: #f2f4f8;
  }

  ul {
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 24px;

      &.active {
        span {
          color: #242833;
        }

        &::after {
          background: linear-gradient(
            228.41deg,
            #00e4ac 0%,
            #10c5e9 53.88%,
            #9240dc 106.71%
          );
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 4px;
        background-color: #c1c5d6;
        border-radius: 50px;
      }

      span {
        color: #c1c5d6;
      }

      .stepNumber {
        margin-bottom: 5px;
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 20px;
      }

      .title {
        margin-bottom: 10px;
        font-family: 'Gilroy-Bold';
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
      }

      .details {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 1024px) {
  .JobFormSteps {
    ul {
      scrollbar-width: none;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        min-width: 331px;
        &.active {
          span {
          }

          &::after {
          }
        }

        &::after {
        }

        span {
        }

        .stepNumber {
        }

        .title {
        }

        .details {
        }
      }
    }
  }
}
