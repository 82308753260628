@use '../../../../../../variables' as *;

.Files {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 13px;

  .files {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    flex: 1;
    min-height: 130px;
    padding-top: 12px;
    padding-left: 17px;
    padding-right: 19px;
    padding-bottom: 12px;
    background-color: #f2f4f8;
    border-radius: 16px;
  }

  .uploader {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 95px;
    padding-left: 17px;
    padding-right: 55px;
    background-color: #f2f4f8;
    border-radius: 16px;

    input {
      display: none;
    }

    .uploadButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-family: 'Gilroy-Medium';
      font-size: 12px;
      color: #242833;
      background-color: white;
      border-radius: 16px;
      border: none;
    }

    .dropzone {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 60px;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;
      color: #c1c5d6;
      background-color: white;
      border: 1px dashed #c1c5d6;
      border-radius: 16px;
    }
  }
}

@media (max-width: $breakpoint) {
  .Files {
    .files {
      gap: 11px;
      min-height: unset;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .uploader {
      gap: 10px;
      height: 90px;
      padding-left: 13px;
      padding-right: 12px;

      .dropzone {
        font-size: 12px;
      }
    }
  }
}
