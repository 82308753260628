.Dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: all cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;

  &.active {
    width: 25px;
    background-color: #0fc7e6;
    box-shadow: 0px 7px 18px rgba(41, 41, 41, 0.14902);
    border-radius: 36px;
  }
}
