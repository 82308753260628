.Ads {
  h2 {
    margin-bottom: 15px;
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 25px;
    color: #242731;
  }

  .content {
    position: relative;
    height: 449px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(78, 79, 114, 0.1);
    border-radius: 16px;
  }
}
