.UrlPreview {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  height: 570px;
  border-radius: 16px;
  overflow: hidden;

  .noLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: Gilroy-Bold;
    font-size: 16px;
    color: #c1c5d6;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-align: center;
    background-color: #f2f4f8;
  }

  iframe {
    flex: 1;
  }
}
