.FilterDropdown {
  position: relative;
  height: 24px;

  .selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 24px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #c1c5d6;
    background-color: #f2f4f8;
    border-radius: 12px;
    cursor: pointer;
  }
}
