.views {
  position: absolute;
  height: 100%;
  width: 100%;

  svg {
    position: absolute;
    bottom: 0;
    overflow: visible;
    top: 100%;

    path {
      cursor: pointer;
    }

    circle {
      visibility: hidden;
    }
  }

  svg:hover circle {
    visibility: visible;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .views {
    svg {
      circle {
        // visibility: hidden !important;
      }
    }
  }
}
