.ViewJobTemplate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .flexContainer {
    display: flex;
    gap: 70px;
    padding-bottom: 34px;
  }

  .col {
    display: flex;
    flex-direction: column;
    gap: 58px;
    flex: 1;
  }

  .templateStatus {
    position: absolute;
    top: 47px;
    right: 56px;
    display: flex;
    align-items: center;
    width: 150px;
    height: 48px;
    padding-left: 20px;
    border: 1px solid #c1c5d6;
    border-radius: 16px;
  }

  .viewTemplateButton {
    width: 200px;
    min-height: 52px;
    align-self: center;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: white;
    background-color: #242833;
    border-radius: 16px;
    border: none;
  }
}
