.Icons {
  position: absolute;
  top: 0;
  right: -25px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  &.addCandidateModal {
    top: 20px;
    right: 0;
  }

  .icon {
    display: block;
    width: 50px;
    height: 50px;
    background-color: rgba(243, 244, 248, 1);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    border: none;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      background-color: #c2c5d5;
    }
  }

  .search {
    position: relative;
    background-image: url('../../../../../assets/images/dashboard/candidate/wordResume/search.svg');

    .searchBox {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .searchInput {
      padding-left: 48px;
      padding-right: 48px;
      position: absolute;
      width: 336px;
      height: 60px;
      top: -60px;
      left: -345px;
      outline: none;
      border: 0;
      background: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18C15.4183 18 19 14.4183 19 10C19 5.58172 15.4183 2 11 2C6.58172 2 3 5.58172 3 10C3 14.4183 6.58172 18 11 18Z' stroke='url(%23paint0_linear_159_1320)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 20.0004L16.65 15.6504' stroke='url(%23paint1_linear_159_1320)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_159_1320' x1='11' y1='-7.01297' x2='-7.02594' y2='8.98703' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_159_1320' x1='18.825' y1='13.2' x2='13.9242' y2='17.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 15px center;
      box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
    }
    .clearIcon {
      position: absolute;
      top: -45px;
      left: -50px;
      display: block;
      width: 32px;
      height: 32px;
      font-size: 0;
      background-color: #f2f4f8;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.9142 5.02517L9.9194 1.01996C10.2123 0.72707 10.6872 0.72707 10.9801 1.01996C11.273 1.31286 11.273 1.78773 10.9801 2.08062L6.97486 6.08583L10.9801 10.091C11.273 10.3839 11.273 10.8588 10.9801 11.1517C10.6872 11.4446 10.2123 11.4446 9.9194 11.1517L5.9142 7.14649L1.90899 11.1517C1.6161 11.4446 1.14123 11.4446 0.848332 11.1517C0.555439 10.8588 0.555439 10.3839 0.848332 10.091L4.85354 6.08583L0.848332 2.08062C0.555439 1.78773 0.555439 1.31286 0.848332 1.01996C1.14123 0.72707 1.6161 0.72707 1.90899 1.01996L5.9142 5.02517Z' fill='%23242833'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 38px;
      cursor: pointer;
    }
  }

  .download {
    background-image: url('../../../../../assets/images/dashboard/candidate/wordResume/download.svg');
  }

  .print {
    background-image: url('../../../../../assets/images/dashboard/candidate/wordResume/print.svg');
  }
}

@media (max-width: 1024px) {
  .Icons {
    right: 0;
    flex-direction: row;

    &.addCandidateModal {
      .icon {
        width: 50px;
        height: 50px;
        background-size: unset;
      }
    }

    .icon {
      width: 30px;
      height: 30px;
      background-size: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
