.Buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

@media (max-width: 1024px) {
  .Buttons {
    flex-direction: column;

    button {
      width: 100% !important;
    }
  }
}
