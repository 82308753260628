.JobForm {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 36px;
}

@media (max-width: 1024px) {
  .JobForm {
    padding-left: 0;
    padding-right: 0;
  }
}
