.popup {
  position: absolute;
  left: calc(162px - 24px);
  z-index: 5;
  width: max-content;
  height: 136px;
  padding: 7px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  color: #242833;
  background: white;
  box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 8px;

  p {
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: -6px;
    top: -18px;
    z-index: -1;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid white;
    transform: rotate(135deg);
    border-radius: 0 0 0 50%;
  }

  .regular {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
  }

  .spaceBetween {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
}

.popupLeft {
  left: auto;
  right: calc(162px - 24px);

  &::before {
    content: '';
    position: absolute;
    left: auto;
    right: -6px;
    top: -18px;
    z-index: -1;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid white;
    transform: rotate(45deg);
    border-radius: 50% 0 0 0;
  }
}

.month {
  left: 50px;
}

.monthPopupLeft {
  left: auto;
  right: 50px;

  &::before {
    content: '';
    position: absolute;
    left: auto;
    right: -6px;
    top: -18px;
    z-index: -1;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid white;
    transform: rotate(45deg);
    border-radius: 50% 0 0 0;
  }
}

.day {
  left: 15px;
  top: 100%;
}