.PhoneInput {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    margin-bottom: 5px;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #c1c5d6;
  }

  .error {
    margin-top: 6px;
    font-size: 14px;
    line-height: 18px;
    color: #ff2d55;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: 45px;
    border-radius: 16px;
    overflow: hidden;

    .country {
      position: absolute;
      width: 24px;
      height: 18px;
      left: 15px;
      padding: 0;
      border: none;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .dropdown {
      height: 100%;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      gap: 15px;
      padding-left: 15px;
      background-color: #f2f4f8;
      cursor: pointer;
      border-radius: 16px;
    }

    .search {
      position: absolute;
      left: 15px;
    }
  }

  input {
    padding-left: 50px;
    padding-right: 15px;
    height: 100%;
    width: 100%;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 16px;
    border: none;

    &::placeholder {
      color: #c1c5d6;
    }

    &:focus {
      outline: none;
    }
  }
}
