.InactiveOverlay {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  backdrop-filter: invert(100%);
  border-radius: 6px;
  overflow: hidden;

  &.visible {
    display: block;
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;

    overflow: hidden;
  }
}
