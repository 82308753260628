@keyframes widthAnimationInput {
  0% {
    width: 187px;
  }
  25% {
    width: 260px;
  }
  50% {
    width: 300px;
  }
  75% {
    width: 330px;
  }
  100% {
    width: 316px;
  }
}

.SearchInput {
  position: relative;
  width: 187px;
  height: 48px;
  padding-left: 48px;
  padding-right: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18C15.4183 18 19 14.4183 19 10C19 5.58172 15.4183 2 11 2C6.58172 2 3 5.58172 3 10C3 14.4183 6.58172 18 11 18Z' stroke='url(%23paint0_linear_159_1320)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 20.0004L16.65 15.6504' stroke='url(%23paint1_linear_159_1320)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_159_1320' x1='11' y1='-7.01297' x2='-7.02594' y2='8.98703' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_159_1320' x1='18.825' y1='13.2' x2='13.9242' y2='17.55' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2300E4AC'/%3E%3Cstop offset='0.504968' stop-color='%2310C5E9'/%3E%3Cstop offset='1' stop-color='%239240DC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 15px center;
  border: 1px solid #c1c5d6;
  border-radius: 16px;

  &.focused {
    width: 316px;
    padding-right: 54px;
    border: none;
    box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.25);
    animation: widthAnimationInput 0.7s ease-in-out;
  }

  .clearIcon {
    position: absolute;
    top: 7px;
    right: 8px;
    display: block;
    width: 32px;
    height: 32px;
    font-size: 0;
    background-color: #f2f4f8;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.9142 5.02517L9.9194 1.01996C10.2123 0.72707 10.6872 0.72707 10.9801 1.01996C11.273 1.31286 11.273 1.78773 10.9801 2.08062L6.97486 6.08583L10.9801 10.091C11.273 10.3839 11.273 10.8588 10.9801 11.1517C10.6872 11.4446 10.2123 11.4446 9.9194 11.1517L5.9142 7.14649L1.90899 11.1517C1.6161 11.4446 1.14123 11.4446 0.848332 11.1517C0.555439 10.8588 0.555439 10.3839 0.848332 10.091L4.85354 6.08583L0.848332 2.08062C0.555439 1.78773 0.555439 1.31286 0.848332 1.01996C1.14123 0.72707 1.6161 0.72707 1.90899 1.01996L5.9142 5.02517Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 38px;
    cursor: pointer;
  }

  .searchHistory {
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    z-index: 1002;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 29px;
    list-style: none;
    background-color: white;
    border-radius: 16px;
    filter: drop-shadow(0px -4px 60px rgba(62, 63, 99, 0.1));

    li {
      padding-left: 45px;
      padding-right: 10px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0001 19.75C16.3849 19.75 20.7501 15.3848 20.7501 10C20.7501 4.61522 16.3849 0.25 11.0001 0.25C5.61532 0.25 1.2501 4.61522 1.2501 10C1.2501 12.3554 2.09097 14.5746 3.56702 16.3107H1.31812C0.903902 16.3107 0.568115 16.6464 0.568115 17.0607C0.568115 17.4749 0.903901 17.8107 1.31812 17.8107L5.56076 17.8107C5.97497 17.8107 6.31076 17.4749 6.31076 17.0607V12.818C6.31076 12.4038 5.97497 12.068 5.56076 12.068C5.14654 12.068 4.81076 12.4038 4.81076 12.818V15.4556C3.49915 13.9698 2.7501 12.0466 2.7501 10C2.7501 5.44365 6.44375 1.75 11.0001 1.75C15.5564 1.75 19.2501 5.44365 19.2501 10C19.2501 14.5563 15.5564 18.25 11.0001 18.25C10.5859 18.25 10.2501 18.5858 10.2501 19C10.2501 19.4142 10.5859 19.75 11.0001 19.75ZM11.0001 5.05072C11.4143 5.05072 11.7501 5.3865 11.7501 5.80072V10.6893L14.1858 13.1251C14.4787 13.418 14.4787 13.8929 14.1858 14.1858C13.893 14.4786 13.4181 14.4786 13.1252 14.1858L10.4698 11.5303C10.3291 11.3897 10.2501 11.1989 10.2501 11V5.80072C10.2501 5.3865 10.5859 5.05072 11.0001 5.05072Z' fill='%2300E4AC'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: left 10px center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #242833;
    background-color: transparent;
    border: none;

    &:hover {
      &::placeholder {
        opacity: 0;
      }
    }

    &::placeholder {
      color: #c1c5d6;
      transition: all 0.4s ease-in-out;
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 1024px) {
  .SearchInput {
    input {
      font-size: 14px;
    }
  }
}
