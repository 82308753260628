.Search {
  position: absolute;
  top: calc(100% + 20px);
  left: 0;
  z-index: 1002;
  width: 100%;
  padding: 15px;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  line-height: 24px;
  background: white;
  border-radius: 16px;

  .tabsLinks {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    font-family: Gilroy-SemiBold;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c1c5d6;

    .tabLink {
      position: relative;
      color: #c1c5d6;
      cursor: pointer;
    }

    .isActive {
      color: #242833;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -16.5px;
        height: 3px;
        width: 100%;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }
    }
  }

  .tabs {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
